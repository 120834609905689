import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'fpn-drag-content-dest-slide-row',
  templateUrl: './drag-content-dest-slide-row.component.html',
  styleUrls: ['./drag-content-dest-slide-row.component.scss']
})
export class DragContentDestSlideRowComponent implements OnInit {

  constructor() { }

  @Input() slide;

  @Input() slideType:string;
  
  @Output()
  onInsertNewBlankSlide: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onInsertNewSlideWithDroppedContent: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onAppendToSlideWithDroppedContent:EventEmitter<string> = new EventEmitter<string>();
   
  @Output()
  onDeleteSlide: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onMoveSlide: EventEmitter<string> = new EventEmitter<string>();


  @Output()
  ondeleteline: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit() {
  }

 


  insertNewBlankSlide() {
     this.onInsertNewBlankSlide.emit('');
  }
  insertNewSlideWithDroppedContent() {
    this.onInsertNewSlideWithDroppedContent.emit('');
  }
  appendToSlideWithDroppedContent(){
    this.onAppendToSlideWithDroppedContent.emit('');
  }
  dragover(e){
    return false;
  }

  deleteSlide(){
    if (confirm('Are you sure you want to delete this slide?')) {
      this.onDeleteSlide.emit('');
      // Delete it!
  } else {
      // Do nothing!
  }
  }

  addLine() {
    console.log(this.slide);
    if (!this.slide.children) {
      this.slide.children = [];
    }
    this.slide.children.push({title:'',children:[]});       
  }

  deleteLine(idx) {
      this.slide.children.splice(idx, 1);       
  }

  moveSlideUp(){
    this.onMoveSlide.emit('up');
  }
  moveSlideDown(){
    this.onMoveSlide.emit('down');
  }

  moveLine(fromIndex, toIndex) {
    if (toIndex<0 || (toIndex>this.slide.children.length-1)) return false;
     var element = this.slide.children[fromIndex];
     this.slide.children.splice(fromIndex, 1);
     this.slide.children.splice(toIndex, 0, element);
     return true;
 }
  moveLineUp(idx){
          this.moveLine(idx,idx-1);       

  }
  moveLineDown(idx){
      this.moveLine(idx,idx+1);
  }


}

