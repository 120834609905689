import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { AnnotationService } from '../../services/annotation.service';
import { AuthenticateService } from '../../services/authenticate.service';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'fpn-book-annotation-view',
  templateUrl: './book-annotation-view.component.html',
  styleUrls: ['./book-annotation-view.component.scss']
})
export class BookAnnotationViewComponent implements OnInit {


  constructor(private annotationService: AnnotationService, public authenticateService: AuthenticateService, private globals: GlobalService) { }

  ngOnInit() {
  }

  login() {
    this.authenticateService.startSigninMainWindow();
  }
}
