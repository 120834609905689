import { Component } from '@angular/core';
import { DataService } from './services/data.service';
import { NavViewComponent } from './views/nav-view/nav-view.component';
import { NavSubViewComponent } from './views/nav-sub-view/nav-sub-view.component';
import {GlobalService} from './services/global.service';
import * as bootstrap from "bootstrap";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  constructor(private globals:GlobalService) {
  }

  ngOnInit() {
        this.processAttributesFromAppRoot();

        //log in check is performed in the logged-in component (in the top right nav windows)

     }

  processAttributesFromAppRoot() {
    let appRoot = document.getElementsByTagName("app-root")[0];
    let attrs = appRoot.attributes;
    Object.keys(attrs).forEach(key => {
      this.globals.appRootAttributes[attrs[key].name] = attrs[key].value;
    });
    
 
  }
}
