import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthenticateService } from './authenticate.service';
import { GlobalService } from './global.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private globals: GlobalService, private router: Router) { }
  //private authenticateService: AuthenticateService

  canActivate() {
    //if (this.authenticateService.loggedIn) { return true; }
    if (this.globals.userInfo) { return true; }

    this.router.navigate(['unauthorized']);
  }
}
