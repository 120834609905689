import { Component, OnInit } from '@angular/core';
import {NavigationService} from '../../services/navigation.service'; 

@Component({
  selector: 'app-book-view',
  templateUrl: './book-view.component.html',
  styleUrls: ['./book-view.component.css']
})
export class BookViewComponent implements OnInit {

  constructor(public navigation: NavigationService) { }

  ngOnInit() {
   }

}
