import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import * as _ from "lodash";
import { SemanticJsonTreeService } from "../../services/semantic-json-tree.service";
import { ComposeDiagramService } from '../../services/compose-diagram.service';


@Component({
  selector: 'fpn-diagrammer',
  templateUrl: './diagrammer.component.html',
  styleUrls: ['./diagrammer.component.scss']
})
export class DiagrammerComponent implements OnInit {

  constructor(
    private semanticJsonTreeService: SemanticJsonTreeService,
    private navigation: NavigationService,
    private diagrammer:ComposeDiagramService
  ) { }

  innerHeight: number;
  sourcePages = [];
  grid = [];

  jsPlumbReset=false;


  currentlyDraggedItem = undefined;

  dlgCodeTitle = '';
  dlgCodeContent = '';

  ngOnInit() {
    this.navigation.setBreadcrumbsToLibraryWorkshopDiagrammer();

    this.innerHeight = window.innerHeight;

    //if this.selectionService.selectedPages

    this.semanticJsonTreeService
    .generateJsonTreeFromSelectedPages()
    .subscribe(p => {
      //console.log(p);
      this.sourcePages = _.values(p);
    });
  }

  ngAfterViewInit(){

  }

  dragstart(channel) {
    //console.log("Dragging ", channel);
    //console.log(window);
    this.currentlyDraggedItem = channel;
  }

  dragend(channel: any) {
    //console.log("Drag End ", channel);
    this.currentlyDraggedItem = undefined;
    return false;
  }

  generateDiagramPreview(){
    var OpenWindow = window.open("about:blank", "_blank",
      "fullscreen=yes,menubar=yes,toolbar=yes,scrollbars=yes,top=0,left=0,width=4000,height=4000");

    var diagramHtml = this.diagrammer.generateHtmlPresentationFromDiagrammer(this.grid);

    this.diagrammer.openHtmlInWindow(diagramHtml, OpenWindow);
  }
  saveDiagram(){
    var diagramHtml = this.diagrammer.generateHtmlPresentationFromDiagrammer(this.grid);
    var fullPageHtml = this.diagrammer.generateFullHtmlPage(diagramHtml);
     //console.log(slideViewer);
     this.dlgCodeContent = fullPageHtml;
     this.dlgCodeTitle=`Select and copy (ctrl-c) the code below and paste (ctrl-v) into a text editor (e.g. NotePad, TextEdit). Save as "filename.html".  Open the file in a browser.`;

     //this is a hack - using jquery to open the modal
     //however, I could not get this to work with viewchild
     //node that the id #dlgCode is the wrapping div within the fpn-dialog-code component
    $('#dlgCode').modal('show')

  }

  //use the browser plug in to save screenshot instead
  // saveDiagramAsPng(){
  // var OpenWindow = window.open("", "newwin",
  //   "fullscreen=yes,menubar=yes,toolbar=yes,scrollbars=yes,top=0,left=0,width=4000,height=4000");

  // var diagramHtml = this.diagrammer.generateHtmlPresentationFromDiagrammer(this.grid);

  // //diagramHtml += this.diagrammer.generateHtml2CanvasScript();
  // this.diagrammer.openHtmlInWindow(diagramHtml, OpenWindow);

  // }

  clearDiagram(){
    this.jsPlumbReset = true;
    var that = this;
    setTimeout(() => that.jsPlumbReset = false, 1000);
  }




}
