export class LodashHelper {
     public static buildCaseInsensitiveMatcher(property, matchText) {
            return function (item) {
                var matcher = new RegExp('^' + matchText + '$', 'i');

                var result = matcher.test(item[property]);
                 return result;
            };
        };
}
