import { Component, OnInit, Input } from '@angular/core';
import { PageLink } from '../../models/page-link.interface';

@Component({
  selector: 'fpn-search-sub-result',
  templateUrl: './search-sub-result.component.html',
  styleUrls: ['./search-sub-result.component.scss']
})
export class SearchSubResultComponent implements OnInit {

  @Input() links : PageLink[] = [];
  @Input() title : string = "";
  constructor() { }

  ngOnInit() {
  }

}
