import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FpnAngled2cliRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';


import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { FormsModule } from '@angular/forms';

import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http'; //newer - this replaces @angular/http

import { CommonModule } from '@angular/common';

//import {FpnAngled2cliRoutingModule} from './app.routing.module';

import { RouterModule, RouterLink } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DataService } from './services/data.service';
import { GlobalService } from './services/global.service';
import { SemanticService } from './services/semantic.service';
import { SelectionService } from './services/selection.service';
import { LibraryComponent } from './components/library/library.component';
import { BookComponent } from './components/book/book.component';
import { PageComponent } from './components/page/page.component';
import { SearchComponent } from './components/search/search.component';
import { ChapterComponent } from './components/chapter/chapter.component';
import { WorkshopComponent } from './components/workshop/workshop.component';
import { SlideshowComponent } from './components/slideshow/slideshow.component';
import { AnnotationComponent } from './components/annotation/annotation.component';
import { LoginComponent } from './components/login/login.component';
import { PageViewComponent } from './views/page-view/page-view.component';
import { LibraryViewComponent } from './views/library-view/library-view.component';
import { BookViewComponent } from './views/book-view/book-view.component';
import { ChapterViewComponent } from './views/chapter-view/chapter-view.component';
import { NavViewComponent } from './views/nav-view/nav-view.component';
import { PageAnnotationViewComponent } from './views/page-annotation-view/page-annotation-view.component';
import { PageWorkshopViewComponent } from './views/page-workshop-view/page-workshop-view.component';
import { PageSlideshowViewComponent } from './views/page-slideshow-view/page-slideshow-view.component';
//import {ComponentOutlet} from '../../node_modules/angular2-component-outlet/src/component-outlet';  //get rid of this once replaced
//import {provideComponentOutletModule} from '../../node_modules/angular2-component-outlet/src/provider'; //get rid of this once replaced
import { BreadcrumbBarComponent } from './views/breadcrumb-bar/breadcrumb-bar.component';
import { NavSubViewComponent } from './views/nav-sub-view/nav-sub-view.component';
import { NavigationService } from './services/navigation.service';
import { SearchBarComponent } from './views/search-bar/search-bar.component';
import { SearchViewComponent } from './views/search-view/search-view.component';
import { PageSelectorComponent } from './components/page-selector/page-selector.component';
import { LibrarySelectorComponent } from './components/library-selector/library-selector.component';
import { LibraryWorkshopViewComponent } from './views/library-workshop-view/library-workshop-view.component';
import { MapToIterablePipe } from './pipes/map-to-iterable.pipe';
import { LoggedInComponent } from './components/logged-in/logged-in.component';
import { AuthenticateService } from './services/authenticate.service';
import { AnnotationService } from './services/annotation.service';
import { AuthGuardService } from './services/auth-guard.service';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { AnnotationEditComponent } from './components/annotation-edit/annotation-edit.component';
import { PageBlockHtmlComponent } from './components/page-block-html/page-block-html.component';
import { NoSanitizeHtmlPipe } from './pipes/no-sanitize-html.pipe';
import { VotingElementComponent } from './components/voting-element/voting-element.component';
import { BookAnnotationViewComponent } from './views/book-annotation-view/book-annotation-view.component';
import { StringService } from "./services/string.service";
import { ChapterAnnotationViewComponent } from './views/chapter-annotation-view/chapter-annotation-view.component';
import { LibraryAnnotationViewComponent } from './views/library-annotation-view/library-annotation-view.component';
import { LibraryTopWorkshopViewComponent } from './views/library-top-workshop-view/library-top-workshop-view.component';
import { ComposePdfService } from './services/compose-pdf.service';
import { ComposeSlidesService } from "./services/compose-slides.service";
import { DragOrganizeComponent } from './components/drag-organize/drag-organize.component';
import { DragContentSourceComponent } from './components/drag-content-source/drag-content-source.component';
import { DragContentDestSlidesComponent } from './components/drag-content-dest-slides/drag-content-dest-slides.component';
import { SemanticJsonTreeService } from "./services/semantic-json-tree.service";
import { DragContentDestSlideRowComponent } from './components/drag-content-dest-slide-row/drag-content-dest-slide-row.component';
import { DragContentEditableTextWellComponent } from './components/drag-content-editable-text-well/drag-content-editable-text-well.component';
import { DragContentTextWellComponent } from './components/drag-content-text-well/drag-content-text-well.component';
import { DiagrammerComponent } from './components/diagrammer/diagrammer.component';
import { DiagrammerDestCanvasComponent } from './components/diagrammer-dest-canvas/diagrammer-dest-canvas.component';
import { ComposeDiagramService } from './services/compose-diagram.service';
import { DialogCodeComponent } from './components/dialog-code/dialog-code.component';
import { QuizComponent } from './components/quiz/quiz.component';
import { ComposeQuizService } from './services/compose-quiz.service';
import { DocumaticComponent } from './components/documatic/documatic.component';
import { ComposeDocumaticService } from "./services/compose-documatic.service";
import { UmlsTreeTriplerComponent } from './components/umls-tree-tripler/umls-tree-tripler.component';
import { ComposeUmlsTreeService } from './services/compose-umls-tree.service';
import { UmlsTripleComponent } from './components/umls-triple/umls-triple.component';
import { UmlsTripleItemComponent } from './components/umls-triple-item/umls-triple-item.component';
import { TocHeaderComponent } from './components/toc-header/toc-header.component';
import { TocListComponent } from './components/toc-list/toc-list.component';
import { NavMainMenuComponent } from './views/nav-main-menu/nav-main-menu.component';
import { SearchSubResultComponent } from './components/search-sub-result/search-sub-result.component';


@NgModule({
  declarations: [
    AppComponent,
    LibraryComponent,
    BookComponent,
    PageComponent,
    SearchComponent,
    ChapterComponent,
    WorkshopComponent,
    SlideshowComponent,
    AnnotationComponent,
    LoginComponent,
    PageViewComponent,
    LibraryViewComponent,
    BookViewComponent,
    ChapterViewComponent,
    NavViewComponent,
    PageAnnotationViewComponent,
    PageWorkshopViewComponent,
    PageSlideshowViewComponent,
    // ComponentOutlet,  //get rid of this once replaced
    BreadcrumbBarComponent,
    NavSubViewComponent,
    SearchBarComponent,
    SearchViewComponent,
    PageSelectorComponent,
    LibrarySelectorComponent,
    LibraryWorkshopViewComponent,
    MapToIterablePipe,
    LoggedInComponent,
    UnauthorizedComponent,
    AuthCallbackComponent,
    AnnotationEditComponent,
    PageBlockHtmlComponent,
    NoSanitizeHtmlPipe,
    VotingElementComponent,
    BookAnnotationViewComponent,
    ChapterAnnotationViewComponent,
    LibraryAnnotationViewComponent,
    LibraryTopWorkshopViewComponent,
    DragOrganizeComponent,
    DragContentSourceComponent,
    DragContentDestSlidesComponent,
    DragContentDestSlideRowComponent,
    DragContentEditableTextWellComponent,
    DragContentTextWellComponent,
    DiagrammerComponent,
    DiagrammerDestCanvasComponent,
    DialogCodeComponent,
    QuizComponent,
    DocumaticComponent,
    UmlsTreeTriplerComponent,
    UmlsTripleComponent,
    UmlsTripleItemComponent,
    TocHeaderComponent,
    TocListComponent,
    NavMainMenuComponent,
    SearchSubResultComponent

  ],
  imports: [
    NgbModule.forRoot(),
    BrowserModule,
    FormsModule,
    HttpClientModule, HttpClientJsonpModule,
    FpnAngled2cliRoutingModule
  ],
  providers: [
    DataService, GlobalService, NavigationService, SemanticService,
    SelectionService, AuthenticateService, AnnotationService, AuthGuardService,
    StringService, SemanticJsonTreeService,
    ComposePdfService, ComposeSlidesService, ComposeDiagramService,
    ComposeQuizService, ComposeDocumaticService,
    ComposeUmlsTreeService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
