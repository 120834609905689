import { Injectable, EventEmitter } from '@angular/core';
import { DataHelper } from '../utility/data-helper';
import {DataService} from './data.service';
import {NavigationService} from './navigation.service';

declare var _ : any;

@Injectable()
export class SelectionService {
    public broadcastClearPageSelection$: EventEmitter<string>;
    
  constructor(private dataService : DataService, private navigationService: NavigationService) { 
      //console.log("starting selection service");
      this.broadcastClearPageSelection$ = new EventEmitter();
  }

 public selectedPages = {};
  public groupedSelectedPages = {};



 listItemSelected(blockLine, pageBlock, page,skipSetSelectedPages = false, invertBlockLineCheck = true) {
     if (!pageBlock || !blockLine) return;
    if (invertBlockLineCheck)
        blockLine.Selected = !blockLine.Selected;



    this.checkChildren(blockLine, blockLine.Selected);
    var p = page;  //might need the other page data format
    this.pageSelectedBlocks(p.lineage, p.title, p.path, pageBlock);

    // dataService.setNSelectedPagesInChildren(lib.book);
   if (!skipSetSelectedPages)  this.setSelectedPages();
  }


  pageBlockSelected(pageBlock, page, skipSetSelectedPages = false, invertPageBlockCheck = true) {
    if (!pageBlock) return;
    if (invertPageBlockCheck)
        pageBlock.Selected = !pageBlock.Selected; //assumes that the calling view did not change the selected property (not used as model)



    for (var i = 0; i < pageBlock.Blocks.length; i++) {
      var blockLine = pageBlock.Blocks[i];
      blockLine.Selected = pageBlock.Selected;
      this.checkChildren(blockLine, pageBlock.Selected);
    }
    var p = page;  //might need the other page data format

    this.pageSelectedBlocks(p.lineage, p.title, p.path, pageBlock);
    //dataService.setNSelectedPagesInChildren(lib.book);
    if (!skipSetSelectedPages) this.setSelectedPages();
  }




  checkChildren(blockLine, isSelected) {
    for (var i = 0; i < blockLine.Children.length; i++) {
      var blockLineSub = blockLine.Children[i];
      blockLineSub.Selected = isSelected;
      this.checkChildren(blockLineSub, isSelected);
    }
  }



setlibraryTreeSelection (item, isSelected:boolean){
    if (item.Selected!==undefined){
        item.Selected = isSelected;
    }
    item.children.forEach(child => {
        this.setlibraryTreeSelection(child, isSelected);
    });
}
  resetLibraryTreeSelections(){
    var lib = this.dataService.libraryTree;
    this.setlibraryTreeSelection(lib, false);

}

resetPageSelections(){
    this.broadcastClearPageSelection$.emit("clear");
}

clearAllSelected(){
    //incomplete - need dataService to clear the library and page selections
    this.selectedPages = {};
    this.groupedSelectedPages = {};
    this.resetLibraryTreeSelections();
    this.resetPageSelections();
}







    getSelectedPages() { return this.selectedPages };

/** 
 * Remove residual pages with no SelectionService
 * Also recalculate grouped set of the pages
 * todo: Should be renamed to pruneSelectedPages
*/
     setSelectedPages() {
         //remove residual pages with no SelectionService
         this.selectedPages = _.pickBy(this.selectedPages,(selectedPage:any)=>{
                { return selectedPage.allSelected || selectedPage.nSelected > 0; }
         });


            this.updateGroupedSelectedPages();
        }

   getPageHierarchyFromLineage(lineage, book = null) {
        return DataHelper.getPageHierarchyFromLineage(lineage, book, this.dataService.libraryTree);  //was libraryTree.data
    }

    parseLineage(lineage) {

        var t = this.getPageHierarchyFromLineage(lineage);


        return {
            book: { name: t.book.name, path: t.book.path },
            chapter: { name: t.chapter.name, path: t.chapter.path },
            section: { name: t.section.name, path: t.section.path },
            page: { name: t.page.name, path: t.page.path }
        };
    }


updateGroupedSelectedPages(){
       this.groupedSelectedPages = _(this.selectedPages)
                .values()
                .groupBy(function (s) {
                    var pageLineage = s.lineage;
                    var sectionLineage = pageLineage.substr(0, pageLineage.lastIndexOf('.'));
                    return sectionLineage;
                })
                .value();
}

   updateTotalSelectedInPage(pageLineage) {
        var nSelectedTotal = 0;
        _.each(this.selectedPages[pageLineage].selectedBlocks,
            function (block) { if (block && block.nSelected) nSelectedTotal += block.nSelected; });
        this.selectedPages[pageLineage].nSelected = nSelectedTotal;
    }

    getNSelectedinPageBlock(item) {
        return DataHelper.getNSelectedinPageBlock(item);

    }

    selectPageInLibraryTreeByLineage(lineage, isSelected){
        this.dataService
            .getPageInTreeByLineageWithoutModifyingNavigation(lineage)
            .subscribe((result:any)=>{
                result.Selected = isSelected;
            });
    }

    selectPageInLibraryTreeByUrl(url, isSelected){
        this.dataService
            .getPageInTreeByUrlWithoutModifyingNavigation(url)
            .subscribe((result:any)=>{
                result.Selected = isSelected;
            });
    }

    pageSelected(page, isSelected, path) {
        var pageLineage = page.lineage || page.Lineage;
        var isSetter = isSelected !== undefined;
        if (isSetter) {

            var parsedLineage = (this.selectedPages[pageLineage] && this.selectedPages[pageLineage].parsedlineage)
                ? this.selectedPages[pageLineage].parsedlineage : this.parseLineage(pageLineage);

            var nSelected = (this.selectedPages[pageLineage] && this.selectedPages[pageLineage].nSelected)
                ? this.selectedPages[pageLineage].nSelected : 0;

            //if we are selecting an entire page, then we should not be sub-selecting any blocks
            //therefore set selectBlocks to none, even if unselecting
            var selectedBlocks = {};
            // var selectedBlocks = (this.selectedPages[pageLineage] && this.selectedPages[pageLineage].selectedBlocks)
            //     ? this.selectedPages[pageLineage].selectedBlocks : {};

            var pageName = page.name || page.Title;
            this.selectedPages[pageLineage] =
                DataHelper.createSelectedPageStructure(pageName, pageLineage, path, selectedBlocks, nSelected, isSelected, parsedLineage);

            this.updateTotalSelectedInPage(pageLineage);

            //intentionally does not modify selectedBlocks or nSelected, (retains these until person specifically clears page or page blocks)
        }

        return this.selectedPages[pageLineage].allSelected || this.selectedPages[pageLineage].nSelected > 0;
    }

 
   //Page selectedBlock getter-setter function
    pageSelectedBlocks(pageLineage, pageTitle, pageUrl, pageBlock) {
        // var pageLineage = page.Lineage;
        
        var isSetter = pageBlock !== undefined;
       // console.log(pageLineage, pageTitle, pageUrl);
 
        // var page = findPage(pageLineage);
        // if (!page.selectedBlocks) page.selectedBlocks = {};
        if (isSetter) {
            var nSelected = this.getNSelectedinPageBlock(pageBlock);
            pageBlock.nSelected = nSelected;
            if (nSelected < 1) pageBlock.Selected = false;
            
            //check the selected pages object for a parsed lineage to book, chapter, page objects
            //use it if it exists, if not create one (book, chapter, page, section each with {name:'',path:''})
            var parsedLineage = (this.selectedPages[pageLineage] && this.selectedPages[pageLineage].parsedlineage)
                ? this.selectedPages[pageLineage].parsedlineage : this.parseLineage(pageLineage);
 
            //check the selectedPages object for selectedBlocks (sections/paragraphs) for the current page lineage
            //use it as a base if it exists, but if not, create a new one
            var selectedBlocks = (this.selectedPages[pageLineage] && this.selectedPages[pageLineage].selectedBlocks) ? this.selectedPages[pageLineage].selectedBlocks : {};

            //if we are selecting a subset of a page,
            //then it makes sense to set allSelected to false.
            //this represents a change as of 6/5/2018
            //var allSelectedInPage = (this.selectedPages[pageLineage]) ? this.selectedPages[pageLineage].allSelected : false;
            var allSelectedInPage = false;

            //creates a structured page object (ISelectedPage): allSelected, selectedBlocks, nSelected, title, lineage, path, parsedLineage 
            this.selectedPages[pageLineage] = DataHelper.createSelectedPageStructure(pageTitle, pageLineage, pageUrl, selectedBlocks, nSelected, allSelectedInPage, parsedLineage);


            if (nSelected > 0) {
                this.selectedPages[pageLineage].selectedBlocks[pageBlock.MapId] = pageBlock;
            }
            else {
                //remove the block from the selectedPage
                //selectedPages with no active block selections are removed in a later step
                this.selectedPages[pageLineage].selectedBlocks[pageBlock.MapId] = undefined;

            }
            //For a given selectedPage[pageLineage], total the number of blocks selected, and store on selectedPage object as nSelected
            this.updateTotalSelectedInPage(pageLineage);

        }

        //return the updated selectedBlock (recalculated NSelected)
        return this.selectedPages[pageLineage].selectedBlocks;
    }


 
      removeSelectedPage(page) {
          
            var tree :any = this.getPageHierarchyFromLineage(page.lineage, this.navigationService.currentBook);
            page.allSelected = false;

            if (tree.page) {
                tree.page.Selected = false;
            }

            
            _.each(page.selectedBlocks, (block)=>{
                if (block) {

                block.Selected = false;
                this.pageBlockSelected(block, page, true, false);

                }
            });
            this.setSelectedPages();
        }






}
