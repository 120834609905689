import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LodashHelper } from '../../utility/lodash-helper';
import { NavigationService } from '../../services/navigation.service';
import { Link } from '../../models/link';
import * as _ from "lodash";

@Component({
  selector: 'app-chapter',
  templateUrl: './chapter.component.html',
  styleUrls: ['./chapter.component.scss']
})
export class ChapterComponent implements OnInit {
  @Input() isKeyView = false;
  public selectedBookAbbrev: string;
  public selectedChapterAbbrev: string;
  public isAnnotation: boolean;
  public isWorkshop: boolean;

  public chapter: any;
  constructor(public dataService: DataService,
    public navigation: NavigationService,
    private route: ActivatedRoute,
    private router: Router) { }

    chapterTitleForGivenSpace(){
      return (this.chapter.name) ? this.chapter.name.slice(0,18) : "";
    }

  get headerLink(): Link {
    return {
      title: `${_.toUpper(this.chapterTitleForGivenSpace())} CHAPTER`,
      path: '..'
    };
  }

  get headerMenuLinks(): Link[] {
    return [{
      title: `Chapter Workshop`,
      path: `/library/${this.selectedBookAbbrev}/${this.selectedChapterAbbrev}/workshop`
    },
    {
      title: `Chapter Annotation`,
      path: `/library/${this.selectedBookAbbrev}/${this.selectedChapterAbbrev}/annotation`
    }
    ];
  }

  composeLinkListInSections(suffix=''){
   //todo: memoize - this is called too often
    var currentPage = this.navigation.currentPage;
    var sections = [];
    this.chapter.children.forEach(section => {
      var links = section.children.map(page => {
        return {
          title: `${page.name}`,
          id: page.lineage,
          path: `/library/${this.selectedBookAbbrev}/${this.selectedChapterAbbrev}/${page.path}${suffix}`,
          selected: (currentPage && page.lineage == currentPage.Lineage)
        }
      });
      sections.push({title:section.name,links:links});
    });

    return sections;
  }

  get linkListInSections() {
    return this.composeLinkListInSections();
  }


  get annotationHeaderLink(): Link {
    return {
      title: `${_.toUpper(this.chapterTitleForGivenSpace())} CHAPTER`,
      path: '../../annotation'
    };
  }

  get annotationHeaderMenuLinks(): Link[] {
    return [{
      title: `Chapter Workshop`,
      path: `/library/${this.selectedBookAbbrev}/${this.selectedChapterAbbrev}/workshop`
    },
    {
      title: `Chapter Index`,
      path: `/library/${this.selectedBookAbbrev}/${this.selectedChapterAbbrev}`
    }
    ];
  }


  get annotationLinkListInSections() {
    return this.composeLinkListInSections('/annotation');
  }



  ngOnInit() {
    this.route.params.forEach((params: Params) => {
      this.selectedBookAbbrev = params['bookAbbrev'];
      this.selectedChapterAbbrev = params['chapterAbbrev'];

      var url = this.router.url;
      //annotation breadcrumbs may load after page, so using url instead
      this.isAnnotation = url.toLowerCase().endsWith("annotation");
      this.isWorkshop = url.toLowerCase().endsWith("workshop");


      this.dataService.getChapterInTreeByAbbrev(this.selectedBookAbbrev, this.selectedChapterAbbrev)
        .subscribe(
          result => {
            this.chapter = result;
            if (this.isKeyView) this.navigation.setBreadcrumbsToChapter();
          },
          error => { console.log(error); }

        );

    });

  }

}
