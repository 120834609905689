import { Component, OnInit, Input } from '@angular/core';
import { Link } from '../../models/link';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'fpn-toc-list',
  templateUrl: './toc-list.component.html',
  styleUrls: ['./toc-list.component.scss']
})
export class TocListComponent implements OnInit {

  constructor(private globals:GlobalService) { }

  @Input() heading:string;
  @Input() listItems : Link[]; 

  ngOnInit() {
  }

}
