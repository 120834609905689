import * as _ from "lodash";

import {ILibraryTreeItem, ISelectedPage, IPageBlockItem, IPageHierarchySynopsis} from "./interfaces"; 
  

export class DataHelper {
            /** 
         * Generates a filename from pathPrefix, extension and a variable number of dirNames
         * @param pathPrefix An optional root directory
         * @param ext A file extension (e.g. .json).  A starting '.' is prefixed if not included 
         * @param dirNames A variable number of dirs, the last of which is the file title
         */
        public static generateFilePath(pathPrefix: string, ext: string, ...dirNames: string[]): string {
            if (_.isEmpty(ext) || _.isEmpty(dirNames)) return null;
            ext = _.trim(ext);
            pathPrefix = _.trim(pathPrefix);

            if (!_.startsWith(ext, '.')) ext = '.' + ext;
            if (pathPrefix.length > 0 && !_.endsWith(pathPrefix, '/')) pathPrefix += '/';

            var filename = dirNames.join('/');
            var path = pathPrefix + filename + ext;
            return path;
        }


        /**
         * Counts the number of selected lines within a outline block in a page
         * Uses recursion to walk each tree to its deepest level.  The outer most PageBlock container is not counted.
         * @param item Item is either a PageBlock or a PageBlockItem (distinguished within the function)
         */
     public static getNSelectedinPageBlock(item : any) {
        var nSelected = 0;
        if (!item || _.isEmpty(item)) return nSelected;


        if (item.Blocks) {//pageBlock 
            _.forEach(item.Blocks, function (line: IPageBlockItem) { nSelected += DataHelper.getNSelectedinPageBlock(line); });
        }
        else { //pageBlockLine
            if (item.Children.length > 0) {
                //previously Did not tally if selected (not the deepest line)
                //however, think I want this to tally.
                if (item.Selected) nSelected += 1;
                _.forEach(item.Children, function (line:IPageBlockItem) { nSelected += DataHelper.getNSelectedinPageBlock(line); });

            }
            else { //No children - so this is deepest branch.  
                //tally if selected
                if (item.Selected) nSelected += 1;

            }
        }

        return nSelected;
    }



        /**
         * Builds a SelectedPage Object used to track pages selected in Workshop
         */
        public static createSelectedPageStructure(title: string, lineage: string, path: string,
            selectedBlocks: {}, nSelected: Number, allSelected: boolean, parsedLineage: {}) {
            var selectedPage: ISelectedPage = {
                allSelected: allSelected,
                selectedBlocks: selectedBlocks,
                nSelected: nSelected,
                title: title,
                lineage: lineage,
                path: path,
                parsedLineage: parsedLineage
            }

            return selectedPage;
        }

        /** 
         * Given a lineage, finds a page within the passed book object tree
         * If Book is undefined, then search in library.  This tree does not contain the actual 
         * page content (those are in individual json files.)  This is an index to those files.
         * @param lineage A map ID, e.g. '23.45.67' whose parent would be '23.45'. 
         * @param book A subset of the library object containing chapters, sections, pages
         * @param library The entire library object, containing books, chapters, sections, pages 
         */
        public static getPageHierarchyFromLineage(lineage: string, book: ILibraryTreeItem, library: ILibraryTreeItem): IPageHierarchySynopsis {
            if (_.isEmpty(lineage) || (!book || !book.children) && (!library || !library.children)) return null;

            var parts = lineage.split('.'); // split(lineage + '', '.');
            var bookLineage = parts[0] + '.' + parts[1];
            var chapterLineage = bookLineage + '.' + parts[2];
            var sectionLineage = chapterLineage + '.' + parts[3];
            var pageLineage = sectionLineage + '.' + parts[4];


            var chapter: ILibraryTreeItem,
                section: ILibraryTreeItem,
                page: ILibraryTreeItem;

            if (!book) book = _(library.children)
                .filter({ lineage: bookLineage })
                .first();
            if (!book) return { book: book, chapter: chapter, section: section, page: page };

            chapter = _(book.children)
                .filter({ lineage: chapterLineage })
                .first();
            if (!chapter) return { book: book, chapter: chapter, section: section, page: page };

            section = _(chapter.children)
                .filter({ lineage: sectionLineage })
                .first();
            if (!section) return { book: book, chapter: chapter, section: section, page: page };

            page = _(section.children)
                .filter({ lineage: pageLineage })
                .first();

            return { book: book, chapter: chapter, section: section, page: page };

        }

 


}
