
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthenticateService } from './authenticate.service';
import { GlobalService } from './global.service';
//import { Http, Response, RequestOptions } from '@angular/http'; //todo: Http to HttpClient, Response needs replacement with HttpResponse
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Annotation } from "../models/annotation";
import '../rxjs-operators';
import { map, tap, catchError } from 'rxjs/operators'


//import 'rxjs';
import { Review } from "../models/review";
import { ResponseError } from '../models/response-error.interface';


@Injectable()
export class AnnotationService {

    public annotations: any;
    private isRunningInWWWroot = false;
    constructor(private authenticateService: AuthenticateService, private globals: GlobalService, private http: HttpClient) {

        this.isRunningInWWWroot = this.globals.appRootAttributes['is-running-in-www-root'] == 'true';
    }


    updateAnnotation(annotation: Annotation): Observable<any> {
        var apiPath = this.globals.getPathApi() + 'Annotation/update';
        //var rxAnnotations = this.http.put(apiPath, annotation)  
        var rxAnnotations = this.authenticateService.AuthPut(apiPath, annotation)
            .pipe(
                map(this.extractData),
                tap((result) => {
                    //console.log(result);
                }),
                catchError(this.handleError)
            );


        return rxAnnotations;

    }

    deleteAnnotation(annotation: Annotation): Observable<any> {
        var apiPath = this.globals.getPathApi() + `Annotation/delete/${annotation.id}`;
        var rxAnnotations = this.authenticateService.AuthDelete(apiPath)
            .pipe(
                tap((result) => {
                    //console.log(result);
                }),
                catchError(this.handleError)
            );
        //var rxAnnotations = this.http.delete(apiPath)

        return rxAnnotations;
    }


    addAnnotation(annotation: Annotation): Observable<any> {
        var apiPath = this.globals.getPathApi() + 'Annotation/create';
        //var rxAnnotations = this.http.post(apiPath, annotation)
        var rxAnnotations = this.authenticateService.AuthPost(apiPath, annotation)
            .pipe(
                map(this.extractData),
                tap((result) => {
                    //console.log(result);
                }),
                catchError(this.handleError)

            )

        return rxAnnotations;

    }

    updateAnnotationReviewStatus(annotationId, statusCode): Observable<any> {
        var apiPath = this.globals.getPathApi() + `Annotation/update/${annotationId}/status/${statusCode}`;
        var rxStatus = this.authenticateService.AuthPut(apiPath, null)
            //var rxReview = this.http.post(apiPath, review)
            .pipe(
                tap((result) => {
                    //console.log(result);
                }),
                catchError(this.handleError)

            );


        return rxStatus;
    }

    addReview(review: Review): Observable<any> {
        var apiPath = this.globals.getPathApi() + 'Review/create';
        //var rxReview = this.http.post(apiPath, review) 

        var rxReview = this.authenticateService.AuthPost(apiPath, review)
            .pipe(
                map(this.extractData),
                tap((result) => {
                    //console.log(result);
                }),
                catchError(this.handleError)

            );

        return rxReview;

    }


    deleteReview(review: Review): Observable<any> {
        var apiPath = this.globals.getPathApi() + `Review/delete/${review.id}`;

        var rxReview = this.authenticateService.AuthDelete(apiPath)
            .pipe(
                map(this.extractData),
                tap((result) => {
                    //console.log(result);
                }),
                catchError(this.handleError)


            );

        return rxReview;

    }

    getAnnotationsForPage(book: string, chapter: string, page: string): Observable<any> {
        var apiPath = this.globals.getPathApi() + 'Annotation/list';

        apiPath = apiPath + `/forUrl?book=${book}&chapter=${chapter}&page=${page}`;

        // if (!this.isRunningInWWWroot) {//stand alone angular test
        //     apiPath = '../../assets/sample-data/annotationListMock.json';
        //  }
        //console.log(apiPath);
        //var rxAnnotations = this.http.get(apiPath) 

        var rxAnnotations = this.authenticateService.AuthGet(apiPath)
            .pipe(
                map(this.extractData),
                tap((result) => {
                    this.annotations = result;
                }),
                catchError(this.handleError)


            );

        return rxAnnotations;


    }


    getAnnotations(takeN: number = 0): Observable<any> {
        var apiPath = this.globals.getPathApi() + 'Annotation/list';
        if (takeN > 0) apiPath = apiPath + `/${takeN}`;

        if (!this.isRunningInWWWroot) {//stand alone angular test
            apiPath = '../../assets/sample-data/annotationListMock.json';
        }


        // var rxAnnotations = Observable.create().catch(
        //     this.http.get(apiPath),
        //     this.http.get(testFilePath),
        //     Observable.from([]))
        //     .map(this.extractData)
        //     .do((result) => {
        //         this.annotations = result;
        //     })
        //     .catch(this.handleError);


        var rxAnnotations = this.http.get(apiPath)
            .pipe(
                map(this.extractData),
                tap((result) => {
                    this.annotations = result;
                }),
                catchError(this.handleError)

            )

        return rxAnnotations;
    }

    //the extract method and handleError method could be extracted to a helper utility class.  
    //it is used in both this service and DataService.
    private extractData(res: Response) {
        //let body = res.json();
        // return body || [];
        return res;
    }

    private extractDataDeprecated(res: Response) {
        let body = res.json();
        return body || [];
        // console.log(res);
        // return res;
    }
    private handleError(error: ResponseError) {
        // In a real world app, we might use a remote logging infrastructure
        // We'd also dig deeper into the error to get a better message
        let errMsg = (error.message) ? error.message :
            error.status ? `${error.status}` : 'Server error';
        if (error.status == 401) {
            //we need to update to use the new token from silent refresh
            // location.reload(); //this does not work
        }

        console.log(errMsg); // log to console instead
        return observableThrowError(errMsg);
    }



    //   api(){
    //    var user = this.authenticateService.user;
    //    if (!user) return;

    //         var url = this.globals.apiRootUrl + "identity";

    //         var xhr = new XMLHttpRequest();
    //         xhr.open("GET", url);
    //         xhr.onload = function () {
    //             console.log(xhr.status, JSON.parse(xhr.responseText));
    //         }
    //         xhr.setRequestHeader("Authorization", "Bearer " + user.access_token);
    //         xhr.send();
    //  }


}
