export enum Category { Info =1, Warning =2, Error =3};
export enum Priority { Minor =1, Normal =5, Serious =10};

export class Annotation {

    public id: number;
    public user : any
    public reviews : any[] = [];
    public currentComment : string = "";
    public currentUserReviewStatus? : number;
    public reviewStatuses?:any[];
    public extracts?:any;

    constructor(
        public noteTitle: string,
        public noteContents: string,
        public sourceUrl: string,
        public isPrivate: boolean,
        public priority: Priority,
        public category: Category,
      ) { }

}
