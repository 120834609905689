import { Component, OnInit } from '@angular/core';
import { BreadcrumbBarComponent} from '../../views/breadcrumb-bar/breadcrumb-bar.component';
import {} from '../../views/search-bar/search-bar.component'
@Component({
  selector: 'app-nav-sub-view',
  templateUrl: './nav-sub-view.component.html',
  styleUrls: ['./nav-sub-view.component.scss']
})
export class NavSubViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
