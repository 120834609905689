import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ActivatedRoute, Params } from '@angular/router';
import { LodashHelper } from '../../utility/lodash-helper';
import { NavigationService } from '../../services/navigation.service';
import { SemanticService } from '../../services/semantic.service';
import { SelectionService } from '../../services/selection.service';
import * as _ from 'lodash'

@Component({
  selector: 'app-page-selector',
  templateUrl: './page-selector.component.html',
  styleUrls: ['./page-selector.component.scss']
})
export class PageSelectorComponent implements OnInit {

  @Input() isKeyView = false;
  public selectedBookAbbrev: string;
  public selectedChapterAbbrev: string;
  public selectedPageAbbrev: string;
  public page: any;
  public parsedPage;
  public selectedPage;

  
  public isEntirePageSelected = false;
  public isCheckAllWarning = false;
  public akasJoined: string;

  constructor(public dataService: DataService,
    private navigation: NavigationService,
    private route: ActivatedRoute,
    private semanticService: SemanticService,
    private selectionService: SelectionService) {
      selectionService.broadcastClearPageSelection$
        .subscribe(item => this.changeAllCheckBoxes(false));
     }
    
public isSlideshow:boolean;
public isAnnotation:boolean;
public isWorkshop:boolean;


selectEntirePage(isSelected){
  this.isCheckAllWarning = false;
  this.changeAllCheckBoxes(false);
  
  this.selectionService.pageSelected(this.page, isSelected, '');
  this.selectionService.setSelectedPages();
  this.isEntirePageSelected = isSelected;
  this.selectionService.selectPageInLibraryTreeByLineage(this.page.Lineage, isSelected);
}
getSelectedLinkedPages(item){
  var selected = [];
  //console.log(item);
  var hrefMatch = /href=["']\/Library\/(.*?)\/workshop["']/gi;

  if (item.Html && (this.isEntirePageSelected || item.Selected===true)) {

   // debugger;
    //var matches = hrefMatch.exec(item.Html);
   // var matches = item.Html.match(hrefMatch);
   var matches;
    while ((matches = hrefMatch.exec(item.Html)) !== null) {
      if (matches.length>1) selected.push(matches[1]);
    }

   // console.log(item.Html, matches); 
    //selected.push(item.MapId);
  }
  if (item.Blocks){
    var selectedBlocks = _.flatten(item.Blocks.map((block)=> this.getSelectedLinkedPages(block)));
     selected = selected.concat(selectedBlocks);
  } else if (item.Children){
    var selectedChildren = _.flatten(item.Children.map((child)=>this.getSelectedLinkedPages(child)));
    selected =selected.concat(selectedChildren);
  }
  return selected;
}
selectAllLinkedPages(){
  //get all links in page for selected content
  //either whole page is selected or line by line
  //console.log(this.page);
 
  var selectedPageLinks = [];
   _.values(this.page.PageBlockData)
  .forEach((bigBlock)=>{
    var selected = this.getSelectedLinkedPages(bigBlock);
    selectedPageLinks = selectedPageLinks.concat(selected);
  });
  

  //console.log(selectedPageLinks);
  selectedPageLinks.forEach((url)=>{
    this.dataService
    .getPageInTreeByUrlWithoutModifyingNavigation(url)
    .subscribe((linkedPage:any)=>{
        linkedPage.Selected = true;
        this.selectionService.pageSelected(linkedPage, true, '');
    });

   // var linkedPage = this.selectionService.selectPageInLibraryTreeByUrl(url, true);
   // console.log(linkedPage);
  });
  
  this.selectionService.setSelectedPages();
  //add links to the selected pages
}
changeAllCheckBoxes(isChecked:boolean){


  _.values(this.page.PageBlockData)
  .forEach((bigBlock)=>{
    this.pageBlockSelected(bigBlock,isChecked);
    //this.changeAllCheckBoxesSub(bigBlock, isChecked);
  }); 
  this.selectionService.setSelectedPages();

  //even if all checkboxes are selected,
  //any subselection of the page unselects the page as a whole
  this.isEntirePageSelected = false;

  if (isChecked) this.isCheckAllWarning = true;
}
  listItemSelected = function (blockLine, pageBlock) {
    if (!pageBlock || !blockLine) return;
    blockLine.Selected = !blockLine.Selected;
    this.isCheckAllWarning = false;


    this.checkChildren(blockLine, blockLine.Selected);
    var p = this.page;  //might need the other page data format
    // console.log(JSON.stringify(pageBlock));

    this.selectionService.pageSelectedBlocks(p.Lineage, p.Title, p.Url, pageBlock);

    // dataService.setNSelectedPagesInChildren(lib.book);
    this.selectionService.setSelectedPages();

    this.isEntirePageSelected = false; //any subselection of the page unselects the page as a whole
  }


  pageBlockSelected = function (pageBlock, isSelected:boolean = null) {
    if (!pageBlock) return;
    this.isCheckAllWarning = false;
    //default behavior is to invert the pageBlock Selection (no parameter passed)
    //However checkAllBoxes will also use this and send a checked or unchecked flag
    pageBlock.Selected = (isSelected===null) ? !pageBlock.Selected : isSelected;


    for (var i = 0; i < pageBlock.Blocks.length; i++) {
      var blockLine = pageBlock.Blocks[i];
      blockLine.Selected = pageBlock.Selected;
      this.checkChildren(blockLine, pageBlock.Selected);
    }
    var p = this.page;  //might need the other page data format

    this.selectionService.pageSelectedBlocks(p.Lineage, p.Title, p.PageUrl, pageBlock);
    //dataService.setNSelectedPagesInChildren(lib.book);
    this.selectionService.setSelectedPages();

    this.isEntirePageSelected = false;//any subselection of the page unselects the page as a whole
  }




  checkChildren = function (blockLine, isSelected) {
    for (var i = 0; i < blockLine.Children.length; i++) {
      var blockLineSub = blockLine.Children[i];
      blockLineSub.Selected = isSelected;
      this.checkChildren(blockLineSub, isSelected);
    }
  }


getSelectedMapIds(item):string[]{
  var selected = [];
  if (item.Selected===true) {
    selected.push(item.MapId);
  }
  if (item.Blocks){
    var selectedBlocks = _.flatten(item.Blocks.map((block)=> this.getSelectedMapIds(block)));
     selected = selected.concat(selectedBlocks);
  } else if (item.Children){
    var selectedChildren = _.flatten(item.Children.map((child)=>this.getSelectedMapIds(child)));
    selected =selected.concat(selectedChildren);
  }
  return selected;
}
getSelectedMapIdsFromPage(selectedPage){
  var selectedMapIds = [];
   _.values(selectedPage.selectedBlocks)
  .forEach((bigBlock)=>{
    var selected = this.getSelectedMapIds(bigBlock);
    selectedMapIds = selectedMapIds.concat(selected);
  });
  return selectedMapIds;
}

syncSelectedItems(item, selectedMapIds){
  item.Selected = (selectedMapIds.includes(item.MapId));
  if (item.Blocks){
    item.Blocks.forEach(block => {
      this.syncSelectedItems(block, selectedMapIds)
    });
  } else if (item.Children){
    item.Children.forEach(child => {
      this.syncSelectedItems(child, selectedMapIds)
    });
  } 
}

syncSelectedItemsForPageOnLoad(){
  //need to also denote elsewhere if allselected in page
  
  //get the selectedPage Items
  if (!this.page || !this.page.Lineage) return null;
  this.selectedPage = this.selectionService.selectedPages[this.page.Lineage];

  if (!this.selectedPage) return null;
  this.isEntirePageSelected = this.selectedPage.allSelected;

  if (!this.selectedPage.selectedBlocks) return null;
  

  var selectedMapIds = this.getSelectedMapIdsFromPage( this.selectedPage);

  //now walk the tree and find the selected mapIds in the page selector
  _.values(this.page.PageBlockData)
  .forEach((bigBlock)=>{
    this.syncSelectedItems(bigBlock, selectedMapIds);
  });
 
}

  ngOnInit() {

    this.route.params.forEach((params: Params) => {
      this.selectedBookAbbrev = params['bookAbbrev'];
      this.selectedChapterAbbrev = params['chapterAbbrev'];
      this.selectedPageAbbrev = params['pageAbbrev'];

      this.dataService.getPageByUrlAndUpdateBookAndChapter(this.selectedBookAbbrev, this.selectedChapterAbbrev, this.selectedPageAbbrev)
        .subscribe(
        result => {

          this.page = result;
          //console.log(result);
          if (this.isKeyView) this.navigation.setBreadcrumbsToPageWorkshop();

          this.parsedPage = this.semanticService.processPageJson(this.page);
          this.isAnnotation = this.navigation.breadcrumbs.find(x=>x.title=='Annotation')!=null;
            this.isWorkshop = this.navigation.breadcrumbs.find(x=>x.title=='Workshop')!=null;

          //The current fpnotebook json format uses an href with '#/library...' which works with angular 1
          //Convert these href to use [routerLink]='/library...' which works with angular 2
          let replaceHrefs = (block) => {
            //block.Html = block.Html.replace(/href=["']#(\/.*?)["']/gi, "[routerLink]='[\"$1\"]'");
            block.Html = block.Html.replace(/href=["']#(\/.*?)["']/gi, "href='$1'");

            for (let child of block.Children) {
              if (child.Children) replaceHrefs(child);
            }
          };

          if (this.parsedPage && this.parsedPage.PageBlockData) {
            for (let pageBlock of this.parsedPage.PageBlockData) {
              for (let block of pageBlock.Blocks) {
                replaceHrefs(block);
              }
            }
          }
          
          this.syncSelectedItemsForPageOnLoad();
            var akaStringArray = _.map(result.Akas,'Title');
           this.akasJoined = akaStringArray.join(', ');
        },
        error => { console.log(error); }

        );

    });



  }

}
