import { Component, OnInit } from '@angular/core';
import {NavigationService} from '../../services/navigation.service';

@Component({ 
  selector: 'app-library-view',
  templateUrl: './library-view.component.html',
  styleUrls: ['./library-view.component.css']
})
export class LibraryViewComponent implements OnInit {

  constructor(private navigation:NavigationService) { }

  ngOnInit() {
  

  }

}
