import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { DataService } from '../../services/data.service';
import {GlobalService} from '../../services/global.service';
import { Router} from '@angular/router';
import { debounceTime, distinctUntilChanged, map, tap, filter, switchMap, catchError, take } from 'rxjs/operators';
import { Subject, of, Observable, merge } from 'rxjs';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';


//If reinstated twitter typeahead, then add this to the angularcli file:
//        "../node_modules/corejs-typeahead/typeahead.bundle.min.js"
// import '../../../../node_modules/corejs-typeahead/typeahead.bundle';
//declare var Bloodhound: any;
// See https://twitter.github.io/typeahead.js/examples/

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent implements OnInit {
 // public searchTerm = "";
 // public searchResults = [];
 @ViewChild('searchInput') searchInput: ElementRef;

 searchHasFocus = false;
 
 public searchTermKeyUp$ = new Subject<string>();

  constructor(public navigation: NavigationService, private dataService: DataService, private globals: GlobalService, private router: Router) { }

// clearSearch(){
//   console.log("clear");
//   this.searchTerm="";
//   this.searchResults = [];
// }

referToSearchPage(){
  if (!this.navigation.searchTerm || this.navigation.searchTerm.length<1) return;
  this.searchInput.nativeElement.blur();
  this.searchInput.nativeElement.value = '';
  this.router.navigate(['/search/' + this.navigation.searchTerm]);

}

  // search(){
 
  //   if (this.navigation.searchTerm.length<3)
  //       { 
          
  //         this.navigation.searchResults = [];
  //         return;
  //       }
  //        this.dataService.getAkasForSearch(this.navigation.searchTerm)
  //        .pipe(
  //          debounceTime(400),
  //          distinctUntilChanged()
  //        )
  //       .subscribe(
  //        result => {
  //             this.navigation.searchResults = result;
  //         });

    
  // }


  ngOnInit() {
    const searchTermSubscription = this.searchTermKeyUp$.pipe(
      map((event: any) => event.target.value),
      tap((term) => { if (!term || term.length < 2) this.navigation.searchResults = [] }),
      filter(term => term.length > 1),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term =>
        this.dataService.getAkasForSearch(term).pipe(
          //map((t:any)=>t.value),
          catchError(() => {
            return of([]);
          }))
      )
    ).subscribe(result => {
      this.navigation.searchResults = result;
    });

  }



  //added 8/6/2018 to replace older search with ngBootstrap version
  // @ViewChild('searchNgbInput') searchNgbInput: NgbTypeahead;
  // focusSearch$ = new Subject<string>();
  // clickSearch$ = new Subject<string>();

  
  // searchRun = (text$: Observable<string>) => {
  //   const debouncedText$ = text$.pipe(debounceTime(400), distinctUntilChanged());
  //   const clicksWithClosedPopup$ = this.clickSearch$.pipe(filter(() => !this.searchNgbInput.isPopupOpen()));
  //   const inputFocus$ = this.focusSearch$;
  //  // const getConcepts$ = this.dataService.getConcepts();


  //   return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$)
  //     .pipe(
  //       distinctUntilChanged(),
  //       switchMap(term =>
  //         this.dataService.getAkasForSearch(term).pipe(
  //           //map((t:any)=>t.value),
  //           catchError(() => {
  //             return of([]);
  //           }))
  //       )
  
  //    // switchMap(() => ),
  //     // tap((term)=>console.log(term)),
  //     // map((term:string) =>  this.dataService.getAkasForSearch(term).filter(v =>
  //     //     v.title.toLowerCase().indexOf(term.toLowerCase()) > -1
  //     //   )
  //     // ).slice(0, 10)),
  //     //tap((terms)=>console.log(terms))
  //   );
  // }
  // searchFormatter = (x: { value: string }) => x.value;


  // searchSelected(ev, input) {
  //   ev.preventDefault();
  //   console.log( ev);
  //   //this.searchConcept = null;
  //   //this.concepts.push(ev.item);
  //  // this.conceptPageResults$.next(this.concepts);
  //   input.value = '';

  // }



}
