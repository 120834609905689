import { Component, OnInit } from '@angular/core';
import { SemanticJsonTreeService } from '../../services/semantic-json-tree.service';
import { NavigationService } from '../../services/navigation.service';
import { ComposeQuizService } from '../../services/compose-quiz.service';
import { Observable } from "rxjs";
import * as _ from "lodash";

@Component({
  selector: 'fpn-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {

  constructor(
    private semanticJsonTreeService: SemanticJsonTreeService,
    private quizService: ComposeQuizService,
    private navigation: NavigationService,

  ) { }

  private currentlyDraggedItem = undefined;

  dlgCodeTitle = '';
  dlgCodeContent;

  sourcePages = [];


  innerHeight: number;

  ngOnInit() {
    this.navigation.setBreadcrumbsToLibraryWorkshopQuiz();

    this.innerHeight = window.innerHeight;

    this.semanticJsonTreeService
      .generateJsonTreeFromSelectedPages()
      .subscribe(p => {
        //console.log(p);
        this.sourcePages = _.values(p);
      });
  }

  flashcardPreview() {

    var OpenWindow = window.open("about:blank", "_blank",
      "fullscreen=yes,menubar=yes,toolbar=yes,scrollbars=yes,top=0,left=0,width=4000,height=4000");

    var quiz$ = this.quizService.generateBlocksFromSelectedPages()
      .subscribe(

        html => {
          var htmlStringified = JSON.stringify(html);
          this.quizService.openHtmlInWindow(htmlStringified, OpenWindow);
        }

      );

  }

  flashcardSave() {
    
    var quiz$ = this.quizService.generateBlocksFromSelectedPages()
      .subscribe(

        html => {

          var htmlStringified = JSON.stringify(html);
          var fullPageHtml = this.quizService.generateFullHtmlPage(htmlStringified);


          //console.log(slideViewer);
          this.dlgCodeContent = fullPageHtml;
          this.dlgCodeTitle = `Select and copy (ctrl-c) the code below and paste (ctrl-v) into a text editor (e.g. NotePad, TextEdit). Save as "filename.html".  Open the file in a browser.`;

          //this is a hack - using jquery to open the modal
          //however, I could not get this to work with viewchild
          //node that the id #dlgCode is the wrapping div within the fpn-dialog-code component
          $('#dlgCode').modal('show')


        }

      );

  }
  dragstart(channel) {
    //console.log("Dragging ", channel);
    //console.log(window);
    this.currentlyDraggedItem = channel;
  }

  dragend(channel: any) {
    //console.log("Drag End ", channel);
    this.currentlyDraggedItem = undefined;
    return false;
  }

}
