import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SemanticJsonTreeService } from "../../services/semantic-json-tree.service";
import { Observable } from "rxjs";
import * as _ from "lodash";
import { IPage } from "../../utility/interfaces"
import { ComposeSlidesService } from "../../services/compose-slides.service";
import { NavigationService } from '../../services/navigation.service';
import { splitMatchedQueriesDsl } from '@angular/core/src/view/util';

@Component({
  selector: 'fpn-drag-organize',
  templateUrl: './drag-organize.component.html',
  styleUrls: ['./drag-organize.component.scss']
})
export class DragOrganizeComponent implements OnInit {

  slides = [];
  private currentlyDraggedItem = undefined;


  @ViewChild('destSlideList') destSlideList: ElementRef;
  constructor(
    private semanticJsonTreeService: SemanticJsonTreeService,
    private composeSlidesService: ComposeSlidesService,
    private navigation: NavigationService,

  ) { }

  dlgCodeTitle = '';
  dlgCodeContent = '';

  sourcePages = [];


  innerHeight: number;

  ngOnInit() {
    this.navigation.setBreadcrumbsToLibraryWorkshopSlidePresenter();

    this.innerHeight = window.innerHeight;

    this.semanticJsonTreeService
      .generateJsonTreeFromSelectedPages()
      .subscribe(p => {
        //console.log(p);
        this.sourcePages = _.values(p);
      });
  }

  generateSlidesAndRun() {
    var OpenWindow = window.open("", "newwin",
      "fullscreen=yes,menubar=yes,toolbar=yes,scrollbars=yes,top=0,left=0,width=4000,height=4000");

    var slideHtml = this.composeSlidesService.generateHtmlPresentationFromSlideSorter(this.slides);

    this.composeSlidesService.openSlideViewer(slideHtml, OpenWindow);

  }

  generateSlidesAndSave() {

    var slideHtml = this.composeSlidesService.generateHtmlPresentationFromSlideSorter(this.slides);
    var slideViewer = this.composeSlidesService.generateFullHtmlPage(slideHtml);
    //console.log(slideViewer);

    this.dlgCodeContent = slideViewer;
    this.dlgCodeTitle=`Select and copy (ctrl-c) the code below and paste (ctrl-v) into a text editor (e.g. NotePad, TextEdit). Save as "filename.html".  Open the file in a browser.`;

    //this is a hack - using jquery to open the modal
    //however, I could not get this to work with viewchild
    //node that the id #dlgCode is the wrapping div within the fpn-dialog-code component
   $('#dlgCode').modal('show')



  }


  dragstart(channel) {
    //console.log("Dragging ", channel);
    //console.log(window);
    this.currentlyDraggedItem = channel;
  }

  dragend(channel: any) {
    //console.log("Drag End ", channel);
    this.currentlyDraggedItem = undefined;
    return false;
  }

  // dragover(channel: any) {
  //   //console.log("Drag Over");
  //   return false;
  // }
  // dragleave(channel: any) {
  //   //console.log("Drag Leave");
  //   return false;
  // }

  // drop(e: any) {
  //   console.log(`Dropped - Move ${this.currentlyDraggedItem} to ${e}`);
  //  // this.move(this.currentlyDraggedItem, e)
  //   return false;
  // }

  deleteSlide(idx) {
    //console.log('deleting slide', idx);
    this.slides.splice(idx, 1);
  }

  deleteAllSlides() {
    if (confirm('Are you sure you want to delete ALL slides?')) {
      this.slides = [];
      // Delete it!
    } else {
      // Do nothing!
    }


  }

  moveSlide(fromIndex, toIndex) {
   if (toIndex<0 || (toIndex>this.slides.length-1)) return false;
    var element = this.slides[fromIndex];
    this.slides.splice(fromIndex, 1);
    this.slides.splice(toIndex, 0, element);
    return true;
}

  moveSlideUp(idx){
    this.moveSlide(idx,idx-1);
  }
  moveSlideDown(idx){
    this.moveSlide(idx,idx+1);
  }
  insertSlide(idx) {
    //console.log("inserting slide", this.currentlyDraggedItem);


    var slide = this.newSlide();
    if (Array.isArray(slide)) {
      //this.slides.splice(idx, 0, slide);
      this.slides.splice.apply(this.slides, [idx, 0].concat(slide));
    }
    else {

      this.slides.splice(idx, 0, slide);
    }

  }

  appendSlide(e) {
    // console.log("appending slide", this.currentlyDraggedItem);

    var slide = this.newSlide();
    if (Array.isArray(slide)) {
      this.slides = this.slides.concat(slide);
    }
    else {
      this.slides.push(slide)

    }
    //console.log(this.slides);

    setTimeout(function (list) {
      list.scrollTop = list.scrollHeight;
    }, 100, this.destSlideList.nativeElement);

  }

  appendToSlide(idx) {
    var slide = this.slides[idx];
    if (this.currentlyDraggedItem) {
      let slideData = this.mapDraggedItemToSlideData(this.currentlyDraggedItem);
      slide.children.push(slideData);
    }
  }
  mapDraggedItemToSlideData(draggedItem) {
    if (draggedItem.ImageLink) {//image
      return {
        title: draggedItem.ImageLink.title,
        imageLink: draggedItem.ImageLink,
        children: draggedItem.Children.map(d => this.mapDraggedItemToSlideData(d))
      }
    }
    else if (draggedItem.Title) {//Page - children are at draggedItem.PageBlockData

      return this.generateSlidesForPage(draggedItem);
      // return {
      //   title: draggedItem.Title,
      //   imageLink: draggedItem.ImageLink,
      //   children: draggedItem.PageBlockData.map(d => this.mapDraggedItemToSlideData(d))
      // }
    }
    else if (draggedItem.Heading) {//PageBlock - children are at draggedItem.Blocks
      return this.generateSlidesForPageBlock(draggedItem);
      // return {
      //   title: draggedItem.Heading,
      //   imageLink: draggedItem.ImageLink,
      //   children: draggedItem.Blocks.map(d => this.mapDraggedItemToSlideData(d))
      // }
    }
    else if (draggedItem.Text) { //Blocks - children are at draggedItem.Children
      return {
        title: draggedItem.Text,
        imageLink: draggedItem.ImageLink,
        children: draggedItem.Children.map(d => this.mapDraggedItemToSlideData(d))
      }
    }
    else { //did not find the interface type
      return null;
    }
  }

  getNLines(block) {
    var n = 0;
    var children = undefined;
    if (block.Blocks) {
      children = block.Blocks;
    }
    if (block.Children) {
      children = block.Children;
    }
    children.forEach(child => {
      n += this.getNLines(child);
    });
    return n;
  }

  generateSlidesForPage(page) {
    var slides = [];
    if (!page.PageBlockData) return slides;

    slides.push({
      title: page.Title,
      imageLink: undefined,
      children: []
    });
    page.PageBlockData.forEach(pageBlock => {

      if (!pageBlock.Heading.match(/See Also/i)) { //don't include the See Also Section
        slides.push({
          title: pageBlock.Heading,
          imageLink: undefined,
          children: []
        });
        //this is a problem - the images do not load correctly if nested in page loop
        //but they load correctly when from pageBlock
        var slidesForBlock = this.generateSlidesForPageBlock(_.cloneDeep(pageBlock));
        slides = slides.concat(slidesForBlock);
      }
    });
    //console.log('page-', slides);
    return slides;
  }
  generateSlidesForPageBlock(parent) {
    var slides = [];
    var looseItems = [];
    var children = [];
    var title = '';
    var isContinued = false;
    var isContinuedImages = false;
    var isPageBlock = false;
    var images = [];

    if (parent.Blocks) {//pageBlock
      children = parent.Blocks;
      title = parent.Heading;
      isPageBlock = true;
    } else if (parent.Children) {
      children = parent.Children;
      title = parent.Text;
    }
    //console.log(parent, ' - children: ', children);
    children.forEach(child => {
      if (child.ImageLink) {
        console.log('imageLink: ', child.ImageLink)
        images.push({
          title: child.ImageLink.title,
          imageLink: child.ImageLink,
          children: child.Children.map(d => this.mapDraggedItemToSlideData(d))
        });
        if (images.length > 1) {
          slides.push({ title: title, children: _.cloneDeep(images) });
          images = [];
          isContinuedImages = true;
        }

      } else if (child.Children && child.Children.length > 0) {
        var moreSlides = this.generateSlidesForPageBlock(child);
        slides = _.concat(slides, moreSlides);
        //slides.push(this.newSlide(child));
      } else {
        looseItems.push({
          title: child.Text,
          imageLink: child.ImageLink,
          children: child.Children.map(d => this.mapDraggedItemToSlideData(d))
        });
        if (looseItems.length > 7) {
          slides.push({ title: title, children: _.cloneDeep(looseItems) });
          looseItems = [];
          isContinued = true;
        }
      }
    });

    if (looseItems.length > 0) {
      slides.push({ title: title, children: _.cloneDeep(looseItems) });
    }

    if (images.length > 0) {
      slides.push({ title: title, children: _.cloneDeep(images) });
    }

    return slides;
  }

  newSlide(item = undefined) {
    if (!item) item = this.currentlyDraggedItem;
    //console.log(this.currentlyDraggedItem);
    if (item) {
      let slideData = this.mapDraggedItemToSlideData(item);
      if (Array.isArray(slideData)) { //slide array is passed back
        return slideData;
      }
      var slideJson = {};

      // debugger; 
      if (slideData.children && slideData.children.length > 0) {
        slideJson = { title: slideData.title, children: slideData.children };
      }
      else if (slideData.imageLink) {
        slideJson = { title: '', children: [slideData] }
      }
      else {
        slideJson = { title: '', children: [slideData] };
      }

      //console.log(slideJson);
      return slideJson;
    }
    else {
      return {}
    }
  }

  removeItem(itemId, fromList) {
    return fromList.filter(function (obj) {
      return obj.id !== itemId;
    });

  }

  move(item, to) {
    //remove item
    // this.sourceItems = this.removeItem(item,this.sourceItems);
    // this.destItems = this.removeItem(item, this.destItems);
    // this.destItems.push({id:item});

  }

}
