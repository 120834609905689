import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fpn-dialog-code',
  templateUrl: './dialog-code.component.html',
  styleUrls: ['./dialog-code.component.scss']
})
export class DialogCodeComponent implements OnInit {

  constructor() { }
  @Input() title:string='';

  //if this is true, then html markup will be displayed as text (<pre>), otherwise the html will be used for display
  @Input() contentVerbatim = true;

  @Input()
  content:string='';
  
  ngOnInit() {
  }

}
