import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapToIterable'
})
export class MapToIterablePipe implements PipeTransform {

  transform(obj: {}, keyName = 'key',valueName = 'value'): any {

          return Object.keys(obj).map(function(key) {
            let pair = {};
 
            pair[keyName] = key;
            pair[valueName] = obj[key];

            return pair;
        });

    }
}



