import { Component, OnInit } from '@angular/core';
import {SearchComponent} from '../../components/search/search.component';


@Component({
  selector: 'app-search-view',
  templateUrl: './search-view.component.html',
  styleUrls: ['./search-view.component.css']
})
export class SearchViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
