import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { PageViewComponent } from './views/page-view/page-view.component';
import { LibraryViewComponent } from './views/library-view/library-view.component';
import { BookViewComponent } from './views/book-view/book-view.component';
import { ChapterViewComponent } from './views/chapter-view/chapter-view.component';
import { PageAnnotationViewComponent } from './views/page-annotation-view/page-annotation-view.component';
import { PageWorkshopViewComponent } from './views/page-workshop-view/page-workshop-view.component';
import { LibraryWorkshopViewComponent } from './views/library-workshop-view/library-workshop-view.component';
import { PageSlideshowViewComponent } from './views/page-slideshow-view/page-slideshow-view.component';
import { SearchViewComponent } from './views/search-view/search-view.component';
import { LoggedInComponent } from './components/logged-in/logged-in.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { BookAnnotationViewComponent } from './views/book-annotation-view/book-annotation-view.component';
import { ChapterAnnotationViewComponent } from "./views/chapter-annotation-view/chapter-annotation-view.component";
import { LibraryAnnotationViewComponent } from "./views/library-annotation-view/library-annotation-view.component";
import { LibraryTopWorkshopViewComponent } from "./views/library-top-workshop-view/library-top-workshop-view.component";
import { DragOrganizeComponent } from './components/drag-organize/drag-organize.component';
import { DiagrammerComponent } from './components/diagrammer/diagrammer.component';
import { QuizComponent } from './components/quiz/quiz.component';
import { DocumaticComponent } from './components/documatic/documatic.component';
import { UmlsTreeTriplerComponent } from './components/umls-tree-tripler/umls-tree-tripler.component';

const routes: Routes = [
  { path: '', redirectTo: '/library', pathMatch: 'full' },
  { path: 'library/annotation', component: LibraryAnnotationViewComponent },
  { path: 'library/workshop', component: LibraryTopWorkshopViewComponent },
  { path: 'library/workshop/slidepresenter', component: DragOrganizeComponent },
  { path: 'library/workshop/diagrammer', component: DiagrammerComponent },
  { path: 'library/workshop/quiz', component: QuizComponent },
  { path: 'library/workshop/documatic', component: DocumaticComponent },
  { path: 'library/workshop/umls', component: UmlsTreeTriplerComponent },
  { path: 'library/:bookAbbrev/workshop', component: LibraryWorkshopViewComponent },
  { path: 'library/:bookAbbrev/annotation', component: BookAnnotationViewComponent },
  { path: 'library/:bookAbbrev/:chapterAbbrev', component: ChapterViewComponent },
  { path: 'library/:bookAbbrev/:chapterAbbrev/annotation', component: ChapterAnnotationViewComponent },
  { path: 'library/:bookAbbrev/:chapterAbbrev/workshop', component: LibraryWorkshopViewComponent },
  { path: 'library/:bookAbbrev', component: BookViewComponent },
  { path: 'library', component: LibraryViewComponent },
  { path: 'library/:bookAbbrev/:chapterAbbrev/:pageAbbrev', component: PageViewComponent },
  { path: 'library/:bookAbbrev/:chapterAbbrev/:pageAbbrev/slideshow/:imageId', component: PageSlideshowViewComponent },
  { path: 'library/:bookAbbrev/:chapterAbbrev/:pageAbbrev/workshop', component: PageWorkshopViewComponent },
  { path: 'library/:bookAbbrev/:chapterAbbrev/:pageAbbrev/annotation', component: PageAnnotationViewComponent }, //, canActivate:[AuthGuardService]  },
  { path: 'search/:term', component: SearchViewComponent },
  { path: 'search', component: SearchViewComponent },
  { path: 'login', component: LoginComponent },
  { path: 'loggedin', component: LoggedInComponent, canActivate: [AuthGuardService] },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'authcallback', component: AuthCallbackComponent },
  { path: '**', redirectTo: '/library' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class FpnAngled2cliRoutingModule { }
