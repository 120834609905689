import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ComposeUmlsTreeService } from '../../services/compose-umls-tree.service';
import { Triple } from '../../models/umls-tree.interface';
import { NavigationService } from '../../services/navigation.service';
import { SemanticJsonTreeService } from '../../services/semantic-json-tree.service';
import * as _ from "lodash";


@Component({
  selector: 'fpn-umls-tree-tripler',
  templateUrl: './umls-tree-tripler.component.html',
  styleUrls: ['./umls-tree-tripler.component.scss']
})
export class UmlsTreeTriplerComponent implements OnInit {


  private currentlyDraggedItem = undefined;


  @ViewChild('destSlideList') destSlideList: ElementRef;

  constructor(
    private umlsTreeService: ComposeUmlsTreeService,
    private semanticJsonTreeService: SemanticJsonTreeService,
    private navigation: NavigationService) { }


  dlgCodeTitle = '';
  dlgCodeContent = '';

  sourcePages = [];


  innerHeight: number;


  triples: Triple[] = [];
  // {
  //   subject:{
  //     title:'subject',
  //     cui:'123',
  //     modifiers:[]
  //   },
  //   verb:{
  //     title:'verb1',
  //     cui:'456',
  //     modifiers:[]
  //   },
  //   objects:[
  //     {title:'object1',
  //      cui:'222',
  //      modifiers:[
  //        {verb:{title:'modifierVerb1',cui:'000'},object:{title:'modifierObj1',cui:'001'},value:'87'},
  //        {verb:{title:'modifierVerb1',cui:'000'},object:{title:'modifierObj1',cui:'001'},value:'87'}
  //       ]
  //     },
  //     {title:'object2',
  //     cui:'333',
  //     modifiers:[]
  //    },
  //    {title:'object3',
  //    cui:'444',
  //    modifiers:[]
  //   }
  //   ]
  // }
  // ];

  ngOnInit() {
    this.navigation.setBreadcrumbsToLibraryWorkshopUmls();

    this.innerHeight = window.innerHeight;

    this.semanticJsonTreeService
      .generateJsonTreeFromSelectedPages()
      .subscribe(p => {
        //console.log(p);
        this.sourcePages = _.values(p);
        console.log(this.sourcePages);
      });
  }

  dragstart(channel) {
    //console.log("Dragging ", channel);
    //console.log(window);
    this.currentlyDraggedItem = channel;
  }

  dragend(channel: any) {
    //console.log("Drag End ", channel);
    this.currentlyDraggedItem = undefined;
    return false;
  }

  createTriple(subjectTitle = '', subjectCui = '', verbTitle = '', verbCui = '') {
    return {
      subject: { title: subjectTitle, cui: subjectCui, modifiers: [] },
      verb: { title: verbTitle, cui: verbCui, modifiers: [] },
      objects: []
    }
  }
  createTripleObject(title = '', cui = '') {
    return { title: title, cui: cui, modifiers: [] }
  }

  addUmlsTriple(triple: Triple = null) {
    var t = (triple) ? triple : this.createTriple();
    this.triples.unshift(t);
  }

  addObjectToTriple(triple) {
    triple.objects.push(this.createTripleObject());




  }
  addDroppedObjectToTriple(triple) {
    if (!this.currentlyDraggedItem) return; //no dragged item

    if (this.currentlyDraggedItem.Blocks) {//if dragging a pageBlock
      triple.objects.push(this.createTripleObject(this.currentlyDraggedItem.Heading));
    } else if (this.currentlyDraggedItem.Children) {//if dragging a PageBlockSubset
      var tripleObj = this.draggedItemToTripleObj(this.currentlyDraggedItem);
      triple.objects.push(tripleObj);
    }

  }
  deleteObjectFromTriple(obj) {
    //console.log(obj);
    let triple: Triple = obj.triple;
    let idxObj: number = obj.idxObj;
    triple.objects.splice(idxObj, 1);

  }

  deleteTriple(idxTriple) {
    this.triples.splice(idxTriple, 1);
  }


  dragover(channel: any) {
    //console.log("Drag Over");
    return false;
  }

  appendNewTripleWithDroppedContent() {
    //console.log('dropped');
    //console.log(this.currentlyDraggedItem);
    // this.onappendslide.emit(-1);

    if (!this.currentlyDraggedItem) return; //no dragged item

    if (this.currentlyDraggedItem.PageBlockData) { //if dragging a page
      this.appendNewTripleWithDroppedPage();
    } else if (this.currentlyDraggedItem.Blocks) {//if dragging a pageBlock
      this.appendNewTripleWithDroppedPageBlock();
    } else if (this.currentlyDraggedItem.Children) {//if dragging a PageBlockSubset
      if (this.currentlyDraggedItem.PageLineage) {
        this.appendNewTripleWithDroppedPageBlockTopSubset();
      } else {
        this.appendNewTripleWithDroppedPageBlockSubset();
      }

    }
  }
  appendNewTripleWithDroppedPageBlockSubset(): any {
    console.log('dropped page block subset not implemented yet');
  }
  appendNewTripleWithDroppedPageBlockTopSubset(): any {
    //this is managed in a similar fashion to the dropped PageBlock
    //PageLineage and PageBlockHeading are available
    console.log('dropped page block top subset is being implemented');

    var page = this.getPageSourceFromPageLineage();
    var heading = this.currentlyDraggedItem.PageBlockHeading;

    var triple = this.createTriple(page.Akas[0].Title, page.Akas[0].Cui, heading);
    triple.subject['menu'] = page.Akas.map(a => this.createTripleObject(a.Title, a.Cui));

    this.currentlyDraggedItem.Children.forEach(child => {
      var tripleObj = this.draggedItemToTripleObj(child);
      triple.objects.push(tripleObj);
    });
    this.triples.push(triple);
    console.log(triple);
  }
  private draggedItemToTripleObj(child: any) {
    var tripleObj;
    if (child.Links && child.Links.length > 0) {
      tripleObj = this.createTripleObject(child.Links[0].Text, child.Links[0].Cui);
      tripleObj['menu'] = child.Links.map(link => this.createTripleObject(link.Text, link.Cui));
      var match = child.Links.filter(link => link.Text.trim() === child.Text.trim());
      if (!match || match.length < 1) {
        tripleObj['menu'].push(this.createTripleObject(child.Text, ""));
      }

    }
    else {
      tripleObj = this.createTripleObject(child.Text);
      tripleObj['menu'] = [];
      tripleObj['menu'].push(this.createTripleObject(child.Text, ""));
    }
    return tripleObj;
  }

  appendNewTripleWithDroppedPageBlock(): any {
    console.log('dropped page block being developed');
    var page = this.getPageSourceFromPageLineage();

    //heading will need to be broken up before the : , ()
    //after the : , () will be used as modifiers
    //will need to look up menu options based on heading
    var heading = this.currentlyDraggedItem.Heading;
    var triple = this.createTriple(page.Akas[0].Title, page.Akas[0].Cui, heading);

    triple.subject['menu'] = page.Akas.map(a => this.createTripleObject(a.Title, a.Cui));

    //now walk the blocks and add these as objects to the triple
    //bring in only the first level blocks (not children)
    this.currentlyDraggedItem.Blocks.forEach(block => {
      var tripleObj = this.draggedItemToTripleObj(block);
      triple.objects.push(tripleObj);
    });
    this.triples.push(triple);
    //console.log(triple);
  }
  private getPageSourceFromPageLineage() {
    var pageLineage = this.currentlyDraggedItem.PageLineage;
    var page = _.find(this.sourcePages, function (p) { return p.Lineage === pageLineage; });
    return page;
  }

  appendNewTripleWithDroppedPage(): any {
    //console.log('dropped page not implemented yet');
  }




  saveTriplesAsJson() {
    this.dlgCodeContent = JSON.stringify(this.triples);
    this.dlgCodeTitle = `Select and copy (ctrl-c) the code below and paste (ctrl-v) into a text editor (e.g. NotePad, TextEdit). Save as "filename.json".`;

    //this is a hack - using jquery to open the modal
    //however, I could not get this to work with viewchild
    //node that the id #dlgCode is the wrapping div within the fpn-dialog-code component
    $('#dlgCode').modal('show')

  }


  clearAllTriples() {
    if (confirm('Are you sure you want to delete ALL Triples?')) {
      this.triples = [];
      // Delete it!
    } else {
      // Do nothing!
    }


  }
}
