import { Component, OnInit, Input } from '@angular/core';
import { Link } from '../../models/link';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'fpn-toc-header',
  templateUrl: './toc-header.component.html',
  styleUrls: ['./toc-header.component.scss']
})
export class TocHeaderComponent implements OnInit {

  constructor(public globals:GlobalService) { }
 @Input() mainLink : Link;
 @Input() menuLinks : Link[]; 
  ngOnInit() {
  }

}
