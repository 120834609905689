import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../services/data.service';
import { GlobalService } from '../../services/global.service';
import { NavigationService } from '../../services/navigation.service';
import { Router } from '@angular/router';
import { Link } from '../../models/link';
import * as _ from "lodash";

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit {
  public isWorkshop: boolean;
  public isAnnotation: boolean;
  @Input() isKeyView = false;
  public library: any;

  constructor(private dataService: DataService,
    public router: Router,
    public navigation: NavigationService) { }



  get headerLink(): Link {
    return {
      title: `BOOKS`,
      path: ''
    };
  }

  get headerMenuLinks(): Link[] {
    return [{
      title: `Library Workshop`,
      path: `/library/workshop`
    },
    {
      title: `Library Annotation`,
      path: `/library/annotation`
    }
    ];
  }

  get linkList():Link[]{
    //todo: memoize - this is called too often
    var currentBook = this.navigation.currentBook;
    var links = this.library.children.map(book=>{
      return {
        title: `${book.name} Book`,
        path: `/library/${book.path}`,
        selected:(currentBook && book.path==currentBook.path)
      }
    });
    return links;
  }

  
  get annotationHeaderLink():Link {
    return {
      title: `BOOKS`,
      path:'/library/annotation'
    };
  }
  
  get annotationHeaderMenuLinks():Link[] {
    return [{
      title: `Library Workshop`,
      path:`/library/workshop`
    },
    {
      title: `Library Index`,
      path:`/library`
    }
  ];
  }

  get annotationLinkList():Link[]{
    //todo: memoize - this is called too often
    var currentBook = this.navigation.currentBook;
    var links = this.library.children.map(book=>{
      return {
        title: `${book.name} Book`,
        path: `/library/${book.path}/annotation`,
        selected:(currentBook && book.path==currentBook.path)
      }
    });
    return links;
  }



  get workshopHeaderLink():Link {
    return {
      title: `BOOKS`,
      path:'/library/workshop'
    };
  }
  
  get workshopHeaderMenuLinks():Link[] {
    return [{
      title: `Library Annotation`,
      path:`/library/annotation`
    },
    {
      title: `Library Index`,
      path:`/library`
    }
  ];
  }

  get workshopLinkList():Link[]{
    //todo: memoize - this is called too often
    var currentBook = this.navigation.currentBook;
    var links = this.library.children.map(book=>{
      return {
        title: `${book.name} Book`,
        path: `/library/${book.path}/workshop`,
        selected:(currentBook && book.path==currentBook.path)
      }
    });
    return links;
  }



  updateNavigation() {
    this.navigation.setBreadcrumbsToLibrary();
  }


  ngOnInit() {
    var url = this.router.url;
    //annotation breadcrumbs may load after page, so using url instead
    this.isAnnotation = url.toLowerCase().endsWith("annotation");
    this.isWorkshop = url.toLowerCase().endsWith("workshop");

    // this.dataService.getPageByUrl('cv','anatomy','CrdvsclrAntmy')
    this.dataService.getLibraryTree()
      .subscribe(
        result => {
          this.library = result;
          if (this.isKeyView) {
            if (this.isWorkshop) {
              this.navigation.setBreadcrumbsToLibraryWorkshop();
            }
            else {
              this.navigation.setBreadcrumbsToLibrary();
            }
          };
        },
        error => { console.log(error); }

      );


  }

}
