import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Preferences } from '../models/preferences';
import { UserInfo } from '../models/user-info.interface';

@Injectable()
export class GlobalService {
  host = window.location.host;
  protocol = window.location.protocol;
  isAuthenticationDisabled = false;

  public preferences: Preferences = {
    isUIcompact: false
  };

  public http = (this.protocol.search(/https/i) > -1) ? 'https' : 'http';
  public domain = (this.host.search(/www/i) > -1) ? this.http + '://www.fpnotebook.com/' : this.http + '://fpnotebook.com/';
  public isProduction = environment.production;
  public appRootAttributes = {};
  public isLocalHost = (this.host.indexOf('localhost') >= 0);
  public inDebugMode = true;


  public userInfo: UserInfo = null;


  public rootDataPath = environment.paths.rootDataPath;

  public pathPages = this.rootDataPath + environment.paths.jsonHtmlFileDir;

  public pathLibraryIndex = this.rootDataPath + environment.paths.jsonLibraryIndexDir;

  //private fullPathApi = this.domain + "drbits/fpnannotationapi/api/";
  private fullPathApi = (environment.production) ? "https://fpnotebook.com/drbits/fpn2017api/api/" : "http://localhost:5001/api/";

  public apiRootUrl = (environment.production) ? "https://fpnotebook.com/drbits/fpn2017api/" : "http://localhost:5001/";


  public userProfilEditUrl = (environment.production) ? "https://fpnotebook.com/drbits/identityserver/Manage/Index" : "http://localhost:5000/Manage/Index";

  public htmlToPdf = (environment.production) ? "https://fpnotebook.com/drbits/fpnMvc/ContentConverter/HtmlToPdf" : "http://localhost:5002/ContentConverter/HtmlToPdf";

  private pathAnnotationList = this.fullPathApi + "Annotation/list";//"assets/sample-data/annotations.json";





  // public breadcrumbs : IBreadcrumb[];
  // public searchInput : string;

  public getPathApi() {
    // if (environment.production)
    //     return '/api/';
    // else
    return this.fullPathApi;
  }

  //after attending NDC 2018 Mpls - Broch Allen recommends using simple html (auth.html, silent-refresh.html) as the callback instead of angular route
  //this should still work as the oidc-client simply writes to web storage, and the user manager in authservice can simply reads from it
  //there is a call back for when the user is loaded (and unloaded)
  public IdentityConfig = {
    authority: (environment.production) ? "https://fpnotebook.com/drbits/identityserver" : "http://localhost:5000",
    client_id: "DI0g4oj7j6sH7Pe6uU0BFYm9xCT2dBwYBvTWQ6xLyPt37sNmcJ.ng.fpnotebook.com",
    redirect_uri: (environment.production) ? "https://fpnotebook.com/drbits/fpn2017ng/assets/auth.html" : "http://localhost:4200/assets/auth.html",
    //?"https://fpnotebook.com/drbits/fpn2017ng/authcallback": "http://localhost:4200/authcallback",
    response_type: "id_token token",
    scope: "openid profile api1 email phone roles custom.profile",
    post_logout_redirect_uri: (environment.production) ? "https://fpnotebook.com/drbits/fpn2017ng/library" : "http://localhost:4200/library",
    silent_redirect_uri: (environment.production) ? "https://fpnotebook.com/drbits/fpn2017ng/assets/silent-refresh.html" : "http://localhost:4200/assets/silent-refresh.html",
    automaticSilentRenew: true,


    //silentRequestTimeout:10000,

    //filterProtocolClaims: true,
    loadUserInfo: true
  };



  constructor() {
    //console.log("domain: ",this.domain);
    //console.log(environment);
  }

}
