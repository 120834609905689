import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'fpn-drag-content-dest-slides',
  templateUrl: './drag-content-dest-slides.component.html',
  styleUrls: ['./drag-content-dest-slides.component.scss']
})

export class DragContentDestSlidesComponent implements OnInit {
  // static onAppendNewSlideWithDroppedContent = "onAppendNewSlideWithDroppedContent";
  // static onAppendNewBlankSlide = "onAppendNewBlankSlide";

  // static onInsertNewSlideWithDroppedContent = "onInsertNewSlideWithDroppedContent";

  // static onInsertNewBlankSlide = "onInsertNewBlankSlide";
  
  constructor() { }

  // @Input()
  // destdata : any[];
  
  @Input()  slides:any[];

  @Input() slideType : string;
  
  // @Output()
  // ondrop  : EventEmitter<string> = new EventEmitter<string>();

  @Output()
  oninsertslide  : EventEmitter<number> = new EventEmitter<number>();

  @Output()
  onappendslide  : EventEmitter<number> = new EventEmitter<number>();

  @Output()
  onappendtoslide  : EventEmitter<number> = new EventEmitter<number>();

  @Output()
  ondeleteslide  : EventEmitter<number> = new EventEmitter<number>();
  
  @Output()
  onMoveSlideUp  : EventEmitter<number> = new EventEmitter<number>();

  @Output()
  onMoveSlideDown  : EventEmitter<number> = new EventEmitter<number>();

  ngOnInit() {
  }

  // drop(id:string){
  //   console.log('dropped');
  //   this.ondrop.emit(id);
  // }

  dragover(channel: any) {
 //console.log("Drag Over");
  return false;
 }
  dragleave(channel: any) {
    //console.log("Drag Leave");
    return false;
  }

  appendNewBlankSlide(){
    this.onappendslide.emit(-1);
  }
  appendNewSlideWithDroppedContent(){
    //console.log('dropped');
    this.onappendslide.emit(-1);
  }
  appendToSlideWithDroppedContent(idx){
    this.onappendtoslide.emit(idx);   
  }
  deleteSlide(idx){
    console.log('dest-slides is deleting', idx)
    this.ondeleteslide.emit(idx);
  }

 
  moveSlide(idx, ev){
    if (ev==='up') {
      this.onMoveSlideUp.emit(idx);
    } else if (ev==='down'){
      this.onMoveSlideDown.emit(idx);
    }

  }
  insertNewBlankSlide(idx){
    this.oninsertslide.emit(idx);
  }
  insertNewSlideWithDroppedContent(idx){
    console.log('dropped', idx);
    this.oninsertslide.emit(idx);
  }


}
