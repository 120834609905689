import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { SelectionService } from './selection.service';
import { from ,  of , Observable} from 'rxjs';
import { map, flatMap, reduce, switchMap } from 'rxjs/operators';

@Injectable()
export class ComposePdfService {
  

  constructor(private selectionService: SelectionService, private dataService: DataService) { }


  printPdf(html, OpenWindow){
    // window.open(this.global.htmlToPdf,"_blank");
    //console.log("----------------");
    //console.log(this.selectionService);
    //console.log(this.selectionService.selectedPages);
   // console.log(html);
  //var OpenWindow = window.open("", "newwin", "height=650, width=900");
   //var OpenWindow = window.open("", "newwin", "height=650, width=900,toolbar=no,scrollbars=" + scroll + ",menubar=no");
    
   
   //replaced the following code with generateHtml method below
   //this method receives the full page html
  //  OpenWindow.document.write("<!DOCTYPE html><html><head><meta http-equiv='content-type' content='text/html;charset=UTF-8' />");
  //   OpenWindow.document.write("<TITLE>FPnotebook PDF</TITLE>");
  //   OpenWindow.document.write("<link rel='stylesheet' href='https://unpkg.com/gutenberg-css@0.4' media='print'>");
  //   OpenWindow.document.write("<link rel='stylesheet' href='https://unpkg.com/gutenberg-css@0.4/dist/themes/modern.min.css' media='print'> <!-- optional -->");

  //   OpenWindow.document.write("<style>ol  {list-style-type: upper-roman;}ol ol {list-style-type: upper-alpha;}ol ol ol {list-style-type: decimal;}ol ol ol ol {list-style-type: lower-alpha;}ol ol ol ol ol {list-style-type: lower-roman;}</style>");
   

  //   OpenWindow.document.write("<BODY onload='print();' >");
  //   OpenWindow.document.write(html);
  //    OpenWindow.document.write("</BODY>");
  //   OpenWindow.document.write("</HTML>");

    OpenWindow.document.write(html);
    OpenWindow.document.close()
    
    OpenWindow.focus();
    //OpenWindow.print(); 

    self.name = "main"
}
generateHtmlPage(htmlContent){
  var html = `
  <!DOCTYPE html><html><head><meta http-equiv='content-type' content='text/html;charset=UTF-8' />
  <TITLE>FPnotebook.com Custom Excerpt</TITLE>
  <link rel='stylesheet' href='https://unpkg.com/gutenberg-css@0.4' media='print'>
  <link rel='stylesheet' href='https://unpkg.com/gutenberg-css@0.4/dist/themes/modern.min.css' media='print'>
  <style>ol {page-break-inside: initial;} ol {orphans: 3; widows: 3;}h4 + ol {page-break-before: avoid;}ol {list-style-type: upper-roman;}ol ol {list-style-type: upper-alpha;}ol ol ol {list-style-type: decimal;}ol ol ol ol {list-style-type: lower-alpha;}ol ol ol ol ol {list-style-type: lower-roman;}</style>
  <BODY onload='print();' >
  ${htmlContent}
  </BODY>
  </HTML>
  `;
  return html;
}
generateCoverPageHeader(){
  var text = `<a class="logo no-reformat" href="https://fpnotebook.com/drbits/fpn2017ng/library">
  <img id="logo-header" src="https://fpnotebook.com/_assets2016/img/fpn-default.png" />
</a>`;
return text;
}
generateCoverPage(title:string = "Custom Excerpt", toc: string = ""):string{
   
  var today = new Date().toLocaleDateString("en-US"); 
  var text = "<div style='float:left'>" + this.generateCoverPageHeader() + "</div>";
 text += `<div style='float:right'><h4>${title}</h4><h5><i>generated on ${today} </i></h5></div><div style='clear: both;'></div>`;

 text += toc;

  return text + "<div style='page-break-after: always;'></div>";
}

replacer(match, p1, offset, string) {
  // p1 is nondigits, p2 digits, and p3 non-alphanumerics
  return ``;
}

replaceSlideshowLinks(pageBlockContent,imageLinks){
  var rgx = /<a href='.*?\/slideshow\/(\d*)'>.*?<\/a>/mi;
  return pageBlockContent.replace(rgx,
    (_,p1)=>{
      var n = Number(p1)-1;
      var link = imageLinks[n];
      return this.generateImageThumbnail(link);
     }
  )
}
  generateFullPage(pageData) {
    var text = "<ol>";
    pageData.PageBlockData.forEach(pageBlock => {
      var pageBlockContentMod = this.replaceSlideshowLinks(pageBlock.Content,pageData.ImageLinks)
      text += "<li>" + pageBlock.Heading + pageBlockContentMod + "</li>";
    });
    text += "</ol>";
    return text;
  }

generateImageThumbnail(link){
  var text = "";
  text += `<a href="${link.url}" target="_blank" class="no-reformat"><img src="${link.url}" height="100px"></a>`;
  return text;
}
  generateOutlineLevel(level, imageLinks) {
    var text = "";
    if (level && level.Html) {
      text += "<li>";
      text += this.convertSlideShowLinks(level.Html, imageLinks);

      if (level.Children) {
        text += "<ol>" + level.Children.filter(b => b.Selected).map(child => this.generateOutlineLevel(child, imageLinks)).reduce((acc, curr) => acc + curr, "") + "</ol>";
      }
      text += "</li>";
    }
    return text;
  }
  private convertSlideShowLinks(sourceText,  imageLinks: any) {

     var text = "";
    var slideshowImageMatch =  sourceText.match(/\/slideshow\/(\d*)'>/im);
    if (slideshowImageMatch && slideshowImageMatch.length > 1) {
      var nSlide = Number(slideshowImageMatch[1]) - 1;
      text += this.generateImageThumbnail(imageLinks[nSlide]);
    }
    else {
      text += sourceText;
    }
    return text;
  }

  generateOnlySelectedBlocksFromPage(pageData, fullPageData) {
    var text = "<ol>";
    for (let blockKey in pageData.selectedBlocks) {
      let selectedBlock = pageData.selectedBlocks[blockKey];
     // console.log(selectedBlock);
      text += "<li>" + selectedBlock.Heading;

      if (selectedBlock) {
        var subText = "";
        selectedBlock.Blocks.filter(b=> (b.Selected===true))
          .forEach(childA => {
            subText+= this.generateOutlineLevel(childA, fullPageData.ImageLinks);
          });

        if (subText && subText.length > 0) 
          text += "<ol>" + subText + "</ol>";
      }

      text += "</li>";
    }
    text += "</ol>"
    return text;
  }

  generateTOC(){
    var text = "<br/><h4>Table of Contents</h4><blockquote><ol>";
    var bookName = "";
    var chapterName = ""; 
    for (let key in this.selectionService.groupedSelectedPages) {
      let section = this.selectionService.groupedSelectedPages[key];
      let sectionName = section[0].parsedLineage.section.name;
      if (bookName!==section[0].parsedLineage.book.name) {
        if (bookName.length>0) text +="</ol></li>"
        bookName = section[0].parsedLineage.book.name; 
        text += `<li>${bookName} Book<ol>`;
      }
      if (chapterName !== section[0].parsedLineage.chapter.name) {
        if (chapterName.length>0) text +="</ol></li>"
        chapterName = section[0].parsedLineage.chapter.name;
        text += `<li>${chapterName} Chapter <ol>`
      }
      text+=`<li>${sectionName} Section <ol>`;
      section.forEach(page => {
        //console.log(page);
        let isAbridged = (page.allSelected) ? "":" (abridged)";
        text += `<li><a href="#${page.lineage}">${page.title}</a>${isAbridged}</li>`;
      });
      text+="</ol></li>"
    }
    return text + "</ol></li></ol></li></ol></blockquote>";
  }
  generatePageHeader(page: any, linkRoot:string): string {
    var text = "";
    //console.log('page',page);
    text += `<h4 id='${page.Lineage}'>
    <a target='_blank' class='no-reformat' href='${linkRoot}library${page.PageUrl}'>${page.Title}</a></h4>`;
    return text;
  }

  generateSectionHeader(sectionTitle: string): string {
    var text = "";
    text += "<hr/><h6 style='text-align: center;'> " + sectionTitle + "</h6><hr/>"
    return text;
  }

//   loadFullPages() {
//     var pages = {};
//     var completed = false;
//     var pagesToGet = Object.entries(this.selectionService.selectedPages)
//       .map(s => s[1])
//       .filter(s => s["allSelected"]);

//     return from(pagesToGet)
//       .pipe(
//       flatMap(p => {
//         var info = p["parsedLineage"];
//         return this.dataService.getPageByUrlWithoutModifyingNavigation(info.book.path, info.chapter.path, info.page.path)
//       })
// //      ,reduce((acc,p)=> acc[p["lineage"]]=p,{})
//     )
//   }

  replaceHtmlLink(pageText: string, linkRoot:string): any {


    const regex = /\<a href='#\//gmi;
   // const subst = `<a target='_blank' class='no-reformat' href='`+linkRoot;
   const subst = `<a target='_blank' class='no-reformat' href='`+linkRoot;

    // The substituted value will be contained in the result variable
    const pageTextMod = pageText.replace(regex, subst);

    //we also need to replace external links with class='no-reformat'
    //otherwise nasty reformatting by Guttenberg.css
    const regexExtLink = /\<a href='http/gmi;
   const substExtLink = `<a target='_blank' class='no-reformat' href='http`;
    const pageTextModExtLink = pageTextMod.replace(regexExtLink, substExtLink);

    return pageTextModExtLink;
  }


  convertToHtml(fullPageDict, linkRoot:string){
    //console.log("fullpagedict",fullPageDict);

    var returnText = this.generateCoverPage("Custom Excerpt",this.generateTOC());
    for (let key in this.selectionService.groupedSelectedPages) {
      let section = this.selectionService.groupedSelectedPages[key];
      let sectionName = section[0].parsedLineage.section.name;
      let bookName = section[0].parsedLineage.book.name;
      let chapterName = section[0].parsedLineage.chapter.name;
      //console.log("section", sectionName);
      var sectionText = this.generateSectionHeader(
        `${bookName} > ${chapterName} > ${sectionName} Section`);
      section.forEach(page => {
        var pageText = "";
        pageText += this.generatePageHeader(fullPageDict[page.lineage],linkRoot);
        if (page.allSelected) {
          //this involves downloading each of the pages
           //console.log(fullPageDict[page.lineage]);
           pageText+= this.generateFullPage(fullPageDict[page.lineage]);

         } else {
          //console.log("only show selected data", page);
           pageText += this.generateOnlySelectedBlocksFromPage(page, fullPageDict[page.lineage]);
        }
        sectionText += this.replaceHtmlLink(pageText,linkRoot) + "<div style='page-break-after: always;'></div>";
      });
      returnText += sectionText;
    }

    return returnText;
  }

  generateHtmlFromSelectedPages(linkRoot:string = "https://fpnotebook.com/drbits/fpn2017ng/") {
    var that = this;
    return new Observable(observer=>{
     var pages = {};
     this.dataService.loadFullPages(this.selectionService.selectedPages).subscribe(
       p=>{
        // console.log('writing Lineage: ',p);
         pages[p["Lineage"]]=p
        },
       (err)=>observer.error("loadFullpages error: " + err),
       ()=>{
         //On complete
        // console.log(pages);
         var returnText = that.convertToHtml(pages, linkRoot);
         observer.next(returnText);
         return observer.complete();
      }
     )
    });

     


  }

}



//Old content from organizing outline

        // text += "<ol><li>" + childA.Html;

        // if (childA) childA.Children.filter(b => b.Selected).forEach(child1 => {
        //   text += "<ol><li>" + child1.Html;
        //   if (child1) child1.Children.filter(b => b.Selected).forEach(childa => {
        //     text += "<ol><li>" + childa.Html;
        //     if (childa) childa.Children.filter(b => b.Selected).forEach(childi => {
        //       text += "<ol><li>" + childi.Html;
        //       if (childi) childi.Children.filter(b => b.Selected).forEach(childx => {
        //         text += "<ol><li>" + childx.Html + "</li></ol>";
        //       });
        //       text += "</li></ol>";
        //     });
        //     text += "</li></ol>";
        //   });
        //   text += "</li></ol>";
        // });

        // text += "</li></ol>";
