import { Component, OnInit } from '@angular/core';
import {GlobalService} from '../../services/global.service'
@Component({
  selector: 'fpn-nav-main-menu',
  templateUrl: './nav-main-menu.component.html',
  styleUrls: ['./nav-main-menu.component.scss']
})
export class NavMainMenuComponent implements OnInit {

  constructor(public globals:GlobalService) { }

  ngOnInit() {
  }

}
