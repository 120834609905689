import { Component, OnInit } from '@angular/core';
import {AuthenticateService} from '../../services/authenticate.service';
import {GlobalService} from '../../services/global.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  _user: any;
  loadedUserSub: any;
  identity : any;
  constructor(public authenticateService:AuthenticateService, private globals:GlobalService ) { }

  ngOnInit() {
     this.loadedUserSub = this.authenticateService.userLoadededEvent
      .subscribe(user => {
        this._user = user;
      });
  }


clearState() {
    this.authenticateService.clearState();
  }
  getUser() {
    this.authenticateService.getUser();
  }
  removeUser() {
    this.authenticateService.removeUser();
  }
  startSigninMainWindow() {
    this.authenticateService.startSigninMainWindow();
  }
  endSigninMainWindow() {
    this.authenticateService.endSigninMainWindow();
  }
  startSignoutMainWindow() {
    this.authenticateService.startSignoutMainWindow();
  }
  endSignoutMainWindow() {
    this.authenticateService.endSigninMainWindow();
  }

getApiData(){
  var loadedApiIdentity = this.authenticateService.AuthGet(this.globals.apiRootUrl + 'identity')
      .subscribe(data=>{this.identity = data});
}

  ngOnDestroy(){
    if(this.loadedUserSub.unsubscribe()){
      this.loadedUserSub.unsubscribe();
    }
  }


 
}
