import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IPage } from '../../utility/interfaces';
import * as _ from 'lodash';

@Component({
  selector: 'fpn-drag-content-source',
  templateUrl: './drag-content-source.component.html',
  styleUrls: ['./drag-content-source.component.scss']
})
export class DragContentSourceComponent implements OnInit {

  public selectedPageExpanded: any = {};

  // @Input()
  // sourcedata:[any];

  @Input()
  sourcePages: any[];
  
  @Output()
  ondragstart : EventEmitter<Object> = new EventEmitter<Object>();

  @Output()
  ondragend : EventEmitter<Object> = new EventEmitter<Object>();

  constructor() { }

  ngOnInit() {
  }

  dragstart(item){
    //console.log("started dragging",item);
    this.ondragstart.emit(item);
    return false;
  }
  dragend(item){
    this.ondragend.emit(item);
  }
  blockToArray(obj){
    return _.values(obj);
  }
}
