import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-library-workshop-view',
  templateUrl: './library-workshop-view.component.html',
  styleUrls: ['./library-workshop-view.component.less']
})
export class LibraryWorkshopViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
