import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { DataService } from '../../services/data.service';
import { NavigationService } from '../../services/navigation.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, map, filter, subscribeOn, defaultIfEmpty, flatMap, reduce } from 'rxjs/operators';
import { Observable, of, Subject, from, merge } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import * as _ from 'lodash'
import { PageLink } from '../../models/page-link.interface';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Concept } from '../../models/concept.interface';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

 

  @Input() isKeyView = false;
  @ViewChild('searchConceptInput') searchConceptInput: NgbTypeahead;
  focusSearchConcept$ = new Subject<string>();
  clickSearchConcept$ = new Subject<string>();

 conceptPageIds: number[]=[];
 conceptPages :PageLink[]=[];

  searchTerm: string = '';
  searchingTermTypeAhead: boolean;
  searchTermTypeAheadFailed: boolean;
  searchType: string = 'By Title   ';

  filterTerm: string = '';

  filterTerms: string[] = [];


  searchConcept: string = "";


  //conceptTerm: string = '';

  concepts: Concept[] = [];

  filteredPageResults = [];

  linksForPageIds = {};

  _isVisible = {};
  public searchTermKeyUp$ = new Subject<string>();
  public filterTermKeyUp$ = new Subject<string>();

  public pageLinkResults$ = new Subject<PageLink[]>();

  public conceptPageResults$ = new Subject<Concept[]>();

  constructor(public dataService: DataService,
    public navigation: NavigationService,
    private route: ActivatedRoute,
    public globals: GlobalService,
    private router: Router

  ) {
    let that = this;
    const searchTermSubscription = this.searchTermKeyUp$.pipe(
      map((event: any) => event.target.value),
      tap((term) => { if (!term || term.length < 2) this.navigation.searchPageResults = [] }),
      filter(term => term.length > 1),
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searchingTermTypeAhead = true),
      switchMap(term =>
        this.dataService.getAkasForSearch(term).pipe(
          //map((t:any)=>t.value),
          tap((results) => {
            this.searchTermTypeAheadFailed = false;
          }),
          catchError(() => {
            this.searchTermTypeAheadFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searchingTermTypeAhead = false)
    ).subscribe((result: PageLink[]) => {
      this.navigation.searchPageResults = result;


      // this.pageLinkResults$.next(result);
    });



    const filterTermSubscription = this.filterTermKeyUp$.pipe(
      map((event: any) => (event && event.target && event.target.value) ?
        event.target.value.toLowerCase() : ''),
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.filteredPageResults = []),
      tap(() => this.searchingTermTypeAhead = true),
      switchMap(term =>
        this.getFilteredTerms()
        // from(this.navigation.searchPageResults).pipe(
        //   filter((resultItem: any) =>
        //     resultItem.value.toLowerCase().includes(term)
        //   ),
        //   tap((results) => {
        //     this.searchTermTypeAheadFailed = false;

        //   }),
        //   catchError(() => {
        //     this.searchTermTypeAheadFailed = true;
        //     return of([]);
        //   }))
      ),
      tap(() => this.searchingTermTypeAhead = false)
    ).subscribe(result => {

      this.filteredPageResults.push(result);

    });

    const pageLinkChanges = this.pageLinkResults$
      .pipe(
        //tap(r=>console.log('inside pageLinkResults ',r)),
        map(r => r.map(l => l.pid.toString())),
        switchMap(ids => this.dataService.getLinksInForPageIds(ids))
      )
      .subscribe(
        (res) => {

          if (res) {
            _.forEach(res, function (value, key) {
              that.linksForPageIds[key] = value;
            });
          }
          // console.log('pageLinkChanges', res);
        },
        err => console.log('error occurred in pageLinkChanges'),
        () => console.log('pageLinkChanges Observable is complete')

      );


    const conceptPageChanges = this.conceptPageResults$
      .pipe(
        tap(() => this.conceptPageIds = []),
        map(concepts => concepts.map(concept => concept.id.toString())),
        switchMap(ids => this.dataService.getPageIdsInCommonForConceptIds(ids)),
    ).subscribe(
      (conceptPageIds: number[]) => {
        this.conceptPageIds = conceptPageIds;

       
        this.dataService.getPagesForIds(conceptPageIds).subscribe(
            (res:PageLink[]) => {this.conceptPages = res; console.log(res);}
        );
        //now, filter the aka lists to only show those with these concepts
        //if no search terms exist, then only show the pages with these concepts
      }
    )


  }

  clearSearchText() {
    this.searchTerm = "";
    this.filterTerm = "";
    if (this.navigation.searchPageTerm && this.navigation.searchPageTerm.length > 0) {//clear filter
      this.filterTermKeyUp$.next(null);
    } else {//clear main title search
      this.clearMainSearchTerm();
    }

  }

  clearMainSearchTerm() {
    this.navigation.searchPageResults = [];
    this.filteredPageResults = [];
    this.navigation.searchPageTerm = "";
    this.router.navigateByUrl('search/');
    this.filterTerms = [];
  }


  clearFilterTerm(idx) {
    if (idx > -1) {
      this.filterTerms.splice(idx, 1);

      this.rebuildFilteredPageResults();

    }
  }

  rebuildFilteredPageResults() {
    this.filteredPageResults = [];

    this.getFilteredTerms()
      .subscribe(result => {

        this.filteredPageResults.push(result);

      });
  };

 

  clearConcept(idx) {
    if (idx > -1) {
      this.concepts.splice(idx, 1);
      this.conceptPageResults$.next(this.concepts);
      // this.filteredPageResults = [];

      // this.getFilteredTerms()
      //   .subscribe(result => {

      //     this.filteredPageResults.push(result);

      //   });

    }
  }



  toggleIsVisible(ev: Event, htmlId, pageLink: PageLink) {
    if (!this._isVisible[htmlId] || this._isVisible[htmlId] === false) {
      this._isVisible[htmlId] = true;
      this.setSubResultsForPageId(pageLink);
    } else {
      this._isVisible[htmlId] = false;
    }
    ev.stopPropagation();

  }
  isVisible(id) {
    return (this._isVisible[id] || this._isVisible[id] === true);
  }

  search() {

    if (!this.navigation.searchPageTerm || this.navigation.searchPageTerm.length < 1) return;

    this.dataService.getAkasForSearch(this.navigation.searchPageTerm)
      // .pipe(
      //   tap(result=>console.log(result))
      // )
      .pipe(
        catchError(() => {
          console.log('error searching aka on page load')
          return of([]);
        })
      )


      .subscribe(
        (result: PageLink[]) => {
          //console.log(result);
          this.navigation.searchPageResults = result;
          // this.pageLinkResults$.next(result);
        });
  }

  // getSearchLinksIn(){
  //   this.dataService
  //   .getLinksInForPageIds(['1','2','3'])
  //   .subscribe(res=>console.log(res));
  // }
  // searchTermTypeAheadFormatter = (result: any) => result.value;
  // searchTermTypeAhead() {
  //   // (text$: Observable<string>) =>
  //     of(this.searchTerm).pipe(
  //       filter(term=>term.length>1),
  //       debounceTime(300),
  //       distinctUntilChanged(),
  //       tap(() => this.searchingTermTypeAhead = true),
  //       switchMap(term =>
  //         this.dataService.getAkasForSearch(term).pipe(
  //           //map((t:any)=>t.value),
  //           tap((results) =>  {
  //             this.searchTermTypeAheadFailed = false;
  //           }),
  //           catchError(() => {
  //             this.searchTermTypeAheadFailed = true;
  //             return of([]);
  //           }))
  //       ),
  //       tap(() => this.searchingTermTypeAhead = false)
  //     ).subscribe(result => {
  //       this.navigation.searchPageResults = result;
  //     });
  //   }


  getFilteredTerms() {
    var filters = this.filterTerms
      .concat(this.filterTerm)
      .map(term => term.toLowerCase())
      .filter(term => term.trim().length > 0);

    return from(this.navigation.searchPageResults)
      .pipe(
        filter((resultItem: any) => {
          return this.isEvery(resultItem, filters,
            (a, b) => a.value.toLowerCase().includes(b)
          )
          // var ok = true;
          // for (let term of filters){
          //   if (!resultItem.value.toLowerCase().includes(term)) {
          //      var ok = false;
          //      break;
          //   }
          // }
          // return ok;


          // _.every(filters, (term) => {
          //   resultItem.value.toLowerCase().includes(term)  //search(new RegExp(term, 'i')) > -1;
          // })
        }),
        // tap(result=>console.log(result)),
        defaultIfEmpty({}),

    )

  }

  isEvery(operand: any, items: any[], test: (a: any, b: any) => boolean) {
    var ok = true;
    for (let item of items) {
      if (!test(operand, item)) {
        var ok = false;
        break;
      }
    }
    return ok;
  }
  searchTermSelected($event) {
    this.navigation.searchPageTerm = this.searchTerm;
    this.router.navigateByUrl('search/' + this.searchTerm);
    this.searchTerm = "";
  }

  filterTermSelected($event) {
    this.filterTerms.push(this.filterTerm);
    this.filterTerm = "";
  }

  setSubResultsForPageId(pageLink: PageLink) {
    //console.log('getting sub results for PageId: ', pageLink);
    if (!pageLink || !pageLink.pid) return;
    if (!this.linksForPageIds) this.linksForPageIds = {};
    if (!this.linksForPageIds[pageLink.pid]) {
      this.pageLinkResults$.next([pageLink]);
    }

    //return [];
    // if (pageId && pageId>=0 &&
    //    this.linksForPageIds && this.linksForPageIds[pageId]){
    //    return this.linksForPageIds[pageId];
    //    } else return [];
  }

  searchConceptRun = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(400), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickSearchConcept$.pipe(filter(() => !this.searchConceptInput.isPopupOpen()));
    const inputFocus$ = this.focusSearchConcept$;
    const getConcepts$ = this.dataService.getConcepts();


    return getConcepts$.pipe(
      switchMap(() => merge(debouncedText$, inputFocus$, clicksWithClosedPopup$)),
      // tap((term)=>console.log(term)),
      map(term => (term === '' ? this.dataService.conceptList
        : this.dataService.conceptList.filter(v =>
          v.title.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
          v.abbrev.toLowerCase().indexOf(term.toLowerCase()) > -1
        )
      ).slice(0, 10)),
      //tap((terms)=>console.log(terms))
    );
  }
  searchConceptFormatter = (x: { title: string }) => x.title;


  searchConceptSelected(ev, input) {
    ev.preventDefault();
    this.searchConcept = null;
    this.concepts.push(ev.item);
    this.conceptPageResults$.next(this.concepts);
    input.value = '';

  }



  ngOnInit() {

       this.navigation.searchTerm = "";
        this.navigation.searchResults = [];

     this.route.params.forEach((params: Params) => {
      this.navigation.searchPageTerm = params['term'];



      if (this.isKeyView) { this.navigation.setBreadcrumbsToSearch(); }

      if (!this.navigation.searchPageTerm || this.navigation.searchPageTerm.length < 1) {
        //clear out the search lists
 
        this.navigation.searchPageTerm = "";
        this.navigation.searchPageResults = [];
      }

      this.search();
      // this.getSearchLinksIn();
    });

  }

  ngOnDestroy() {
    this.searchTermKeyUp$.unsubscribe();
    this.filterTermKeyUp$.unsubscribe();
    this.pageLinkResults$.unsubscribe();
  }

}
