import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { AnnotationService } from '../../services/annotation.service';
import { AuthenticateService } from '../../services/authenticate.service';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'fpn-chapter-annotation-view',
  templateUrl: './chapter-annotation-view.component.html',
  styleUrls: ['./chapter-annotation-view.component.scss']
})
export class ChapterAnnotationViewComponent implements OnInit {


  constructor(private annotationService: AnnotationService, public authenticateService: AuthenticateService, private globals: GlobalService) { }

  ngOnInit() {
  }
  login() {
    this.authenticateService.startSigninMainWindow();
  }
}

