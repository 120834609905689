import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fpn-drag-content-editable-text-well',
  templateUrl: './drag-content-editable-text-well.component.html',
  styleUrls: ['./drag-content-editable-text-well.component.scss']
})
export class DragContentEditableTextWellComponent implements OnInit {

  constructor() { }

  @Input()
  welldata;

  @Output()
  ondeleteline: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  onmovelineup: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  onmovelinedown: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit() {
  //  console.log('editable',this.welldata);
  }

  deleteLine(idx=-1) {
    if (idx <0) { //go up one level
      this.ondeleteline.emit(-1);
    }
    else {
      this.welldata.children.splice(idx, 1);       
    }

    
    //    if (confirm('Are you sure you want to delete this line and any of its children?')) {
    //   //console.log("delete is not yet implemented");
    //   // this.ondeleteline.emit('');
    //   // Delete it!

    // } else {
    //   // Do nothing!
    // }
  }

  moveLine(fromIndex, toIndex) {
    if (toIndex<0 || (toIndex>this.welldata.children.length-1)) return false;
     var element = this.welldata.children[fromIndex];
     this.welldata.children.splice(fromIndex, 1);
     this.welldata.children.splice(toIndex, 0, element);
     return true;
 }
  moveLineUp(idx=-1){
    if (idx <0) { //go up one level
      this.onmovelineup.emit(-1);
    }
    else {
      this.moveLine(idx,idx-1);       
    }

  }
  moveLineDown(idx=-1){
    if (idx <0) { //go up one level
      this.onmovelinedown.emit(-1);
    }
    else {
      this.moveLine(idx,idx+1);
    }

  }
}
