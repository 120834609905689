import { Component, OnInit } from '@angular/core';
import {NavigationService} from '../../services/navigation.service';


@Component({
  selector: 'app-chapter-view',
  templateUrl: './chapter-view.component.html',
  styleUrls: ['./chapter-view.component.css']
})
export class ChapterViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
