import { Injectable } from '@angular/core';
import {IPage, IPageBlockCollection, IPageBlockItem} from  '../utility/interfaces';

@Injectable()
export class SemanticService {

  constructor() { }



     public processPageJson(pageJson: IPage) {
            if (!pageJson || !pageJson.PageBlockData) {
                console.log('Invalid page json - no pageBlockData to analyze.');
                return null;
            }
            var pageBlocks = [];
            pageJson.PageBlockData.forEach((pageBlock,blockN) => {
                var pageBlock = this.processPageBlock(pageBlock, blockN);
                pageBlock['PageLineage']=pageJson.Lineage;
                pageBlock.Blocks.forEach((block) => {
                  block['PageLineage']=pageJson.Lineage;  
                  block['PageBlockHeading']=pageBlock.Heading;
                });
                pageBlocks.push(pageBlock);
            });

            
            pageJson.SelectedAka = pageJson.Akas[0].Title;

            pageJson.PageBlockData = pageBlocks;

            return pageJson;
        }//ProcessPageJson
        
        
        public processPageBlock(pageBlock: IPageBlockCollection, blockN:number) {
            var that = this;
            //console.log(pageBlock.Heading);
            
            var $el = $('<div></div>');
            $el.html(pageBlock.Content);

            //this next part works, but when inserted into the page, angular removes input boxes 
            //looking for another way to show this
            //var nestedNumbered = that.addNestedNumbering($el.children());
            //var withCB = that.addInputBoxes('li', nestedNumbered);
           // pageBlock.ContentMod = "<ol>" + withCB.html() + "</ol>";

            var li = $el.children().children();

            pageBlock.Selected = false;
            pageBlock.MapId = blockN.toString();
            var pageBlockArray = [];
            $.each(li, function (i, value) {
                var mapId = blockN + '.' + i;
                pageBlockArray.push(that.processPageBlockItem($(value),mapId));

            });
            pageBlock.Blocks = pageBlockArray;
            return pageBlock;
        }

        //This has a side effect related to UI - it changes link hrefs to have a suffix of /workshop
        //Future refactoring plan - this should be passed as a parameter as url suffix or maybe a callback function
        //that modifies the href
        public processPageBlockItem($li: JQuery, mapId:string) {
            var that = this;
            var $liClone = $li.clone(true);
            $li.children("ol").empty();
            var text = $li.text();
            
            $li.children("a.LinkPage").each((i, e) => {
                var href = e.getAttribute("href");
                //first char is # - get rid of this - we'll do this later
               // href = href.slice(1); 
                e.setAttribute("href", href + "/workshop");
            });

            var html = $li.html().replace("<ol></ol>","");
            
            var pageBlockItem :IPageBlockItem = {
                Text: text,
                Html: html,
                MapId: mapId,
                Selected:false,
                Links: this.extractLinks($li.children("a.LinkPage")),
                Children: this.extractListChildren($liClone.children("ol"), mapId)
            };

 
            return pageBlockItem;
        }



        public extractLinks(links) {
            var linkArr = [];
            links.each(function (index, value) {
                var $value = $(value);
                var link = {
                    Href: $value.attr("href"),
                    Text: $value.text(),
                    Cui: $value.attr("data-cui")
                };
                linkArr.push(link);
            });
            return linkArr;
        }

        public extractListChildren($ol:JQuery, mapId:string) {
            var childItems = [];
            var that = this;
           if ($ol) {
               $.each($ol.children(), function (i, value) {
                    childItems.push(that.processPageBlockItem($(value),mapId + '.' + i));
                });
            }

           return childItems;
        }






}
