import { Component, OnInit } from '@angular/core';
import { AnnotationService } from "../../services/annotation.service";
import { AuthenticateService } from "../../services/authenticate.service";
import { GlobalService } from "../../services/global.service";

@Component({
  selector: 'fpn-library-annotation-view',
  templateUrl: './library-annotation-view.component.html',
  styleUrls: ['./library-annotation-view.component.scss']
})
export class LibraryAnnotationViewComponent implements OnInit {

   constructor(private annotationService: AnnotationService, public authenticateService: AuthenticateService, private globals: GlobalService) { }

  ngOnInit() {
  }

  login() {
    this.authenticateService.startSigninMainWindow();
  }


}
