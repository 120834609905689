import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SemanticJsonTreeService } from "../../services/semantic-json-tree.service";
import { Observable } from "rxjs";
import * as _ from "lodash";
import { IPage } from "../../utility/interfaces"
import { ComposeDocumaticService } from "../../services/compose-documatic.service";
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'fpn-documatic',
  templateUrl: './documatic.component.html',
  styleUrls: ['./documatic.component.scss']
})
export class DocumaticComponent implements OnInit {

  lists = [];

  dlgCodeTitle = '';
  dlgCodeContent = '';

  innerHeight : number;
  sourcePages=[];

  private currentlyDraggedItem = undefined;

  @ViewChild('destSlideList') destSlideList: ElementRef;
  
  constructor(
    private semanticJsonTreeService: SemanticJsonTreeService,
    private composeDocumaticService: ComposeDocumaticService,
    private navigation: NavigationService,

  ) { }

  ngOnInit() {
    this.navigation.setBreadcrumbsToLibraryWorkshopDocumatic();

    this.innerHeight = window.innerHeight;

    this.semanticJsonTreeService
      .generateJsonTreeFromSelectedPages()
      .subscribe(p => {
        //console.log(p);
        this.sourcePages = _.values(p);
      });
  }

  dragstart(channel) {
    //console.log("Dragging ", channel);
    //console.log(window);
    this.currentlyDraggedItem = channel;
  }

  dragend(channel: any) {
    //console.log("Drag End ", channel);
    this.currentlyDraggedItem = undefined;
    return false;
  }

deleteList(idx){
  this.lists.splice(idx, 1);
}

generateListsAndRun() {
  var OpenWindow = window.open("", "newwin",
    "fullscreen=yes,menubar=yes,toolbar=yes,scrollbars=yes,top=0,left=0,width=4000,height=4000");

  var slideHtml = this.composeDocumaticService.generateBootstrapListsFromListSorter(this.lists);

  this.composeDocumaticService.openListViewer(slideHtml, OpenWindow);

}

generateEpicListsAndSave() {

  var listText = this.composeDocumaticService.generateEpicListsFromListSorter(this.lists);

  //console.log(slideViewer);

  this.dlgCodeContent = listText;
  this.dlgCodeTitle=`Select and copy (ctrl-c) the code below and paste (ctrl-v) into Epic SmartPhrase or SmartText (Tm)`;

  //this is a hack - using jquery to open the modal
  //however, I could not get this to work with viewchild
  //node that the id #dlgCode is the wrapping div within the fpn-dialog-code component
 $('#dlgCode').modal('show')

}



deleteAllLists() {
  if (confirm('Are you sure you want to delete ALL slides?')) {
    this.lists = [];
    // Delete it!
  } else {
    // Do nothing!
  }
}
moveList(fromIndex, toIndex) {
  if (toIndex<0 || (toIndex>this.lists.length-1)) return false;
   var element = this.lists[fromIndex];
   this.lists.splice(fromIndex, 1);
   this.lists.splice(toIndex, 0, element);
   return true;
}

 moveListUp(idx){
   this.moveList(idx,idx-1);
 }
 moveListDown(idx){
   this.moveList(idx,idx+1);
 }
 insertList(idx) {
   var list = this.newSlide();
  if (Array.isArray(list)) {
    //this.slides.splice(idx, 0, slide);
    this.lists.splice.apply(this.lists, [idx, 0].concat(list));
  }
  else {

    this.lists.splice(idx, 0, list);
  }

}
newSlide(item = undefined) {
  if (!item) item = this.currentlyDraggedItem;
  //console.log(this.currentlyDraggedItem);
  if (item) {
    let slideData = this.mapDraggedItemToSlideData(item);
    if (Array.isArray(slideData)) { //slide array is passed back
      return slideData;
    }
    var slideJson = {};

    // debugger; 
    if (slideData.children && slideData.children.length > 0) {
      slideJson = { title: slideData.title, children: slideData.children };
    }
    else if (slideData.imageLink) {
      slideJson = { title: '', children: [slideData] }
    }
    else {
      slideJson = { title: '', children: [slideData] };
    }

    //console.log(slideJson);
    return slideJson;
  }
  else {
    return {}
  }
}
 
mapDraggedItemToSlideData(draggedItem) {
  if (draggedItem.ImageLink) {//image
    return {
      title: draggedItem.ImageLink.title,
      imageLink: draggedItem.ImageLink,
      children: draggedItem.Children.map(d => this.mapDraggedItemToSlideData(d))
    }
  }
  else if (draggedItem.Title) {//Page - children are at draggedItem.PageBlockData

    return this.generateSlidesForPage(draggedItem);
    // return {
    //   title: draggedItem.Title,
    //   imageLink: draggedItem.ImageLink,
    //   children: draggedItem.PageBlockData.map(d => this.mapDraggedItemToSlideData(d))
    // }
  }
  else if (draggedItem.Heading) {//PageBlock - children are at draggedItem.Blocks
    return this.generateSlidesForPageBlock(draggedItem);
    // return {
    //   title: draggedItem.Heading,
    //   imageLink: draggedItem.ImageLink,
    //   children: draggedItem.Blocks.map(d => this.mapDraggedItemToSlideData(d))
    // }
  }
  else if (draggedItem.Text) { //Blocks - children are at draggedItem.Children
    return {
      title: draggedItem.Text,
      imageLink: draggedItem.ImageLink,
      children: draggedItem.Children.map(d => this.mapDraggedItemToSlideData(d))
    }
  }
  else { //did not find the interface type
    return null;
  }
}

generateSlidesForPage(page) {
  var slides = [];
  if (!page.PageBlockData) return slides;

  slides.push({
    title: page.Title,
    imageLink: undefined,
    children: []
  });
  page.PageBlockData.forEach(pageBlock => {

    if (!pageBlock.Heading.match(/See Also/i)) { //don't include the See Also Section
      slides.push({
        title: pageBlock.Heading,
        imageLink: undefined,
        children: []
      });
      //this is a problem - the images do not load correctly if nested in page loop
      //but they load correctly when from pageBlock
      var slidesForBlock = this.generateSlidesForPageBlock(_.cloneDeep(pageBlock));
      slides = slides.concat(slidesForBlock);
    }
  });
  //console.log('page-', slides);
  return slides;
}


generateSlidesForPageBlock(parent) {
  var slides = [];
  var looseItems = [];
  var children = [];
  var title = '';
  var isContinued = false;
  var isContinuedImages = false;
  var isPageBlock = false;
  var images = [];

  if (parent.Blocks) {//pageBlock
    children = parent.Blocks;
    title = parent.Heading;
    isPageBlock = true;
  } else if (parent.Children) {
    children = parent.Children;
    title = parent.Text;
  }
  //console.log(parent, ' - children: ', children);
  children.forEach(child => {
    if (child.ImageLink) {
      console.log('imageLink: ', child.ImageLink)
      images.push({
        title: child.ImageLink.title,
        imageLink: child.ImageLink,
        children: child.Children.map(d => this.mapDraggedItemToSlideData(d))
      });
      if (images.length > 1) {
        slides.push({ title: title, children: _.cloneDeep(images) });
        images = [];
        isContinuedImages = true;
      }

    } else if (child.Children && child.Children.length > 0) {
      var moreSlides = this.generateSlidesForPageBlock(child);
      slides = _.concat(slides, moreSlides);
      //slides.push(this.newSlide(child));
    } else {
      looseItems.push({
        title: child.Text,
        imageLink: child.ImageLink,
        children: child.Children.map(d => this.mapDraggedItemToSlideData(d))
      });
      if (looseItems.length > 7) {
        slides.push({ title: title, children: _.cloneDeep(looseItems) });
        looseItems = [];
        isContinued = true;
      }
    }
  });

  if (looseItems.length > 0) {
    slides.push({ title: title, children: _.cloneDeep(looseItems) });
  }

  if (images.length > 0) {
    slides.push({ title: title, children: _.cloneDeep(images) });
  }

  return slides;
}

appendList(e) {
  // console.log("appending slide", this.currentlyDraggedItem);

  var slide = this.newSlide();
  if (Array.isArray(slide)) {
    this.lists = this.lists.concat(slide);
  }
  else {
    this.lists.push(slide)

  }
  //console.log(this.lists);

  setTimeout(function (list) {
    list.scrollTop = list.scrollHeight;
  }, 100, this.destSlideList.nativeElement);

}

appendToList(idx) {
  var slide = this.lists[idx];
  if (this.currentlyDraggedItem) {
    let slideData = this.mapDraggedItemToSlideData(this.currentlyDraggedItem);
    slide.children.push(slideData);
  }
}




}
