import { Injectable, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//import { Http, Headers, RequestOptions, Response } from '@angular/http'; //todo: replace Http with HttpClient
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { GlobalService } from './global.service';
import { Observable } from 'rxjs';
import { UserManager, Log, MetadataService, User, OidcClientSettings } from 'oidc-client';
import { environment } from '../../environments/environment';
import { THROW_IF_NOT_FOUND } from '@angular/core/src/di/injector';
import * as _ from "lodash";

//Thanks to Justin Murphy for his Angular2OidcClient example on github
//https://github.com/jmurphzyo/Angular2OidcClient


interface requestOptions {
  headers: HttpHeaders
};

@Injectable()
export class AuthenticateService {




  public mgr: UserManager = null;
  userLoadededEvent: EventEmitter<User> = new EventEmitter<User>();


  currentUser: User = null;

  currentUserName: String = null;

  loggedIn: boolean = false;

  //authHeaders: Headers;
  //authHeaders:HttpHeaders;
  authHeaders: {};


  constructor(private http: HttpClient, private globals: GlobalService, private router: Router
    //, private oidcSettings:OidcClientSettings
  ) {
    //Log.logger = console;
    this.mgr = new UserManager(globals.IdentityConfig);
    this.setupUserManagerEvents(this.mgr, globals);

    this.currentUserName = "";
    this.loggedIn = false;

    this.getUser();


  }

  setupUserManagerEvents(mgr: UserManager, globals: GlobalService) {

    var that = this;
    mgr.events.addAccessTokenExpiring(function () {
      if (!globals.isProduction) console.log("token expiring");

      //https://stackoverflow.com/questions/48778603/silent-refresh-not-working-with-oidc-client-in-angular-5#answer-48801015
      // this.mgr.signinSilent({scope:oidcSettings.scope, response_type: oidcSettings.response_type})
      //   .then((user:Oidc.User)=>{this.setUser(user); console.log("success: setUser via SignInSilent"); })
      //   .catch((error:Error)=> { //work around to handle iframe window timeout errors on browsers
      //     this.userManager.getUser().then((user:Oidc.User) =>{this.setUser(user); console.log("Error: tried to setUser via SignInSilent");});
      //   });
    });

    mgr.events.addAccessTokenExpired(function () {
      if (!globals.isProduction) console.log("token expired...");
      that.removeUser();
      that.startSigninMainWindow();
    });
    mgr.events.addUserSignedOut(function () {
      //console.log("user Signed out...");
      that.removeUser();

    });
    mgr.events.addUserLoaded((data) => {
      if (!globals.isProduction) console.log("user loaded...");
      that.getUser();
      if (data.state && data.state.windowHref) {
        //unfortunately this will require a full reload of the site, and re-login by using window.location.href
        window.location.href = data.state.windowHref;
      }

    });
    mgr.events.addUserUnloaded((data) => {
      if (!globals.isProduction) console.log("user unloaded...");

      //this avoids page change, but prevents other logout
      // if (data.state && data.state.windowHref){
      //   //unfortunately this will require a full reload of the site, and re-login by using window.location.href
      //   window.location.href = data.state.windowHref;
      //  }


      // this.removeUser();

    });

    mgr.events.addSilentRenewError(function () {
      if (!globals.isProduction) console.log("silent renew error...");
      that.removeUser();
    });


    // this.mgr.events.addUserUnloaded((e) => {
    //   if (!environment.production) {
    //     console.log("user unloaded");
    //   }
    //   this.loggedIn = false;
    // });
  }


  clearState() {
    this.mgr.clearStaleState().then(function () {
      // console.log("clearState success");
    }).catch(function (e) {
      console.log("clearState error", e.message);
    });
  }


  setUser(user: User) {
    if (user) {
      //todo: need to define this.currentUsername (should be in claims)
      if (!this.globals.isProduction) console.log("setting user ", user);

      this.loggedIn = true;
      this.currentUser = user;
      this.currentUserName = user.profile.name;
      this._setAuthHeaders(user);
      this.userLoadededEvent.emit(user);
    }
    else {
      if (!environment.production) {
        console.log("error getting user from authenticate service in development");
        // this.loggedIn = true;
        //this.currentUserName = "scottjmoses@msn.com"; 
      }
      this.loggedIn = false;
    }
  }

  isUserInRole(...role: string[]) {
    if (!this.loggedIn || !this.currentUser || !this.currentUser.profile || !this.currentUser.profile.role) return false;

    //in some cases role is a string and in others, role is a string array
    var userRole = this.currentUser.profile.role;
    var roles: string[];
    if (!(userRole instanceof Array)) {
      roles = [userRole.toLowerCase()];
    } else {
      roles = userRole.map(s => s.toLowerCase());
    }
    var found = false;

    for (var i = 0; i < role.length; i++) {
      found = (roles.indexOf(role[i]) > -1);
      if (found) break;
    }
    return found;
  }


  getUser() {
    //console.log("getting user");
    var that = this;
    this.mgr.getUser()
      .then((user) => {
        that.setUser(user);
      })
      .catch((err) => {
        console.log(err);
        that.loggedIn = false;
      });



    // this.mgr.getUser().then((user) => {
    //   console.log("got user", user);
    //       this.loggedIn = true;
    //       this.currentUser = user;
    //        this.userLoadededEvent.emit(user);
    // }).catch(function (err) {
    //   console.log(err);
    // });


  }

  removeUser(mgr = this.mgr) {
    var that = this;
    mgr.removeUser().then(() => {
      if (that) {
        that.loggedIn = false;
        that.currentUser = null;
        that.currentUserName = null;
        that.userLoadededEvent.emit(null);

      }
      // console.log("user removed");
    }).catch(function (err) {
      console.log(err);
    });
  }

  startSigninMainWindow() {
    var that = this;

    var windowHref = window.location.href;
    var routerHref = this.router.url;

    //console.log("current window.location.href",windowHref);
    //console.log("current this.router.url",routerHref);
    this.mgr.signinRedirect({ data: { routerHref: routerHref, windowHref: windowHref } }).then(function () {
      // console.log("signinRedirect complete");
    }).catch(function (err) {
      console.log("startSignInMainWindow", err);
    });
  }

  endSigninMainWindow() {
    var that = this;
    this.mgr.signinRedirectCallback().then(function (user) {
      //  console.log("signed in", user);
    }).catch(function (err) {
      console.log(err);
    });
  }

  startSignoutMainWindow() {
    var removeUser = this.removeUser;
    var router = this.router;
    var mgr = this.mgr;

    var windowHref = window.location.href;
    var routerHref = this.router.url;

    this.mgr.signoutRedirect({ data: { routerHref: routerHref, windowHref: windowHref } }).then(function (resp) {
      removeUser(mgr);

      // setTimeout(5000, () => {
      //   console.log("testing to see if fired...");

      // })
    }).catch(function (err) {
      console.log(err);
    });
  };

  endSignoutMainWindow() {
    var that = this;
    this.mgr.signoutRedirectCallback().then(function (resp) {
      // console.log("signed out", resp);
      that.router.navigateByUrl('/library');

    }).catch(function (err) {
      console.log(err);
    });
  };

  /**
     * Example of how you can make auth request using angulars http methods.
     * @param options if options are not supplied the default content type is application/json
     */
  AuthGet(url: string, options?: any): Observable<any> {

    options = this._setRequestOptions();
    return this.http.get(url, options); //this returns actual data object - not wrapped on json property as in the past



    // if (options) {
    //   options = this._setRequestOptions(options);
    // }
    // else {
    //   options = this._setRequestOptions();
    // }

    //console.log(options);

    // const httpOptionsTest = {
    //   headers: new HttpHeaders({
    //     'Content-Type':  'application/json',
    //     'Accept':  'application/json',
    //     'Authorization': this.currentUser.token_type + " " + this.currentUser.access_token
    //   })
    // };
    //    return this.httpDeprecated.get(url, options); //this returns actual data object - not wrapped on json property as in the past


  }
  /**
   * @param options if options are not supplied the default content type is application/json
   */
  AuthPut(url: string, data: any, options?: any): Observable<any> {

    //no longer need to stringify data
    //let body = JSON.stringify(data);
    let body = data;

    if (options) {
      options = this._setRequestOptions(options);
    }
    else {
      options = this._setRequestOptions();
    }
    return this.http.put(url, body, options);
  }
  /**
   * @param options if options are not supplied the default content type is application/json
   */
  AuthDelete(url: string, options?: any): Observable<any> {

    if (options) {
      options = this._setRequestOptions(options);
    }
    else {
      options = this._setRequestOptions();
    }
    return this.http.delete(url, options);
  }
  /**
   * @param options if options are not supplied the default content type is application/json
   */
  AuthPost(url: string, data: any, options?: any): Observable<any> {
    //no longer need to stringify data
    //let body = JSON.stringify(data);
    let body = data;
    if (options) {
      options = this._setRequestOptions(options);
    }
    else {
      options = this._setRequestOptions();
    }
    return this.http.post(url, body, options);
  }


  private _setAuthHeaders(user: any) {
    //this.authHeaders = new HttpHeaders();
    // this.authHeaders = new Headers();

    // this.authHeaders.append('Authorization', user.token_type + " " + user.access_token);
    // this.authHeaders.append('Content-Type', 'application/json');
    // this.authHeaders.append('Accept', 'application/json');

    this.authHeaders = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': this.currentUser.token_type + " " + this.currentUser.access_token
    };

  }

  private _setRequestOptions(options?: any) { //requestOptions) {

    //added on 12/24/2017 - redundant - but the only way I can deal with silent refresh updating the access token
    // this._setAuthHeaders(this.currentUser);
    //modified 7/23/2018 after angular removed RequestOptions support

    // if (options) {
    //   options.headers.append(this.authHeaders.keys[0], this.authHeaders.values[0]);
    // }
    // else {
    //   options =  {
    //     headers: new HttpHeaders(this.authHeaders)
    //   };
    //   //options = new RequestOptions({ headers: this.authHeaders }); //, body: "" 
    // }

    //options is in form of {headers:{}}
    if (options) {
      var headers = options.headers || {};
      _.assign(headers, this.authHeaders);

      options.headers = new HttpHeaders(headers);
    }
    else {
      options = {
        headers: new HttpHeaders(this.authHeaders)
      };
    }



    // if (options && options.headers) {
    //   this.authHeaders.keys().forEach(key => {
    //      options.headers.append(key, this.authHeaders[key]);
    //   });
    // }
    // else {
    //   options = {headers : this.authHeaders }
    //   //options = new RequestOptions({ headers: this.authHeaders }); //, body: "" 
    // }

    return options;
  }



  // updateUser(){
  //   debugger;
  // this.mgr.getUser().then(
  //     (user)=>{this.currentUser = user;},
  //     (error)=>{console.log(error); return null}
  //     );
  // }

  // login(){
  //   this.mgr.signinRedirect().then(()=>{this.updateUser();});

  // }

  // logout(){
  //   this.mgr.signoutRedirect();
  // }







}
