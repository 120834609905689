import { Injectable } from '@angular/core';
import { StringService } from "./string.service";

export interface IBreadcrumb {
  title: string,
  abbrev: string,
  icon?: string,
  href: string
}

@Injectable()
export class NavigationService {

  public currentBook = null;
  public currentChapter = null;
  public currentPage = null;
  public currentImageId = null;
  public breadcrumbs: IBreadcrumb[];

  public searchInput: string;

  public searchTerm = "";
  public searchResults = [];

  public searchPageTerm = "";
  public searchPageResults = [];

  clearSearch() {
    this.searchTerm = "";
    this.searchResults = [];
  }

  private EmptyBreadcrumb: IBreadcrumb = {
    title: '',
    abbrev: '',
    icon: '',
    href: ''
  }



  get LibraryBreadcrumb(): IBreadcrumb {
    return {
      title: 'FPNotebook Library',
      abbrev: '',
      icon: 'fa fa-home',
      href: '/library'
    }
  }
  get SearchBreadcrumb(): IBreadcrumb {
    return {
      title: 'Search',
      abbrev: 'Search',
      icon: 'fa fa-search',
      href: `/search/${this.searchPageTerm}`
    }
  }
  get SearchAdvancedBreadcrumb(): IBreadcrumb {
    return {
      title: 'Search Advanced',
      abbrev: 'Search Advanced',
      icon: 'fa fa-search',
      href: `/searchAdvanced/${this.searchPageTerm}`
    }
  }

  get BookBreadcrumb(): IBreadcrumb {
    if (!this.currentBook) return this.EmptyBreadcrumb;
    return {
      title: this.currentBook.name + ' Book',
      abbrev: this.currentBook.path,
      href: `/library/${this.currentBook.path}`
    }
  }

  get LibraryWorkshopBreadcrumb(): IBreadcrumb {
     return {
      title: 'Workshop',
      abbrev: 'Workshop',
      icon: 'fa fa-flask',
      href: `/library/workshop`
    }
  }
  
  get LibraryWorkshopSlidePresenterBreadcrumb(): IBreadcrumb {
    return {
     title: 'SlidePresenter',
     abbrev: 'Present',
     icon: '',
     href: `/library/workshop/slidepresenter`
   }
 }

 get LibraryWorkshopDiagrammerBreadcrumb(): IBreadcrumb {
  return {
   title: 'Diagrammer',
   abbrev: 'Diagrammer',
   icon: '',
   href: `/library/workshop/diagrammer`
 }
}

get LibraryWorkshopQuizBreadcrumb(): IBreadcrumb {
  return {
   title: 'Quiz',
   abbrev: 'Quiz',
   icon: '',
   href: `/library/workshop/quiz`
 }
}

get LibraryWorkshopDocumaticBreadcrumb(): IBreadcrumb {
  return {
   title: 'Documatic',
   abbrev: 'Documatic',
   icon: '',
   href: `/library/workshop/documatic`
 }
}

get LibraryWorkshopUmlsBreadcrumb(): IBreadcrumb {
  return {
   title: 'UMLS',
   abbrev: 'UMLS',
   icon: '',
   href: `/library/workshop/umls`
 }
}



  get BookWorkshopBreadcrumb(): IBreadcrumb {
    if (!this.currentBook) return this.EmptyBreadcrumb;
    return {
      title: this.currentBook.name + ' Book',
      abbrev: this.currentBook.path,
      icon: 'fa fa-flask',
      href: `/library/${this.currentBook.path}/workshop`
    }
  }
  get ChapterBreadcrumb(): IBreadcrumb {
    if (!this.currentBook || !this.currentChapter) return this.EmptyBreadcrumb;
    return {
      title: this.currentChapter.name + ' Chapter',
      abbrev: this.currentChapter.path,
      href: `/library/${this.currentBook.path}/${this.currentChapter.path}`
    }
  }
  get ChapterWorkshopBreadcrumb(): IBreadcrumb {
    if (!this.currentBook || !this.currentChapter) return this.EmptyBreadcrumb;
    return {
      title: this.currentChapter.name + ' Chapter',
      abbrev: this.currentChapter.path,
      icon: 'fa fa-flask',
      href: `/library/${this.currentBook.path}/${this.currentChapter.path}/workshop`
    }
  }
  get PageBreadcrumb(): IBreadcrumb {
    if (!this.currentPage) return this.EmptyBreadcrumb;
    return {
      title: this.currentPage.Title,
      abbrev: this.currentPage.Title,
      href: `/library/${this.currentPage.PageUrl}`
    }
  }
  get PageWorkshopBreadcrumb(): IBreadcrumb {
    if (!this.currentPage) return this.EmptyBreadcrumb;
    return {
      title: "Workshop",
      abbrev: "Workshop",
      icon: 'fa fa-flask',
      href: `/library/${this.currentPage.PageUrl}/workshop`
    }
  }

  get PageSlideshowBreadcrumb(): IBreadcrumb {
    if (!this.currentPage) return this.EmptyBreadcrumb;
    return {
      title: "Slideshow",
      abbrev: "Slideshow",
      href: `/library/${this.currentPage.PageUrl}/slideshow/${this.currentImageId}`
    }
  }
  get LibraryAnnotationBreadcrumb(): IBreadcrumb {
    return {
      title: 'Library Annotation',
      abbrev: 'Lib',
      icon:"fa fa-edit",
      href: `/library/annotation`
    }
  }

  get BookAnnotationBreadcrumb(): IBreadcrumb {
    if (!this.currentBook) return this.EmptyBreadcrumb;
    return {
      title: this.currentBook.name + ' Book',
      abbrev: this.currentBook.path,
      icon:"fa fa-edit",
      href: `/library/${this.currentBook.path}/annotation`
    }
  }

  get ChapterAnnotationBreadcrumb(): IBreadcrumb {
    if (!this.currentBook || !this.currentChapter) return this.EmptyBreadcrumb;
    return {
      title: this.currentChapter.name + ' Chapter',
      abbrev: this.currentChapter.path,
      icon:"fa fa-edit",
      href: `/library/${this.currentBook.path}/${this.currentChapter.path}/annotation`
    }
  }

  get PageAnnotationBreadcrumb(): IBreadcrumb {
    if (!this.currentPage) return this.EmptyBreadcrumb;
    return {
      title: "Annotation",
      abbrev: "Annotation",
      icon:"fa fa-edit",
      href: `/library/${this.currentPage.PageUrl}/annotation`
    }
  }

  get SourcePageAnnotationUrl(): string {
    if (!this.currentPage) {
      return this.stringService.concatWith("/",
      (this.currentBook) ? this.currentBook.path : "",
      (this.currentChapter) ? this.currentChapter.path : "",
      "");
    } else {
      return this.currentPage.PageUrl;
    };
  }






  constructor(private stringService: StringService) { }



  clearCurrentPageSlideshowDataAndBelow() {
    this.currentImageId = null;
  }
  clearCurrentPageDataAndBelow() {
    this.currentPage = null;
    this.clearCurrentPageSlideshowDataAndBelow();
  }
  clearCurrentChapterDataAndBelow() {
    this.currentChapter = null;
    this.clearCurrentPageDataAndBelow();
  }
  clearCurrentBookDataAndBelow() {
    this.currentBook = null;
    this.clearCurrentChapterDataAndBelow();
  }

  setBreadCrumbsInit() {
    this.clearSearch();
  }

  setBreadcrumbsToLibrary() {
    this.setBreadCrumbsInit();
    this.clearCurrentBookDataAndBelow();
    this.breadcrumbs = [
      this.LibraryBreadcrumb
    ];
  }
  setBreadcrumbsToSearch() {
    this.setBreadCrumbsInit();
    this.clearCurrentBookDataAndBelow();
    this.breadcrumbs = [
      this.SearchBreadcrumb
    ];
  }

  setBreadcrumbsToSearchAdvanced() {
    this.setBreadCrumbsInit();
    this.clearCurrentBookDataAndBelow();
    this.breadcrumbs = [
      this.SearchAdvancedBreadcrumb
    ];
  }

  setBreadcrumbsToBook() {
    this.setBreadCrumbsInit();
    this.clearCurrentChapterDataAndBelow();
    this.breadcrumbs = [
      this.LibraryBreadcrumb,
      this.BookBreadcrumb
    ];
  }

  

  setBreadcrumbsToChapter() {
    this.setBreadCrumbsInit();
    this.clearCurrentPageDataAndBelow();
    this.breadcrumbs = [
      this.LibraryBreadcrumb,
      this.BookBreadcrumb,
      this.ChapterBreadcrumb
    ];
  }

  


  setBreadcrumbsToPage() {
    this.setBreadCrumbsInit();
    this.clearCurrentPageSlideshowDataAndBelow();
    this.breadcrumbs = [
      this.LibraryBreadcrumb,
      this.BookBreadcrumb,
      this.ChapterBreadcrumb,
      this.PageBreadcrumb
    ];
  }

  setBreadcrumbsToPageSlideshow() {
    this.setBreadCrumbsInit();
    this.breadcrumbs = [
      this.LibraryBreadcrumb,
      this.BookBreadcrumb,
      this.ChapterBreadcrumb,
      this.PageBreadcrumb,
      this.PageSlideshowBreadcrumb
    ];
  }

  setBreadcrumbsToLibraryAnnotation() {
    this.setBreadCrumbsInit();
    this.clearCurrentBookDataAndBelow();
    this.breadcrumbs = [
      this.LibraryBreadcrumb,
      this.LibraryAnnotationBreadcrumb
    ];
  }


  setBreadcrumbsToBookAnnotation() {
    this.setBreadCrumbsInit();
    this.clearCurrentChapterDataAndBelow();
    this.breadcrumbs = [
      this.LibraryBreadcrumb,
      this.LibraryAnnotationBreadcrumb,
      this.BookBreadcrumb,
      this.BookAnnotationBreadcrumb
    ];
  }

  setBreadcrumbsToChapterAnnotation() {
    this.setBreadCrumbsInit();
    this.clearCurrentPageDataAndBelow();
    this.breadcrumbs = [
      this.LibraryBreadcrumb,
      this.LibraryAnnotationBreadcrumb,
      this.BookAnnotationBreadcrumb,
      this.ChapterBreadcrumb,
      this.ChapterAnnotationBreadcrumb
    ];
  }

  setBreadcrumbsToPageAnnotation() {
    this.setBreadCrumbsInit();
    this.breadcrumbs = [
      this.LibraryBreadcrumb,
      this.LibraryAnnotationBreadcrumb,
      this.BookAnnotationBreadcrumb,
      this.ChapterAnnotationBreadcrumb,
      this.PageBreadcrumb,
      this.PageAnnotationBreadcrumb
    ];
  }

  setBreadcrumbsToLibraryWorkshop() {
    this.setBreadCrumbsInit();
    this.breadcrumbs = [
      this.LibraryBreadcrumb,
      this.LibraryWorkshopBreadcrumb
    ];
  }

  setBreadcrumbsToLibraryWorkshopSlidePresenter() {
    this.setBreadCrumbsInit();
    this.breadcrumbs = [
      this.LibraryBreadcrumb,
      this.LibraryWorkshopBreadcrumb,
      this.LibraryWorkshopSlidePresenterBreadcrumb
    ];
  }

  setBreadcrumbsToLibraryWorkshopDiagrammer() {
    this.setBreadCrumbsInit();
    this.breadcrumbs = [
      this.LibraryBreadcrumb,
      this.LibraryWorkshopBreadcrumb,
      this.LibraryWorkshopDiagrammerBreadcrumb
    ];
  }

setBreadcrumbsToLibraryWorkshopQuiz() {
    this.setBreadCrumbsInit();
    this.breadcrumbs = [
      this.LibraryBreadcrumb,
      this.LibraryWorkshopBreadcrumb,
      this.LibraryWorkshopQuizBreadcrumb
    ];
  }

  setBreadcrumbsToLibraryWorkshopDocumatic() {
    this.setBreadCrumbsInit();
    this.breadcrumbs = [
      this.LibraryBreadcrumb,
      this.LibraryWorkshopBreadcrumb,
      this.LibraryWorkshopDocumaticBreadcrumb
    ];
  }

  setBreadcrumbsToLibraryWorkshopUmls() {
    this.setBreadCrumbsInit();
    this.breadcrumbs = [
      this.LibraryBreadcrumb,
      this.LibraryWorkshopBreadcrumb,
      this.LibraryWorkshopUmlsBreadcrumb
    ];
  }


  setBreadcrumbsToBookWorkshop() {
    this.setBreadCrumbsInit();
    this.breadcrumbs = [
      this.LibraryBreadcrumb,
      this.BookBreadcrumb,
      this.BookWorkshopBreadcrumb
    ];
  }


  setBreadcrumbsToChapterWorkshop() {
    this.setBreadCrumbsInit();
    this.breadcrumbs = [
      this.LibraryBreadcrumb,
      this.BookWorkshopBreadcrumb,
      this.ChapterBreadcrumb,
      this.ChapterWorkshopBreadcrumb
    ];
  }


  setBreadcrumbsToPageWorkshop() {
    this.setBreadCrumbsInit();
    this.breadcrumbs = [
      this.LibraryBreadcrumb,
      this.BookWorkshopBreadcrumb,
      this.ChapterWorkshopBreadcrumb,
      this.PageBreadcrumb,
      this.PageWorkshopBreadcrumb
    ];
  }




}
