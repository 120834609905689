import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Triple } from "../../models/umls-tree.interface";

@Component({
  selector: 'fpn-umls-triple',
  templateUrl: './umls-triple.component.html',
  styleUrls: ['./umls-triple.component.scss']
})
export class UmlsTripleComponent implements OnInit {

  @Output() onAddObjectToTriple: EventEmitter<Triple> = new EventEmitter<Triple>();
  @Output() onDeleteObjectFromTriple: EventEmitter<object> = new EventEmitter<object>();
  @Output() onDeleteTriple: EventEmitter<number> = new EventEmitter<number>();
  
  @Output() onAddDroppedObjectToTriple: EventEmitter<Triple> = new EventEmitter<Triple>();

  @Input() triple;

  constructor() { }

  ngOnInit() {
  }

  addObjectToTriple(){
    this.onAddObjectToTriple.emit();
  }

  dragover(channel: any) {
    //console.log("Drag Over");
    return false;
  }

  appendNewTripleObjectWithDroppedContent(){
    this.onAddDroppedObjectToTriple.emit();
  }
  deleteTriple(){
    this.onDeleteTriple.emit();
  }

  deleteObject(idxObj, triple){
    this.onDeleteObjectFromTriple.emit({idxObj,triple});
  }

}
