import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { SelectionService } from "./selection.service";
import { DataService } from "./data.service";

@Injectable()
export class ComposeQuizService {

  constructor(private selectionService: SelectionService, private dataService: DataService) { }

  openHtmlInWindow(html, OpenWindow) {
    //debugger;
    OpenWindow.document.write(this.generateFullHtmlPage(html));

    OpenWindow.document.close()

    OpenWindow.focus();

    self.name = "main"
  }

  generateStyle() {
    return ``;
  }

  generateFullHtmlPage(html) {
    var text = "";
    text += "<!DOCTYPE html><html><head><meta http-equiv='content-type' content='text/html;charset=UTF-8' />";
    text += "<TITLE>FPnotebook QuizViewer</TITLE>";
    text +=" <base href='/'>";
    text += "<meta name='viewport' content='width=device-width, initial-scale=1'>";

    text += '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">';

    // text += "<style>ol  {list-style-type: upper-roman;}ol ol {list-style-type: upper-alpha;}ol ol ol {list-style-type: decimal;}ol ol ol ol {list-style-type: lower-alpha;}ol ol ol ol ol {list-style-type: lower-roman;}</style>";
    text += `<style>${this.generateStyle()}</style>`;
    text += "<BODY>";
    text += "<fpn-root></fpn-root>";
    text += "<script>var quizData=" + html + "</script>";
    text+=`
<script type="text/javascript" src="https://fpnotebook.com/DrBits/quizViewer/inline.bundle.js"></script>
<script type="text/javascript" src="https://fpnotebook.com/DrBits/quizViewer/polyfills.bundle.js"></script>
<script type="text/javascript" src="https://fpnotebook.com/DrBits/quizViewer/scripts.bundle.js"></script>
<script type="text/javascript" src="https://fpnotebook.com/DrBits/quizViewer/styles.bundle.js"></script>
<script type="text/javascript" src="https://fpnotebook.com/DrBits/quizViewer/vendor.bundle.js"></script>
<script type="text/javascript" src="https://fpnotebook.com/DrBits/quizViewer/main.bundle.js"></script>
    `;
    text += "</BODY>";
    text += "</HTML>";
    return text;
  }


  replaceSlideshowLinks(pageBlockContent, imageLinks) {
    var rgx = /<a href='.*?\/slideshow\/(\d*)'>.*?<\/a>/mi;
    return pageBlockContent.replace(rgx,
      (_, p1) => {
        var n = Number(p1) - 1;
        var link = imageLinks[n];
        return this.generateImageThumbnail(link);
      }
    )
  }
  generateBlocksFromFullPage(pageData) {
    var blocks = [];
    pageData.PageBlockData.forEach((pageBlock, idx) => {
      var pageBlockContentMod = this.replaceSlideshowLinks(pageBlock.Content, pageData.ImageLinks)
      blocks.push({
        pageTitle: pageData.Title,
        pageLineage: pageData.Lineage,
        heading: pageBlock.Heading,
        subHeading: pageBlock.SubHeading,
        content: pageBlockContentMod,
        book: pageData.Book,
        chapter: pageData.Chapter,
        pageUrl: pageData.PageUrl,
        lineage: `${pageData.Lineage}.${idx}`
      });
    });
    return blocks;
  }

  private convertSlideShowLinks(sourceText, imageLinks: any) {

    var text = "";
    var slideshowImageMatch = sourceText.match(/\/slideshow\/(\d*)'>/im);
    if (slideshowImageMatch && slideshowImageMatch.length > 1) {
      var nSlide = Number(slideshowImageMatch[1]) - 1;
      text += this.generateImageThumbnail(imageLinks[nSlide]);
    }
    else {
      text += sourceText;
    }
    return text;
  }

  generateOutlineLevel(level, imageLinks) {
    var text = "";
    if (level && level.Html) {
      text += "<li>";
      text += this.convertSlideShowLinks(level.Html, imageLinks);

      if (level.Children) {
        text += "<ol>" + level.Children.filter(b => b.Selected).map(child => this.generateOutlineLevel(child, imageLinks)).reduce((acc, curr) => acc + curr, "") + "</ol>";
      }
      text += "</li>";
    }
    return text;
  }

  generateOnlySelectedBlocksFromPage(pageData, fullPageData) {
    var blocks = [];

    for (let blockKey in pageData.selectedBlocks) {
      let selectedBlock = pageData.selectedBlocks[blockKey];
      // console.log(selectedBlock);
      //text += "<li>" + selectedBlock.Heading;

      if (selectedBlock) {
        var subText = "";
        selectedBlock.Blocks.filter(b => (b.Selected === true))
          .forEach(childA => {
            subText += this.generateOutlineLevel(childA, fullPageData.ImageLinks);
          });


        if (subText && subText.length > 0)
          blocks.push({ //note that these are different than the full page pageData
            pageTitle: pageData.title,
            pageLineage: pageData.lineage,
            heading: selectedBlock.Heading,
            subHeading: selectedBlock.SubHeading,
            content: subText,
            book: pageData.parsedLineage.book.name,
            chapter: pageData.parsedLineage.chapter.name,
            pageUrl: pageData.path,
            lineage: `${pageData.lineage}.${blockKey}`
          });

      }

      //text += "</li>";
    }

    return blocks;
  }


  generateImageThumbnail(link) {
    var text = "";
    text += `<a href="${link.url}" target="_blank"><img src="${link.url}" height="100px"></a>`;
    return text;
  }


  convertToQuestionBlocks(fullPageDict, linkRoot: string) {
    //console.log("fullpagedict",fullPageDict);
 
    var blocks = [];
    for (let key in this.selectionService.groupedSelectedPages) {
      let section = this.selectionService.groupedSelectedPages[key];
      let sectionName = section[0].parsedLineage.section.name;
      let bookName = section[0].parsedLineage.book.name;
      let chapterName = section[0].parsedLineage.chapter.name;
      //console.log("section", sectionName);
      var sectionHeader = `${bookName} > ${chapterName} > ${sectionName} Section`;
      section.forEach(page => {
        //var pageText = "";
        //pageText += this.generatePageHeader(page);
        if (page.allSelected) {
          //this involves downloading each of the pages
          //console.log(fullPageDict[page.lineage]);
          blocks = blocks.concat(this.generateBlocksFromFullPage(fullPageDict[page.lineage]));

        } else {
          console.log("only show selected data", page);
          blocks = blocks.concat(this.generateOnlySelectedBlocksFromPage(page, fullPageDict[page.lineage]));
        }
        //  sectionText += this.replaceHtmlLink(pageText,linkRoot) + "<div style='page-break-after: always;'></div>";
      });
      //returnText += '';//sectionText;
    }

    return blocks;
  }


  generateBlocksFromSelectedPages(linkRoot: string = "https://fpnotebook.com/drbits/fpn2017ng/") {
    var that = this;
    return new Observable(observer => {
      var pages = {};
      this.dataService.loadFullPages(this.selectionService.selectedPages).subscribe(
        p => {
          // console.log('writing Lineage: ',p);
          pages[p["Lineage"]] = p;


        },
        (err) => observer.error("loadFullpages error: " + err),
        () => {
          //On complete
          // console.log(pages);
          var blocks = that.convertToQuestionBlocks(pages, linkRoot);
          observer.next(blocks);
          return observer.complete();
        }
      )
    });


  }


}
