import { Component, OnInit } from '@angular/core';
import { AnnotationService } from '../../services/annotation.service';
import { AuthenticateService } from '../../services/authenticate.service';
import { GlobalService } from '../../services/global.service';
import { User } from "oidc-client";
import { UserInfo } from '../../models/user-info.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logged-in',
  templateUrl: './logged-in.component.html',
  styleUrls: ['./logged-in.component.scss']
})
export class LoggedInComponent implements OnInit {
  // _user: User;
  // loadedUserSub: any;
  userProfileEditUrl: string;
  providers = ['twitter', 'github', 'aad'];



  redirect: string = "";

  constructor(private annotationService: AnnotationService, private globals: GlobalService,
    private router: Router) { }
  // public authenticateService: AuthenticateService

  async ngOnInit() {
    this.redirect = window.location.pathname;
    this.globals.userInfo = await this.getUserInfo();

    // this.userProfileEditUrl = this.globals.userProfilEditUrl;
    // this.authenticateService.getUser();

    //  this.loadedUserSub = this.authenticateService.userLoadededEvent
    //     .subscribe(user => {
    //       this._user = user;
    //     });
  }

  async getUserInfo() {
    try {
      const response = await fetch('/.auth/me');
      const payload = await response.json();
      const { clientPrincipal } = payload;
      return clientPrincipal;
    } catch (error) {
      console.error('No profile could be found');
      return undefined;
    }
  }


  // login() {
  //   this.authenticateService.startSigninMainWindow();
  // }

  // async logout() {
  //   ///.auth/logout?post_logout_redirect_uri={{redirect}}
  //   //await this.authenticateService.startSignoutMainWindow();
  //   this.globals.userInfo = null;
  //   this.router.navigate(['.auth/logout?post_logout_redirect_uri=' + this.redirect]);
  // }

}
