import { Injectable } from '@angular/core';

@Injectable()
export class StringService {

  constructor() { }

  public concatWith(delim: string, ...words: string[]) {
    return words
      .filter((word) => word && word.trim().length > 0)
      .reduce((acc, word) => {
        var newVal = "";
        if (acc.length > 0) { newVal += delim }
        newVal += word;
        return acc + newVal;
      },"");

  }

}
