import { Component, OnInit, Output, Input, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Annotation, Priority, Category } from '../../models/annotation'
import { FormControl } from "@angular/forms/src/forms";

@Component({
  selector: 'app-annotation-edit',
  templateUrl: './annotation-edit.component.html',
  styleUrls: ['./annotation-edit.component.less']
})
export class AnnotationEditComponent implements OnInit {
  @Output() saveAnnotationEvent: EventEmitter<Annotation> = new EventEmitter<Annotation>();
  @Output() cancelAnnotationEvent: EventEmitter<Annotation> = new EventEmitter<Annotation>();

  @ViewChild('annotationForm') annotationForm : ElementRef;
  //@Input() annotation: Annotation = null;
  @Input() model: Annotation = null;
  @Input() isAdd: boolean = false;
  //public model = new Annotation("", "", "",true, Priority.Minor, Category.Info);

  public priorityKeys = Object.keys(Priority).filter(Number);
  public priorityEnum: typeof Priority = Priority;

  public categoryKeys = Object.keys(Category).filter(Number);
  public categoryEnum: typeof Category = Category;
  constructor() {
  }

  ngOnInit() {
    // if (this.annotation !== null) {
    //   this.model = this.annotation;
    // }
  }
  clearModel() {
    //this.annotationForm.reset();

    //we clear the model on the parent instead of this method

    // this.model.priority = Priority.Minor;
    // this.model.category = Category.Info;

    //this.model = new Annotation("","","",true,Priority.Minor,Category.Info);
  }
  cancelAnnotation() {
    this.cancelAnnotationEvent.emit(this.model);
  }
  saveAnnotation() {
    this.saveAnnotationEvent.emit(this.model);
   // this.annotationForm.markAsPristine({});//does not work

    // if (this.isAdd) {
    //   this.clearModel();
      
    // }

  }

}
