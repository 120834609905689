import { Component, OnInit } from '@angular/core';
import {NavigationService} from '../../services/navigation.service';
import {AnnotationService} from '../../services/annotation.service';
import {AuthenticateService} from '../../services/authenticate.service';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-page-annotation-view',
  templateUrl: './page-annotation-view.component.html',
  styleUrls: ['./page-annotation-view.component.css']
})
export class PageAnnotationViewComponent implements OnInit {
 // _user: any;
 // loadedUserSub: any;
  constructor(private annotationService:AnnotationService, public authenticateService:AuthenticateService, private globals:GlobalService) { }

  ngOnInit() {
    // this.loadedUserSub = this.authenticateService.userLoadededEvent
    // .subscribe(user => {
    //   this._user = user;
    // });
  }
  login(){
    this.authenticateService.startSigninMainWindow();
  }
}
