import { Injectable } from '@angular/core';
import { IPageBlockCollection, IPageBlockItem, IPage } from "../utility/interfaces";
import { Observable } from "rxjs";
import { SelectionService } from "./selection.service";
import { DataService } from "./data.service";
import { SemanticService } from './semantic.service';


@Injectable()
export class SemanticJsonTreeService {

  constructor(
    private selectionService: SelectionService,
    private semanticService:SemanticService,
    private dataService : DataService
  ) { }


  generateJsonTreeFromSelectedPages(linkRoot:string = "https://fpnotebook.com/drbits/fpn2017ng/") {
    var that = this;
    return new Observable(observer=>{
     var pages = {};
     this.dataService.loadFullPages(this.selectionService.selectedPages).subscribe(
       p=>{
        // console.log('writing Lineage: ',p);
         pages[p["Lineage"]]=p
        },
       (err)=>observer.error("loadFullpages error: " + err),
       ()=>{
         //On complete
        // console.log(pages);
         var returnText = that.convertToJsonTree(pages, linkRoot);
         observer.next(returnText);
         return observer.complete();
      }
     )
    });
  }


  convertToJsonTree(fullPageDict, linkRoot:string){
    //console.log("fullpagedict",fullPageDict);
    var returnJson = {};
    //var returnText = this.generateCoverPage("Custom Excerpt",this.generateTOC());
    for (let key in this.selectionService.groupedSelectedPages) {
      let section = this.selectionService.groupedSelectedPages[key];
      let sectionName = section[0].parsedLineage.section.name;
      let bookName = section[0].parsedLineage.book.name;
      let chapterName = section[0].parsedLineage.chapter.name;
      //console.log("section", sectionName);
      //var sectionText = this.generateSectionHeader(`${bookName} > ${chapterName} > ${sectionName} Section`);
      section.forEach(page => {
        //var pageText = "";
        //pageText += this.generatePageHeader(page);
        if (page.allSelected) {
          //this involves downloading each of the pages
          //console.log(page);
          //this will need to be map to id
           returnJson[page.lineage]= this.semanticService.processPageJson(fullPageDict[page.lineage]);

         } else {
          //console.log("only show selected data", page);
          returnJson[page.lineage]=page;  //this.generateOnlySelectedBlocksFromPage(page);
        }
        //sectionText += this.replaceHtmlLink(pageText,linkRoot) + "<div style='page-break-after: always;'></div>";
      });
      //returnText += sectionText;
    }

    return returnJson;
  }

  // generateFullPage(pageData) {
  //   var returnJson = [];

  //   pageData.PageBlockData.forEach((pageBlock, blockN) => {
  //     returnJson.push(this.processPageBlock(pageBlock,blockN));
  //     //text += "<li>" + pageBlock.Heading + pageBlock.Content + "</li>";
  //   });
  //   //text += "</ol>";
  //  // debugger;    
  //   return returnJson;
  // }



  


//   public processPageJson(pageJson: IPage) {
//     if (!pageJson || !pageJson.PageBlockData) {
//         console.log('Invalid page json - no pageBlockData to analyze.');
//         return null;
//     }
//     var pageBlocks = [];
//     pageJson.PageBlockData.forEach((pageBlock,blockN) => {
//         pageBlocks.push(this.processPageBlock(pageBlock, blockN));
//     });

    
//     pageJson.SelectedAka = pageJson.Akas[0].Title;

//     pageJson.PageBlockData = pageBlocks;

//     return pageJson;
// }//ProcessPageJson


//   public processPageBlock(pageBlock: IPageBlockCollection, blockN: number) {
//     var that = this;
//     //console.log(pageBlock.Heading);

//     var $el = $('<div></div>');
//     $el.html(pageBlock.Content);

//     //this next part works, but when inserted into the page, angular removes input boxes 
//     //looking for another way to show this
//     //var nestedNumbered = that.addNestedNumbering($el.children());
//     //var withCB = that.addInputBoxes('li', nestedNumbered);
//     // pageBlock.ContentMod = "<ol>" + withCB.html() + "</ol>";

//     var li = $el.children().children();

//     pageBlock.Selected = false;
//     pageBlock.MapId = blockN.toString();
//     var pageBlockArray = [];
//     $.each(li, function (i, value) {
//       var mapId = blockN + '.' + i;
//       pageBlockArray.push(that.processPageBlockItem($(value), mapId));

//     });
//     pageBlock.Blocks = pageBlockArray;
//     return pageBlock;
//   }

//   //This has a side effect related to UI - it changes link hrefs to have a suffix of /workshop
//   //Future refactoring plan - this should be passed as a parameter as url suffix or maybe a callback function
//   //that modifies the href
//   public processPageBlockItem($li: JQuery, mapId: string) {
//     var that = this;
//     var $liClone = $li.clone(true);
//     $li.children("ol").empty();
//     var text = $li.text();

//     $li.children("a.LinkPage").each((i, e) => {
//       var href = e.getAttribute("href");
//       //first char is # - get rid of this - we'll do this later
//       // href = href.slice(1); 
//       e.setAttribute("href", href + "/workshop");
//     });

//     var html = $li.html().replace("<ol></ol>", "");

//     var pageBlockItem: IPageBlockItem = {
//       Text: text,
//       Html: html,
//       MapId: mapId,
//       Selected: false,
//       Links: this.extractLinks($li.children("a.LinkPage")),
//       Children: this.extractListChildren($liClone.children("ol"), mapId)
//     };


//     return pageBlockItem;
//   }



//   public extractLinks(links) {
//     var linkArr = [];
//     links.each(function (index, value) {
//       var $value = $(value);
//       var link = {
//         Href: $value.attr("href"),
//         Text: $value.text(),
//         Cui: $value.attr("data-cui")
//       };
//       linkArr.push(link);
//     });
//     return linkArr;
//   }

//   public extractListChildren($ol: JQuery, mapId: string) {
//     var childItems = [];
//     var that = this;
//     if ($ol) {
//       $.each($ol.children(), function (i, value) {
//         childItems.push(that.processPageBlockItem($(value), mapId + '.' + i));
//       });
//     }

//     return childItems;
//   }

}
