import { Component, OnInit, Input, Renderer2, ViewChild, ElementRef, ContentChildren, QueryList, Directive } from '@angular/core';
import { Router } from "@angular/router";

/**
 * From https://medium.com/@laco0416/forget-compile-in-angular-2-a2893d8291b1
 * 
 * @export
 * @class PageBlockHtmlComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-page-block-html',
  templateUrl: './page-block-html.component.html',
  styleUrls: ['./page-block-html.component.less']
})
export class PageBlockHtmlComponent implements OnInit {
 
  private clickListeners: Function[] = [];

  constructor(private router: Router,private el:ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
  }

ngAfterViewInit() { // for searching a components template
    const anchorNodes:NodeList = this.el.nativeElement.querySelectorAll('a[href]:not(.LinkRef)'); //or a.LinkPage

    const anchors:Node[] = Array.from(anchorNodes); //or Array.prototype.slice.call(anchorNodes);

    anchors.forEach(anchor => {
        let listener = this.renderer.listen(anchor,'click',e=>{
          e.preventDefault();
          let href = e.srcElement.getAttribute('href');
          //console.log(href);
          this.router.navigateByUrl(href);
        });
        this.clickListeners.push(listener);

        // anchor.addEventListener('click',e=>{
        //   e.preventDefault();
        //   let href = e.srcElement.getAttribute('href');
        //   this.router.navigateByUrl(href);
        // });
    });

 }

ngOnDestroy() {
    // Removes click listener 
    this.clickListeners.forEach(listener =>{
      listener();
    });
   
}



}
