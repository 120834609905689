import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fpn-voting-element',
  templateUrl: './voting-element.component.html',
  styleUrls: ['./voting-element.component.scss']
})
export class VotingElementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

@Input() votes : number;
@Input() userVote : number;
@Input() disable : boolean;

@Output() emitUpvote = new EventEmitter();
@Output() emitDownvote = new EventEmitter();

upVote(){
  if (!this.disable) this.emitUpvote.emit({});
}

downVote(){
  if (!this.disable) this.emitDownvote.emit({});
}


}
