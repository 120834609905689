import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ActivatedRoute, Params } from '@angular/router';
import { LodashHelper } from '../../utility/lodash-helper';
import { NavigationService } from '../../services/navigation.service';
import { SemanticService } from '../../services/semantic.service';
import { SelectionService } from '../../services/selection.service';
import { GlobalService } from '../../services/global.service';
@Component({
  selector: 'app-library-selector',
  templateUrl: './library-selector.component.html',
  styleUrls: ['./library-selector.component.scss']
})
export class LibrarySelectorComponent implements OnInit {
  @Input() isKeyView = false;
  public selectedBookAbbrev: string;
  public selectedChapterAbbrev: string;

  public selectedBook = null;
  public selectedChapter = null;
  public library = null;
  constructor(public dataService: DataService,
    public navigation: NavigationService,
    private route: ActivatedRoute,
    private semanticService: SemanticService,
    private selectionService: SelectionService,
    public globals:GlobalService
  ) { }


    changeAllCheckBoxes(isChecked:boolean){
      //let chapter of selectedBook.children
      this.selectedBook.children.forEach(chapter => {
        this.chapterOrSectionSelected(chapter,isChecked);
      });
    }

  chapterOrSectionSelected(chapter, isSelected:boolean = null) {
    if (!chapter) return;

    //default behavior is to invert the chapter Selection (no parameter passed)
    //However checkAllBoxes will also use this and send a checked or unchecked flag
    chapter.Selected = (isSelected===null) ? !chapter.Selected : isSelected;

    for (var i = 0; i < chapter.children.length; i++) {
      var line = chapter.children[i];
      line.Selected = chapter.Selected;
      this.checkChildren(line, line.Selected);
    }

    //dataService.setNSelectedPagesInChildren(lib.book);

    this.selectionService.setSelectedPages();

  }

  pageSelected(item) {
    if (!item) return;

    item.Selected = !item.Selected;

    this.checkChildren(item, item.Selected);
    //dataService.setNSelectedPagesInChildren(lib.book);
    this.selectionService.setSelectedPages();
  }


  checkChildren(line, isSelected) {
    if (!line.children || line.children.length < 1) {//page level (no children)
      this.selectionService.pageSelected(line, isSelected, '');
      //lib.setSelectedPages();
    }
    else {//chapter, book or section level
      for (var i = 0; i < line.children.length; i++) {
        var lineSub = line.children[i];
        lineSub.Selected = isSelected;
        this.checkChildren(lineSub, isSelected);
      }

    }
  }





  ngOnInit() {

    this.route.params.forEach((params: Params) => {
      this.selectedBookAbbrev = params['bookAbbrev'];
      this.selectedChapterAbbrev = params['chapterAbbrev'];
      if (!this.selectedBookAbbrev || this.selectedBookAbbrev.includes("workshop")) this.selectedBookAbbrev = null;
      if (!this.selectedChapterAbbrev || this.selectedChapterAbbrev.includes("workshop")) this.selectedChapterAbbrev = null;

      this.dataService.getLibraryTree()
        .subscribe(
        result => {
          this.library = result;
        },
        error => { console.log(error); }

        );


      if (this.selectedChapterAbbrev) {
        this.dataService.getChapterInTreeByAbbrev(this.selectedBookAbbrev, this.selectedChapterAbbrev)
          .subscribe(
          result => {
            this.selectedChapter = result;
            this.selectedBook = this.navigation.currentBook;
            if (this.isKeyView) this.navigation.setBreadcrumbsToChapterWorkshop();
          },
          error => { console.log(error); }

          );
      } else if (this.selectedBookAbbrev) {

        var matchBookAbbrevCaseInsensitive = LodashHelper.buildCaseInsensitiveMatcher('path', this.selectedBookAbbrev);
        this.dataService.getBookInTreeBy(matchBookAbbrevCaseInsensitive)
          .subscribe(
          result => {
            this.selectedBook = result;
            if (this.isKeyView) this.navigation.setBreadcrumbsToBookWorkshop();

          },
          error => { console.log(error); }

          );


      }


    });


  }

}
