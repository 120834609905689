import { Component, OnInit, Input } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { DataService } from '../../services/data.service';
import { ActivatedRoute, Params } from '@angular/router';
import { AnnotationService } from '../../services/annotation.service';
import { DataHelper } from '../../utility/data-helper';
import { AuthenticateService } from '../../services/authenticate.service';
import { AnnotationEditComponent } from '../annotation-edit/annotation-edit.component';
import { Annotation, Priority, Category } from "../../models/annotation";
import { Review } from "../../models/review";
import { AnnotationReviewStatus } from "../../models/annotationReviewStatus";
import { VotingElementComponent } from "../voting-element/voting-element.component";
import { LodashHelper } from "../../utility/lodash-helper";
import {toArray} from 'rxjs/operators';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-annotation',
  templateUrl: './annotation.component.html',
  styleUrls: ['./annotation.component.scss']
})


export class AnnotationComponent implements OnInit {
  @Input() isKeyView = false;
  public selectedBookAbbrev: string;
  public selectedChapterAbbrev: string;
  public selectedPageAbbrev: string;
  public library : any;
  public book: any;
  public chapter: any;

  public url: string;
  public page: any;
  public pagePath = "";
  // public currentUserName ="";
  public annotations: Annotation[];

  private _reviewActions = [
    { id: -1, title: "Rejected" },
    { id: 0, title: "Neutral" },
    { id: 1, title: "Recommended" },
    { id: 2, title: "To Use" },
    { id: 3, title: "Used" },
  ];

  get reviewActions() {
    var isAdmin = this.authenticateService.isUserInRole('admin');
    if (isAdmin)
      return this._reviewActions;
    else
      return this._reviewActions.slice(0, 3);
  }

  public newAnnotation = new Annotation("", "", "", false, Priority.Normal, Category.Info);
  // public collapseToggleStatus = {'collapseAnnotationAdd':false};
  // collapseToggle(id:string){
  //   this.collapseToggleStatus[id] = !(this.collapseToggleStatus[id]);
  //   console.log(id + ': ' + this.collapseToggleStatus[id]);
  // }

  constructor(private navigation: NavigationService, private dataService: DataService,
    private annotationService: AnnotationService, private route: ActivatedRoute,
    public authenticateService: AuthenticateService,
    public globals : GlobalService
  ) {

  }


  startPopover(e: MouseEvent) {
    var e$ = $(e.target);
    e$.popover({ container: 'body', placement: 'auto' });
    e$.popover('show');
  }
  endPopover(e: MouseEvent) {
    $(e.target).popover('hide');
  }


  addComment(annotation: Annotation, reviewAction, domId) {
    //non-angular process
    if (annotation.reviews == null || annotation.reviews == undefined) {
      annotation.reviews = [];
    }
    let review = new Review(annotation.id, annotation.currentComment, reviewAction);
   // console.log(review);
    review.user = { userName: this.authenticateService.currentUserName };
    this.annotationService.addReview(review)
      .subscribe(
      result => {
        annotation.currentUserReviewStatus = review.status;
        this.updateReviewStatusesForAnnotation(annotation, review.status);

        if (review && review.comment && review.comment.trim().length > 0)
          annotation.reviews.push(result);
      }
      );
    annotation.currentComment = "";
    let collapsePanel$ = $('#collapseReview-' + domId);
    collapsePanel$.collapse('hide');
  }

  deleteComment(review: Review) {
    //var isUserInRole = this.authenticateService.isUserInRole("editor","admin");
    this.annotationService.deleteReview(review)
      .subscribe(
      result => {
        var annotation: Annotation = this.annotations.find(a => a.id == review.annotationId);
        //remove the annotation from the annotation array (may be better to reload from repo)
        var index = annotation.reviews.findIndex(a => a.id == review.id);
        if (index > -1) {
          var deletedItem = annotation.reviews.splice(index, 1);
        }

      });

  }

  getTotalVotes(annotation: Annotation) {
    if (!annotation || !annotation.reviewStatuses || annotation.reviewStatuses.length < 1) return 0;
    return annotation.reviewStatuses.map(s => {
      if (s.status > 0) return 1.0;
      else if (s.status < 0) return -1.0;
      else return 0;
    }).reduce((a, b) => a + b, 0);
  }

  collapseItem(domId: string) {

    let collapsePanel$ = $('#' + domId);
    collapsePanel$.collapse('hide');

  }

  addAnnotation(annotation: Annotation, domId: string) {
    console.log(annotation);
    annotation.user = { userName: this.authenticateService.currentUserName };
    var that = this;

    annotation.sourceUrl = this.navigation.SourcePageAnnotationUrl;
    //annotation.sourceUrl = this.stringService.concatWith("/",this.selectedBookAbbrev,this.selectedChapterAbbrev,this.selectedPageAbbrev);
    //annotation.sourceUrl = `${this.selectedBookAbbrev}/${this.selectedChapterAbbrev}/${this.selectedPageAbbrev}`;
    this.annotationService.addAnnotation(annotation)
      .subscribe(
      result => {
        //since these objects have been merged into the first item of an array, unpack - we want the array inside the array
        //does not include the user profile, which we could add if we stored this on login
        //console.log(result);
        //console.log(that);
        that.annotations.push(result);
      }, error => {
        console.log(error);
      });
    this.newAnnotation = new Annotation("", "", "", true, Priority.Minor, Category.Info);
    this.collapseItem(domId);
  }

  deleteAnnotation(annotation: Annotation) {
    this.annotationService.deleteAnnotation(annotation)
      .subscribe(
      result => {
        //remove the annotation from the annotation array (may be better to reload from repo)
        var index = this.annotations.findIndex(a => a.id == annotation.id);
        if (index > -1) {
          var deletedItem = this.annotations.splice(index, 1);
        }
      });

  }
  getAnnotationById(id: number) {
    return this.annotations.find((a) => { return a.id == id });
  }
  replaceAnnotation(id: number, annotation: Annotation): boolean {
    var index = this.annotations.findIndex((a) => { return a.id == id });
    if (index > -1) {
      this.annotations[index] = annotation;
      return true;
    }
    return false;
  }

  cancelAnnotation(annotation: Annotation, domId: string) {
     this.collapseItem(domId);
  }

  updateAnnotation(annotation: Annotation, domId: string) {

    this.annotationService.updateAnnotation(annotation)
      .subscribe(
      result => {
        //we need to replace the updated annotation with the new one
        this.replaceAnnotation(annotation.id, result);


        // var toUpdate = this.getAnnotationById(annotation.id);
        // toUpdate = annotation;
        //since these objects have been merged into the first item of an array, unpack - we want the array inside the array
        //does not include the user profile, which we could add if we stored this on login
        //console.log(result);
        //this.annotations.push(result);
      });

    this.collapseItem(domId);
  }

  ngOnInit() {
    //this.currentUserName = this.authenticateService.currentUserName;
    //console.log(this.currentUserName);

  //  console.log(this.authenticateService.currentUser.profile);

    this.route.params.forEach((params: Params) => {
      this.selectedBookAbbrev = (params['bookAbbrev']) ? params['bookAbbrev'] : "";
      this.selectedChapterAbbrev = (params['chapterAbbrev'])?params['chapterAbbrev'] : "";
      this.selectedPageAbbrev = (params['pageAbbrev'])?params['pageAbbrev'] : "";

      //check to see if this is a page 
      if (this.selectedPageAbbrev && this.selectedPageAbbrev.trim().length > 0) { //page
        this.url = DataHelper.generateFilePath(this.selectedBookAbbrev, this.selectedChapterAbbrev, this.selectedPageAbbrev);

        this.pagePath = `\/library/${this.selectedBookAbbrev}/${this.selectedChapterAbbrev}/${this.selectedPageAbbrev}/Annotation/`;

        this.dataService.getPageByUrlAndUpdateBookAndChapter(this.selectedBookAbbrev, this.selectedChapterAbbrev, this.selectedPageAbbrev)
          .subscribe(
          result => {

            this.page = result;
            if (this.isKeyView) this.navigation.setBreadcrumbsToPageAnnotation();
          },
          error => { console.log(error); }
          );
      } else if (this.selectedChapterAbbrev && this.selectedChapterAbbrev.trim().length > 0) { //chapter
        this.dataService.getChapterInTreeByAbbrev(this.selectedBookAbbrev, this.selectedChapterAbbrev)
          .subscribe(
          result => {
            this.chapter = result;
            if (this.isKeyView) this.navigation.setBreadcrumbsToChapterAnnotation();
          },
          error => { console.log(error); }

          );
      } else if (this.selectedBookAbbrev && this.selectedBookAbbrev.trim().length > 0) { //book
        var matchBookAbbrevCaseInsensitive = LodashHelper.buildCaseInsensitiveMatcher('path', this.selectedBookAbbrev);
        this.dataService.getBookInTreeBy(matchBookAbbrevCaseInsensitive)
          .subscribe(
          result => {
            this.book = result;
            if (this.isKeyView) this.navigation.setBreadcrumbsToBookAnnotation();

          },
          error => { console.log(error); }

          );

      } else { //library
        this.dataService.getLibraryTree()
        .subscribe(
            result =>{
                this.library = result;
                if (this.isKeyView) this.navigation.setBreadcrumbsToLibraryAnnotation();
             },
             error=>{console.log(error); }

        );

      }


      //  this.dataService.getPageByUrlAndUpdateBookAndChapter(this.selectedBookAbbrev, this.selectedChapterAbbrev, this.selectedPageAbbrev)
      //   .subscribe(
      //   result => {

      //     this.page = result;
      //     if (this.isKeyView) this.navigation.setBreadcrumbsToPageAnnotation();

      //   });


      this.initAnnotationsForPage().subscribe(
        result => {
          //since these objects have been merged into the first item of an array, unpack - we want the array inside the array
          var annotations = (result.length > 0) ? result[0] : [];

          this.annotations = annotations.map((a) => {
            a.extracts = this.extractLinksFromText(a.noteContents);
            return a;
          });

         // console.log(this.annotations);

          // for (let annotation of this.annotations){
          //     annotation.comments = "";

          // }
        });

    });

  } //ngOnInit

  extractLinksFromText(text: string) {
    var reLink = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

    var links = text.match(reLink);
    var textWithoutLinks = text.replace(reLink, "");
    return { links: links, text: textWithoutLinks };
  }

  vote(annotation: Annotation, isUpvote: boolean) {

    var tempStatus = annotation.currentUserReviewStatus;
    if (tempStatus == null) tempStatus = 0;
    if (isUpvote) {
      if (tempStatus < 0) tempStatus = 0
      else if (tempStatus < 1) tempStatus = 1
    }
    else { //downvote
      if (tempStatus > 0) tempStatus = 0
      else if (tempStatus > -1) tempStatus = -1
    }


    this.annotationService
      .updateAnnotationReviewStatus(annotation.id, tempStatus)
      .subscribe(
      result => {
        //status was updated properly
        annotation.currentUserReviewStatus = tempStatus;

        //modify the reviewStatuses array as well for this user
        this.updateReviewStatusesForAnnotation(annotation, tempStatus);
      },
      error => { console.log(error); }
      );

  }
  updateReviewStatusesForAnnotation(annotation, status) {
    var userId = this.authenticateService.currentUser.profile.sub;
    var reviewStatusesForUser = annotation.reviewStatuses.filter(r => r.userId == userId);
    if (reviewStatusesForUser.length > 0) {
      reviewStatusesForUser.forEach(s => {
        s.status = status;
      });
    } else {
      var rs = new AnnotationReviewStatus(annotation.id, userId, status);
      if (!annotation.reviewStatuses) annotation.reviewStatuses = [];
      annotation.reviewStatuses.push(rs);
    }
  }


  initAnnotationsForPage() {
    return this.annotationService
      .getAnnotationsForPage(this.selectedBookAbbrev, this.selectedChapterAbbrev, this.selectedPageAbbrev)
      .pipe(toArray());

  }

  getAnnotationReviewStatusForUser(annotation) {
    return annotation.currentUserReviewStatus;

    // var userId = this.authenticateService.currentUser.profile.sub;
    // var reviewsStatusesForUser = annotation.reviewStatuses.filter(r => r.userId == userId);
    // var reviewsForUser = annotation.reviews.filter(r => r.user.id == userId);
    // if (reviewsForUser && reviewsForUser.length > 0) { //these are reviews added since initial page load
    //   var result = (reviewsForUser[reviewsForUser.length - 1]);
    //   if (result && result.status) return result.status;
    // }

    // if (reviewsStatusesForUser && reviewsStatusesForUser.length > 0) { //these were available at page load
    //   return reviewsStatusesForUser.status;

    // }
    // else //no status exists, return default (0=neutral)
    //   return 0;
  }



}
