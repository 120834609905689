import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';

import { AuthenticateService } from '../../services/authenticate.service';


@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.less']
})
export class UnauthorizedComponent implements OnInit {

  constructor(private authenticateService:AuthenticateService, private location:Location) { }

  authenticateButtonClicked  = false;

  @Input() info : string;

  ngOnInit() {
  }
  login() {
    this.authenticateButtonClicked = true;
    this.authenticateService.startSigninMainWindow();
  }
  goBack(){
    this.location.back();
  }
}
