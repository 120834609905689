import { Component, OnInit, Input } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { DataService } from '../../services/data.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SemanticService } from '../../services/semantic.service';
import { SelectionService } from '../../services/selection.service';
import { GlobalService } from "../../services/global.service";
import {ComposePdfService} from "../../services/compose-pdf.service";
import { ComposeSlidesService } from "../../services/compose-slides.service";

@Component({
  selector: 'app-workshop',
  templateUrl: './workshop.component.html',
  styleUrls: ['./workshop.component.scss']
})
export class WorkshopComponent implements OnInit {
  @Input() isKeyView = false;
  public selectedBookAbbrev: string;
  public selectedChapterAbbrev: string;
  public selectedPageAbbrev: string;
  public page: any;
  public selectedSubSetSectionExpanded: any = {};

  constructor(
    public navigation: NavigationService,
    private dataService: DataService,
    private route: ActivatedRoute,
    public selectionService: SelectionService,
    private global: GlobalService,
    private pdf : ComposePdfService,
    private router : Router,
    private slidesService : ComposeSlidesService
  ) { }



  hasSelectedContent(){
    return (this.selectionService.groupedSelectedPages && 
      Object.keys(this.selectionService.groupedSelectedPages).length>0);
  }
  openPdf() {
    var OpenWindow = window.open("", "newwin", "height=650, width=900,toolbar=no,scrollbars=" + scroll + ",menubar=no");
    
    var pdf$ = this.pdf.generateHtmlFromSelectedPages()
      .subscribe(htmlContent=>
        { 
          var htmlPage = this.pdf.generateHtmlPage(htmlContent);
          //console.log(htmlPage);
          return this.pdf.printPdf(htmlPage, OpenWindow)
        
        }
        ); 

  }

  openSlides(){
    //var OpenWindow = window.open("", "newwin", "height=650, width=900,toolbar=no,scrollbars=" + scroll + ",menubar=no");
    //var pdf$ = this.slidesService.generateHtmlFromSelectedPages();
  
    this.router.navigateByUrl('library/workshop/slidepresenter');

  }
  openDiagrammer(){
    this.router.navigateByUrl('library/workshop/diagrammer');
  }
  openQuiz(){
    this.router.navigateByUrl('library/workshop/quiz');  
  }

  openDocumatic(){
    this.router.navigateByUrl('library/workshop/documatic');  
  }
  buildOntology(){
    this.router.navigateByUrl('library/workshop/umls'); 
  }

  clearSelected(){
    this.selectionService.clearAllSelected()
  }

  ngOnInit() {
    this.route.params.forEach((params: Params) => {
      this.selectedBookAbbrev = params['bookAbbrev'];
      this.selectedChapterAbbrev = params['chapterAbbrev'];
      this.selectedPageAbbrev = params['pageAbbrev'];


      // this.dataService.getPageByUrlAndUpdateBookAndChapter(this.selectedBookAbbrev, this.selectedChapterAbbrev, this.selectedPageAbbrev)
      //   .subscribe(
      //   result => {

      //     this.page = result;
      //     if (this.isKeyView) this.navigation.setBreadcrumbsToPageWorkshop();

      //   });
    });
  }

}
