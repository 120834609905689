import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fpn-drag-content-text-well',
  templateUrl: './drag-content-text-well.component.html',
  styleUrls: ['./drag-content-text-well.component.scss']
})
export class DragContentTextWellComponent implements OnInit {

  @Input()
  welldata;
  
  @Input()
  imageLinks;
  constructor() { }

  ngOnInit() {
    this.setImageLink();
  }

  private setImageLink() {

   var slideshowImageMatch =  this.welldata.Html.match(/\/slideshow\/(\d*)">/im);
   if (slideshowImageMatch && slideshowImageMatch.length > 1) {
     var nSlide = Number(slideshowImageMatch[1]) - 1;
     this.welldata.ImageLink = this.imageLinks[nSlide];
     
   }
 
 }

  @Output()
  ondragstart : EventEmitter<Object> = new EventEmitter<Object>();

  @Output()
  ondragend : EventEmitter<Object> = new EventEmitter<Object>();

  dragstart(item){
    //console.log("at text well, deeply started dragging",item);
    this.ondragstart.emit(item);
    return false;
  }
  dragend(item){
    this.ondragend.emit(item);
  }


}
