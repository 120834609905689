import { Component, OnInit, Input, ViewChild, ElementRef, HostListener, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
//import * as JsPlumb from 'jsplumb';
//import { jsPlumb } from 'jsplumb';

declare var jsPlumb: any;

@Component({
  selector: 'fpn-diagrammer-dest-canvas',
  templateUrl: './diagrammer-dest-canvas.component.html',
  styleUrls: ['./diagrammer-dest-canvas.component.scss']
})
export class DiagrammerDestCanvasComponent implements OnInit, OnChanges {

  @Input()
  grid;

  @Input()
  currentlyDraggedItem;


  @Input()
  jsPlumbReset;


 
  ngOnChanges(changes: SimpleChanges): void {
    //console.log(changes['jsPlumbReset']);
    if (changes['jsPlumbReset']) {

      if (this.jsPlumbReset) {
        this.initGrid();
        this.jsPlumbInstance.reset();
      }

    }

  }

  get task(): string { return this._task; }

  private _task = '';

  @ViewChild('destDiagrammer') destDiagram: ElementRef;


  jsPlumbInstance;

  jsPlumbConnectorProp = {
    isSource: true,
    isTarget: true,
    connectorStyle: { stroke: "blue", strokeWidth: 2 },
    connectorHoverStyle: { stroke: 'red' },
    connector: ["Bezier", { curviness: 20 }, { cssClass: 'myConnector' }],
    connectorOverlays: [["Arrow", { location: 0.8 }]]
  };

  selectedConnector;

  selectedNode;
  constructor() { }

  initSelectedNode() {
    this.selectedNode = {
      id: null,
      caption: null,
      isActive: false
    };
  }


  activateNodeWithDroppedContent(node) {
    if (!node.isActive) this.activateNode(node);
    if (!this.currentlyDraggedItem) return;
    //console.log(this.currentlyDraggedItem);

    var text = this.currentlyDraggedItem.Text ||
      this.currentlyDraggedItem.Heading ||
      this.currentlyDraggedItem.Title ||
      "";

    if (this.currentlyDraggedItem.ImageLink &&
      this.currentlyDraggedItem.ImageLink.url &&
      this.currentlyDraggedItem.ImageLink.url.length > 0) {
      node.ImageLink = this.currentlyDraggedItem.ImageLink;
      var title = this.currentlyDraggedItem.ImageLink.title;
      node.caption = (title) ? title : text;
    } else {
      node.caption = text;
    }
  }
  dragover(text: string) {
    return false;
  }

  initGrid(n: number = 32) {
    // if (!this.grid) {
    //  this.grid = [];      
    // } else {
    // }
    //assumes this.grid is array = [] with input from parent
    //if this is reset, it breaks connection to parent
    this.grid.length = 0;
    for (let index = 0; index < n; index++) {
      var node = this.createNewNode(index, false);
      this.grid.push(node);
    }
  }


  addEndpointsToNode(node) {
     if (node.endpoints && node.endpoints.length > 0) return;
    var ep1 = this.jsPlumbInstance.addEndpoint(node.id, {
      paintStyle: { fill: "#66666666" },
      hoverPaintStyle: { fill: "#ff0000" },
      anchor: "Top"
    }, this.jsPlumbConnectorProp);
    var ep2 = this.jsPlumbInstance.addEndpoint(node.id, {
      paintStyle: { fill: "#66666666" },
      hoverPaintStyle: { fill: "#ff0000" },
      anchor: "Left"
    }, this.jsPlumbConnectorProp);
    var ep3 = this.jsPlumbInstance.addEndpoint(node.id, {
      paintStyle: { fill: "#66666666" },
      hoverPaintStyle: { fill: "#ff0000" },
      anchor: "Bottom"
    }, this.jsPlumbConnectorProp);
    var ep4 = this.jsPlumbInstance.addEndpoint(node.id, {
      paintStyle: { fill: "#66666666" },
      hoverPaintStyle: { fill: "#ff0000" },
      anchor: "Right"
    }, this.jsPlumbConnectorProp);

    //the endpoints are created as above
    //now we store the endpoints so we can remove them later
    node.endpoints = [ep1, ep2, ep3, ep4];
  }
  removeEndpointsFromNode(node) {
    node.endpoints.forEach(endpoint => {
      this.jsPlumbInstance.deleteEndpoint(endpoint);
    });
    node.endpoints.length = 0;
  }
  ngOnInit() {
    //this.initGrid();
    this.initGrid();

    this.initSelectedNode();

    // window.addEventListener('scroll', this.scroll, true); //third parameter
  }

  // ngOnDestroy() {
  //  // window.removeEventListener('scroll', this.scroll, true);
  // }

  // scroll(){
  //    if (this.jsPlumbInstance) {
  //        this.jsPlumbInstance.revalidate();
  //         this.jsPlumbInstance.repaintEverything();
  //     }
  // }
  // scroll = (): void => {
  //   // from https://stackoverflow.com/questions/44516017/how-to-handle-window-scroll-event-in-angular-4
  //   //handle your scroll here
  //   //notice the 'odd' function assignment to a class field
  //   //this is used to be able to remove the event listener
  //   console.log('scrolling and redrawing jsPlumb');
  //   this.jsPlumbInstance.revalidate();
  //   this.jsPlumbInstance.repaintEverything();
  // };

  // @HostListener('window:scroll', ['$event']) onScrollEvent($event){
  //   console.log($event);
  //   console.log("scrolling 2");
  //   this.jsPlumbInstance.revalidate();
  //   this.jsPlumbInstance.repaintEverything();
  // } 

  deselectCurrentConnector() {
    if (this.selectedConnector) {
      this.selectedConnector.setPaintStyle({ stroke: 'blue' });
    }
    this.selectedConnector = null;
  }

  selectNewConnector(conn) {
    this.initSelectedNode();
    if (!conn.caption) conn['caption'] = '';
    this.deselectCurrentConnector();
    conn.setPaintStyle({ stroke: 'red' });
    this.selectedConnector = conn;
  }

  ngAfterViewInit() {
    let that = this;

    // jsPlumb.ready(function() {
    //   jsPlumb.addEndpoint(me.destDiagram.nativeElement);      
    // }); 





    // jsPlumb.ready(function() {
    // jsPlumb.setContainer('destDiagrammer');

    this.jsPlumbInstance = jsPlumb.getInstance({ container: 'destDiagrammer' });

    //this.jsPlumbInstance.setContainer('destDiagrammer');
    this.jsPlumbInstance.bind('connection', (info, ev) => {
      //console.log(info, ev);
      //this.connections.push(this.createNewExtendedConnection(info.connection));
      info.connection.bind("click", function (conn) {
        //console.log("you clicked on ", conn);
        that.selectNewConnector(conn);
      });
      // this.jsPlumbInstance.recalculateOffsets(info.sourceId);
      // this.jsPlumbInstance.recalculateOffsets(info.targetId);
      //  this.jsPlumbInstance.repaint(info.sourceId);
      //  this.jsPlumbInstance.repaint(info.targetId);
    });

    // for (let idx = 0; idx < this.grid.length; idx++) {
    //   const element = this.grid[idx];
    //   this.addAnchorsToElement('node'+idx);
    // }


    // var end1 = this.jsPlumbInstance.addEndpoint('test1', { 
    //   anchors:["Right","Left","Top","Bottom"]
    // }, this.jsPlumbConnectorProp);  

    // var end2 = this.jsPlumbInstance.addEndpoint('test2', { 
    //   anchors:["Right","Left","Top","Bottom"]
    // }, this.jsPlumbConnectorProp);  

    // this.jsPlumbInstance.connect({ 
    //   source:end1,
    //   target:end2,
    //   connector: [ "Bezier", { curviness:175 } ],
    //   paintStyle:{ strokeWidth:25, stroke:'red' }
    // });  

    // this.jsPlumbInstance.makeSource("el1", {
    //   anchor:"Continuous",
    //   endpoint:["Rectangle", { width:40, height:20 }],
    //   maxConnections:3
    // });    

    // this.jsPlumbInstance.makeSource("el2", {
    //   anchor:"Continuous",
    //   endpoint:["Rectangle", { width:40, height:20 }],
    //   maxConnections:3
    // });    
    // jsPlumb.connect({ 
    //   source:'el1',
    //   target:'el2',
    //   connector: [ "Bezier", { curviness:175 } ],
    //   paintStyle:{ strokeWidth:25, stroke:'red' },
    //   newConnection:true 
    // });  

    // this.jsPlumbMain = jsPlumb.getInstance({
    //   container:'destDiagrammer'
    //   // Connector: [
    //   //   "Flowchart",
    //   //   { gap: 1, cornerRadius: 3, alwaysRespectStubs: false }
    //   // ],
    //   // DragOptions: { cursor: "move", zIndex: 2000 },
    //   // PaintStyle: { strokeStyle: "blue", strokeWidth: 4, lineWidth: 3 },
    //   // EndpointStyle: { radius: 1, fillStyle: "transparent" },
    //   // //Container: ''
    // });

    // jsPlumb.setContainer(me.testEl.nativeElement);
    // a.connect({ 
    //   source:"element4", 
    //   target:"element3", 
    //   scope:"someScope"   
    // });
    // });

  }

  selectNode(node) {
    if (!node.isActive) return;
    this.deselectCurrentConnector();
    this.selectedNode = node;
  }
  createNewNode(index: number, isActive: boolean = false) {
    return { caption: "", id: `node${index}`, isActive: isActive };
  }

  createNewExtendedConnection(connection) {
    return { caption: "", id: `${connection.id}`, isActive: true, conn: connection };
  }
  deactivateNode(node) {
    if (!this.selectedNode || !this.selectedNode.isActive) return;
    node.isActive = false;
    //this.jsPlumbInstance.detachAllConnections(node.id);
    this.removeEndpointsFromNode(node);
    if (node === this.selectedNode) this.initSelectedNode();
  }
  activateNode(node) {
    if (node.isActive) return;
    node.isActive = true;
    this.addEndpointsToNode(node);
  }
  // createNewElement(nodeId:number){
  //    return { caption : "", id:`node${nodeId}`};
  // }
  // addElement(nodeId:number){
  //   var newItem = this.createNewNode(nodeId);

  //   this.selectedItem = newItem;
  //   this.grid2[nodeId] = newItem;
  //   //this.jsPlumbInstance.repaintEverything();
  //  // this.jsPlumbInstance.recalculateOffsets(newItem.id);
  //  // this.jsPlumbInstance.repaint(newItem.id);
  //  // this.addAnchorsToElement(newItem.id);

  // }

}
