import { Injectable } from '@angular/core';
import { DataService } from "./data.service";
import { SelectionService } from "./selection.service";

@Injectable()
export class ComposeDocumaticService {

  constructor(private selectionService: SelectionService,
    private dataService: DataService) { }


    selectId = 0;


    openListViewer(html, OpenWindow) {

      var fullHtml = this.generateFullHtmlPage(html);
      OpenWindow.document.write(fullHtml);
   
      OpenWindow.document.close()
  
      OpenWindow.focus();
  
      self.name = "main"
    }
  
    generateStyle(){
      return `
      `;
    }

    generateFullHtmlPage(html) {
      var text = "";
      text += "<!DOCTYPE html><html><head><meta http-equiv='content-type' content='text/html;charset=UTF-8' />";
      text += "<TITLE>FPnotebook Documatic Preview</TITLE>";
      text += "<link rel='stylesheet' href=''>";
      text += '<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-beta.2/css/bootstrap.min.css" integrity="sha384-PsH8R72JQ3SOdhVi3uxftmaW6Vc51MKb0q5P2rRUpPvrszuE4W1povHYgTpBfshb" crossorigin="anonymous">';
  
      // text += "<style>ol  {list-style-type: upper-roman;}ol ol {list-style-type: upper-alpha;}ol ol ol {list-style-type: decimal;}ol ol ol ol {list-style-type: lower-alpha;}ol ol ol ol ol {list-style-type: lower-roman;}</style>";
      text += `<style>${this.generateStyle()}</style>`;
      text += "<BODY>";
  
      text += '<script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>';
      text += ' <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.3/umd/popper.min.js" integrity="sha384-vFJXuSJphROIrBnz7yo7oB41mKfc8JzQZiCq4NCceLEaO4IHwicKwpJf9c9IpFgh" crossorigin="anonymous"></script>';
      text += '<script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-beta.2/js/bootstrap.min.js" integrity="sha384-alpBpkh1PFOepccYVYDB4do5UnbKysX5WZXm3XxPqe5iKTfUKjNkCk9SaVuEZflJ" crossorigin="anonymous"></script>';
      text += html;
      text += "</BODY>";
      text += "</HTML>";
      return text;
    }


    generateBootstrapListTextLevel(title, children: any[]):string[] {
      if (!children || children.length < 1) return [];

      var completedLists = [];
      var lines = [];
      children.forEach(li => {
        if (!li.imageLink) {//ignore imageLinks
          lines.push(`<option value>${li.title}</option>`);
        }
        completedLists.concat(this.generateEpicListTextLevel(li.title,li.children));
      });
      this.selectId+=1;
      var text = `<div class='row mt-3'><div class='col-sm-6'><label for='select${this.selectId}'>${title}</label></div>
      <div class='col-sm-6'>
      <select id='${this.selectId}'>
      ${lines.join('')}
      </select></div></div>
      `;
      completedLists.push(text);
      return completedLists;
    }


    generateBootstrapListsFromListSorter(lists: any[]): any {
      var text = "<div class='container mt-5'>";
      //console.log(slides);
      lists.forEach(list => {
        text += this.generateBootstrapListTextLevel(list.title, list.children).join(`
        
        `);
      });
      text +="</div>";
      return text;
    }





    generateEpicListTextLevel(title, children: any[]):string[] {
      if (!children || children.length < 1) return [];

      var completedLists = [];
      var lines = [];
      children.forEach(li => {
        if (!li.imageLink) {//ignore imageLinks
          lines.push(`"${li.title}"`);
        }
        completedLists.concat(this.generateEpicListTextLevel(li.title,li.children));
      });

      var text = `<p>{${title}:[replaceWithListID]::
      ${lines.join(',')}
      }</p>`;
      completedLists.push(text);
      return completedLists;
    }


    generateEpicListsFromListSorter(lists: any[]): any {
      var text = "";
      //console.log(slides);
      lists.forEach(list => {
        text += (this.generateEpicListTextLevel(list.title, list.children)).join('');
      });
      return text;
    }

    

}
