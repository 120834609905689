import { Component, OnInit } from '@angular/core';
import { UserManager, Log, MetadataService, User} from 'oidc-client';
import {AuthenticateService} from '../../services/authenticate.service';
import {  Router } from '@angular/router';

/**
 * @deprecated - "best practice" is to do this with the auth.html freestanding (instead of routed)
 */
@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.less']
})
export class AuthCallbackComponent implements OnInit {

public isWaiting = true;
public errorMessage = "";
  constructor(private authenticateService:AuthenticateService, private router:Router) { }

  ngOnInit() {

            this.authenticateService.mgr.signinRedirectCallback().then(user => {
            if (user == null) {
                this.isWaiting = false;
                this.errorMessage = "No sign-in request pending.";
             }
            else {
              //user is now created
              //update authenticate service
              this.authenticateService.setUser(user);

              this.router.navigate(['library']);
                //window.location = "/";
            }
        })
        .catch(er => {
            this.isWaiting = false;
            this.errorMessage = er.message;
        });


  }





}
