import { Injectable } from '@angular/core';
import * as _ from "lodash";

@Injectable()
export class ComposeDiagramService {

  constructor() { }



  openHtmlInWindow(html, OpenWindow) {
    OpenWindow.document.write(this.generateFullHtmlPage(html));

    OpenWindow.document.close()

    OpenWindow.focus();

    self.name = "main"
  }

  generateStyle() {
    return `
    .card {
      margin:20px 30px;
      height: 200px;
      width: 200px;
      float: left;
    }
    
    .blank {
      margin: 20px 30px;
      height: 200px;
      width: 200px;
      float: left;
    }
    .blankConnCap{
      margin: 10px 30px;
      height: 50px;
      width: 200px;
      float: left;
    }
    .offsetconncap{
      margin: 10px 15px;
      height: 50px;
      width: 100px;
      float: left;
    }
    .alert {
      margin:10px 30px;
      height: 50px;
      width: 200px;
      float: left;
    }

    `;
    // .card img {
    //   height:100px;
    // }

  }
  generateFullHtmlPage(html) {
    var text = "";
    text += "<!DOCTYPE html><html><head><meta http-equiv='content-type' content='text/html;charset=UTF-8' />";
    text += "<TITLE>FPnotebook Diagrammer</TITLE>";
    text += "<link rel='stylesheet' href=''>";
    text += '<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-beta.2/css/bootstrap.min.css" integrity="sha384-PsH8R72JQ3SOdhVi3uxftmaW6Vc51MKb0q5P2rRUpPvrszuE4W1povHYgTpBfshb" crossorigin="anonymous">';
    text += "<link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/jsPlumb/2.7.3/css/jsplumbtoolkit-defaults.min.css'>";

    // text += "<style>ol  {list-style-type: upper-roman;}ol ol {list-style-type: upper-alpha;}ol ol ol {list-style-type: decimal;}ol ol ol ol {list-style-type: lower-alpha;}ol ol ol ol ol {list-style-type: lower-roman;}</style>";
    text += `<style>${this.generateStyle()}</style>`;
    text += "<BODY>";

    text += '<script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>';
    text += ' <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.3/umd/popper.min.js" integrity="sha384-vFJXuSJphROIrBnz7yo7oB41mKfc8JzQZiCq4NCceLEaO4IHwicKwpJf9c9IpFgh" crossorigin="anonymous"></script>';
    text += '<script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-beta.2/js/bootstrap.min.js" integrity="sha384-alpBpkh1PFOepccYVYDB4do5UnbKysX5WZXm3XxPqe5iKTfUKjNkCk9SaVuEZflJ" crossorigin="anonymous"></script>';
    text += "<script src='https://cdnjs.cloudflare.com/ajax/libs/jsPlumb/2.7.3/js/jsplumb.min.js'></script>";
   // text += `<script src="https://fpnotebook.com/_assets2016/js/html2canvas.min.js"></script>`;
    text += html;
    text += "</BODY>";
    text += "</HTML>";
    return text;
  }

  generateImageHtml(imageLink) {
    if (!imageLink || !imageLink.url) return '';
    var text = `
    <img class="card-img-top" src="${imageLink.url}">
    `
    return text;
  }
  generateHtmlNodeCard(node) {
    var image = this.generateImageHtml(node.ImageLink);
    var caption = `<h5 class="card-title">${node.caption}</h5>`;
    // var caption = (image.length>0) 
    //   ? `<h5 class="card-title">${node.caption}</h5>`
    //   :  `<p class="card-text">${node.caption}</p>`;
    var text = `
  <div id="${node.id}" class="card ${(image.length > 0) ? 'bg-dark text-white' : ''}">
    ${image}
    <div class="${(image.length > 0) ? 'card-img-overlay' : 'card-body'}"> 
        ${caption}
    </div>
  </div>`;
    return text;
  }

  generateHtmlConnectCaptionAlert(connection) {


    var text = `
    <div id="${connection.id}Caption" class="alert alert-secondary connCapp" role="alert">
      ${connection.caption}
    </div>`

    return text;
  }

  getNodeCoordinates(nodeName, colsPerRow = 4) {
    //assumes nodeId = 'nodeX' such as 'node57'
    //we want to get the number only and then assuming 4 cols/row generate coord
    var nodeRegex = /node(\d*)/gi;
    var match = nodeRegex.exec(nodeName);
    //we only care about first match
    if (match && match.length > 1) {
      //first and only capturing group is at position 1 
      var id = parseInt(match[1]);
      if (!isNaN(id)) {
        var x = id % colsPerRow;
        var y = Math.floor(id / colsPerRow);
        return { 'x': x, 'y': y };
      }
    }
    return null;
  }
  getCaptionLocation(connection) {
    connection.sourcePosition = this.getNodeCoordinates(connection.sourceId);
    connection.targetPosition = this.getNodeCoordinates(connection.targetId);
    if (connection.sourcePosition == null || connection.targetPosition == null) return null;
    var x = Math.min(connection.sourcePosition.x, connection.targetPosition.x) +
      Math.abs(connection.sourcePosition.x - connection.targetPosition.x) / 2;
    var y = Math.min(connection.sourcePosition.y, connection.targetPosition.y) + Math.abs(connection.sourcePosition.y - connection.targetPosition.y) / 2;
    return { 'x': x, 'y': y };
  }

  getAllConnections(nodes: any[]) {
    var connections = {};
    nodes.filter(node => node.isActive).forEach(node => {

      var epc = _.flatten(node.endpoints
        .filter(e => (e.connections && e.connections.length > 0))
        .map(m => m.connections));

      epc.forEach((connection: any) => {
        if (connection.caption && connection.caption.length > 0) {
          connection.captionLocation = this.getCaptionLocation(connection);
        }
        connections[connection.id] = connection;
      });

    });
    return connections;
  }

  getConnectionCaptionLine(connections, row, colsPerRow = 3) {
    var text = '';

    var connForRow = _.values(connections)
      .filter((c) => c.captionLocation)
      .filter((c) => (c.captionLocation.y >= row - 1) && (c.captionLocation.y < row));
    if (!connForRow || connForRow.length < 1) return '';

    var sortedConn =
      _.sortBy(connForRow, [function (c) { return c.captionLocation.x; }]);

    var col = 0;
    sortedConn.forEach((c: any, idx) => {
      if (col === 0) text += `<div class="offsetconncap">&nbsp;</div>`;
      var x = Math.floor(c.captionLocation.x);
      while (x > (col + 1) && col < (colsPerRow - 1)) {
        text += `<div class="blankConnCap"></div>`;
        col += 1;
      }
      text += this.generateHtmlConnectCaptionAlert(c);

      col = (col >= (colsPerRow - 1)) ? 0 : col + 1;
    });
    //fill up the rest of the remaining line with blanks
    for (let index = 0; index < (colsPerRow - col); index++) {
      text += `<div class="blankConnCap"></div>`;
    }
    //console.log('getConnectionCaptionLine',text)
    return text;
  }
  generateOneConnectionScript(sourceId, targetId) {
    var text = `
  jsPlumbInstance.connect({
    source:"${sourceId}",
    target:"${targetId}",
    anchor:[ "Continuous", { faces:["top","bottom","left","right"] }],
    endpoint:[ "Dot", { radius:5, hoverClass:"myEndpointHover" } ],
    connector:[ "Bezier", { curviness:20 } ],
    overlays: [
          [ "Arrow", { location:0.5 } ],
          [ "Label", { cssClass:"labelClass" } ]  
      ]
  });`;

    return text;

  }
  generateConnectionScript(nodes: any[], connections) {

    console.log(connections);
    var text = '';
    _.values(connections).forEach((connection: any) => {
      if (connection.captionLocation) {
        text += this.generateOneConnectionScript(connection.sourceId, `${connection.id}Caption`);
        text += this.generateOneConnectionScript(`${connection.id}Caption`, connection.targetId);
      } else {
        text += this.generateOneConnectionScript(connection.sourceId, connection.targetId);
      }
    });
    return text;
  }
  generateScript(nodes: any[], connections) {
    var text = `jsPlumb.ready(function() {
      var jsPlumbInstance = jsPlumb.getInstance({
        Container:"mainDiagram"
      });
      ${this.generateConnectionScript(nodes, connections)}
      
    });`;

    //the following line was in the text block above, but was not carrying over the SVG (arrows)
    //${(generatePng)? this.generateHtml2CanvasScript():''}


    return text;
  }

  //Not used currently, as svgs are not displayed
  //there is a way to have svgs rendered on canvas - but seems like a lot of extra work
  //better to use the browser plug-ins to screen shot pages.
  generateHtml2CanvasScript(){
    var text = ``;
    text += `
        setTimeout(function(){  
          html2canvas(document.querySelector("#mainDiagram"),{height:1000}).then(canvas => {
            document.body.appendChild(canvas)
          });            
        }, 1000);
       

    `;
    return text;
  }
  generateHtmlPresentationFromDiagrammer(nodes: any[], colsPerRow = 4): any {
    var text = `<div id="mainDiagram" class="container">`;
    //text += `<div class="row">`;
    var blanks = '';
    var connections = this.getAllConnections(nodes);

    var lastNodePos = 0;
    nodes.forEach((node, idx) => {
      if (idx > 0 && (idx % colsPerRow) === 0) {
        var row = Math.floor(idx / colsPerRow);

        var connCaptionLine = this.getConnectionCaptionLine(connections, row);
        if (connCaptionLine.length > 0) {
          text += blanks;
          text += connCaptionLine;
          blanks = '';
        }

      }
      if (!node.isActive) {
        blanks += `<div class="blank"></div>`;
      } else {
        text += blanks;
        text += this.generateHtmlNodeCard(node);
        lastNodePos = idx;
        blanks = '';
      }
    });


    // var normalizedLastNodePos = ((lastNodePos + 1 + colsPerRow) % colsPerRow);
    // if  (normalizedLastNodePos !== 0) {//finish a node row
    //   for (let index = 0; index < (colsPerRow - normalizedLastNodePos); index++) {
    //     text +=`<div class="blank"></div>`;
    //   }      
    // }


    //text += `</div>`;
    text += "</div>"
    text += `<script>${this.generateScript(nodes, connections)}</script>`;
    return text;
  }


}
