import { Component, OnInit, Input } from '@angular/core';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {LodashHelper} from '../../utility/lodash-helper';
import {NavigationService} from '../../services/navigation.service';
import { Link } from '../../models/link';
import * as _ from "lodash";

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss']
})
export class BookComponent implements OnInit {
  @Input() isKeyView = false;
  public selectedBookAbbrev:string;
  public book: any;
  public isAnnotation: boolean;
  public isWorkshop: boolean;

  constructor(public dataService : DataService,
   private navigation:NavigationService,
   private route : ActivatedRoute,
    private router: Router) { }


   bookTitleForGivenSpace(){
    if (this.navigation.currentChapter) {
      return this.navigation.currentBook.path;
    } else
    {
      return this.navigation.currentBook.name;
    }
  }

  get headerLink():Link {
      return {
        title: `${_.toUpper(this.bookTitleForGivenSpace())} BOOK`,
        path:'..'
      };
  }


  get headerMenuLinks():Link[] {
    return [{
      title: `Book Workshop`,
      path:`/library/${this.selectedBookAbbrev}/workshop`
    },
    {
      title: `Book Annotation`,
      path:`/library/${this.selectedBookAbbrev}/annotation`
    }
  ];
}

get linkList():Link[]{
  //todo: memoize - this is called too often
  var currentChapter = this.navigation.currentChapter;
  var links = this.book.children.map(chapter=>{
    return {
      title: `${chapter.name} Chapter`,
      path: `/library/${this.selectedBookAbbrev}/${chapter.path}`,
      selected:(currentChapter && chapter.path==currentChapter.path)
    }
  });
  return links;
}



get annotationHeaderLink():Link {
  return {
    title: `${_.toUpper(this.bookTitleForGivenSpace())} BOOK`,
    path:'/library/annotation'
  };
}

get annotationHeaderMenuLinks():Link[] {
  return [{
    title: `Book Workshop`,
    path:`/library/${this.selectedBookAbbrev}/workshop`
  },
  {
    title: `Book Index`,
    path:`/library/${this.selectedBookAbbrev}`
  }
];
}


get annotationLinkList():Link[]{
  //todo: memoize - this is called too often
  var currentChapter = this.navigation.currentChapter;
  var links = this.book.children.map(chapter=>{
    return {
      title: `${chapter.name} Chapter`,
      path: `/library/${this.selectedBookAbbrev}/${chapter.path}/annotation`,
      selected:(currentChapter && chapter.path==currentChapter.path)
    }
  });
  return links;
}



  ngOnInit() {
        var url = this.router.url;
          //annotation breadcrumbs may load after page, so using url instead
          this.isAnnotation = url.toLowerCase().endsWith("annotation");
          this.isWorkshop = url.toLowerCase().endsWith("workshop");

      this.route.params.forEach((params:Params)=>{
          this.selectedBookAbbrev = params['bookAbbrev'];
          var matchBookAbbrevCaseInsensitive = LodashHelper.buildCaseInsensitiveMatcher('path',this.selectedBookAbbrev);
         this.dataService.getBookInTreeBy(matchBookAbbrevCaseInsensitive)
        .subscribe(
            result =>{
                this.book = result;
                if (this.isKeyView) this.navigation.setBreadcrumbsToBook();

             },
             error=>{console.log(error); }

        );

      });

  
  }

}
