import { Component, OnInit } from '@angular/core';
import {NavigationService} from '../../services/navigation.service';

@Component({
  selector: 'app-page-view',
  templateUrl: './page-view.component.html',
  styleUrls: ['./page-view.component.css']
})
export class PageViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
