import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CuiPair } from '../../models/umls-tree.interface';

@Component({
  selector: 'fpn-umls-triple-item',
  templateUrl: './umls-triple-item.component.html',
  styleUrls: ['./umls-triple-item.component.scss']
})
export class UmlsTripleItemComponent implements OnInit {

  @Input() item: CuiPair;
@Input() label : string = '';
@Output() onDelete = new EventEmitter();
@Output() onAddAnotherItem = new EventEmitter();

//@Output() onAddModifier = new EventEmitter();
//@Output() onDeleteModifier = new EventEmitter<number>();

canDelete = false;
canAddAnotherItem = false;

  constructor() { }

  ngOnInit() {
    this.canDelete = this.onDelete.observers.length>0;
    this.canAddAnotherItem = this.onAddAnotherItem.observers.length>0;

  }

  deleteItem(){
    this.onDelete.emit();
  }
  
  addAnotherItem(){
    this.onAddAnotherItem.emit();
  }

  createModifier(){
    return {verb:{title:'',cui:''},object:{title:'',cui:''},value:''};
  }

  changeItemTo(textSuggestion:CuiPair){
    this.item.title = textSuggestion.title;
    this.item.cui = textSuggestion.cui;
    return false;
  }

  addModifier(){
    //this.onAddModifier.emit();

    //should this be passed up several levels?
    this.item.modifiers.push(this.createModifier());
  }

  deleteModifier(idxModifier){
    //this.onDeleteModifier.emit(idxModifier);

    //should this be passed up several levels?
    this.item.modifiers.splice(idxModifier,1);
  }


}
