import { Injectable } from '@angular/core';
import { DataService } from "./data.service";
import { SelectionService } from "./selection.service";
import { PageSlideshowViewComponent } from '../views/page-slideshow-view/page-slideshow-view.component';



@Injectable()
export class ComposeSlidesService {

  constructor(private selectionService: SelectionService,
    private dataService: DataService) { }


  openSlideViewer(html, OpenWindow) {
    // window.open(this.global.htmlToPdf,"_blank");
    //console.log("----------------");
    //console.log(this.selectionService);
    //console.log(this.selectionService.selectedPages);
    // console.log(html);
    //var OpenWindow = window.open("", "newwin", "height=650, width=900");
    //var OpenWindow = window.open("", "newwin", "height=650, width=900,toolbar=no,scrollbars=" + scroll + ",menubar=no");
    OpenWindow.document.write("<!DOCTYPE html><html><head><meta http-equiv='content-type' content='text/html;charset=UTF-8' />");
    OpenWindow.document.write("<TITLE>FPnotebook Slideshow</TITLE>");
    OpenWindow.document.write("<link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/reveal.js/3.6.0/css/reveal.min.css'>");

    OpenWindow.document.write("<link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/reveal.js/3.6.0/css/theme/white.min.css'>");

    OpenWindow.document.write(`<style>${this.generateStyle()}</style>`);

    OpenWindow.document.write("<BODY onload='Reveal.initialize()'>");
    //OpenWindow.document.write("<body>");
    OpenWindow.document.write(html);

    OpenWindow.document.write("<script src='https://cdnjs.cloudflare.com/ajax/libs/reveal.js/3.6.0/js/reveal.min.js'></script>");
    //OpenWindow.document.write("<script>Reveal.initialize();</script>");
    OpenWindow.document.write("</body>");
    OpenWindow.document.write("</html>");

    OpenWindow.document.close()

    OpenWindow.focus();

    self.name = "main"
  }

  generateStyle(){
    return `
    .twocolumn {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      text-align: left;
   }
   ul li{
     font-size:90%;
   }
    `;
  }
  generateFullHtmlPage(html) {
    var text = "";
    text += "<!DOCTYPE html><html><head><meta http-equiv='content-type' content='text/html;charset=UTF-8' />";
    text += "<TITLE>FPnotebook Slideshow</TITLE>";
    text += "<link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/reveal.js/3.6.0/css/reveal.min.css'>";

    text += "<link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/reveal.js/3.6.0/css/theme/white.min.css'>";

    text += "<style>ol  {list-style-type: upper-roman;}ol ol {list-style-type: upper-alpha;}ol ol ol {list-style-type: decimal;}ol ol ol ol {list-style-type: lower-alpha;}ol ol ol ol ol {list-style-type: lower-roman;}</style>";
    text += `<style>${this.generateStyle()}</style>`;
    text += "<BODY>";
    text += html;

    text += "<script src='https://cdnjs.cloudflare.com/ajax/libs/reveal.js/3.6.0/js/reveal.min.js'></script>";
    text += "<script>Reveal.initialize();</script>";
    text += "</BODY>";
    text += "</HTML>";
    return text;
  }

  generateTwoColumnLayout(col1:string,col2:string){
    var html = `<div class="twocolumn">
    <div>
      ${col1}
    </div>
    <div>
      ${col2}       
    </div>
  </div>`;
 return html;
  };

  generateSlideTextLevel(children: any[]) {
    if (!children || children.length < 1) return "";
    var images = [];
    var text = [];
    children.forEach(li => {
      if (li.imageLink) {
        images.push(
          `<div>
          <h4>${li.imageLink.title}</h4>
          <img src="${li.imageLink.url}" width="300px">
          <p><small><i>${li.imageLink.caption}</i></small></p>
          </div>`);
      }
      else {
        text.push(`<li>${li.title}${this.generateSlideTextLevel(li.children)}</li>`);
      }
    });
    
    var html = "";
    if (images.length>1){
      //we only show the first 2, and no text
      html =this.generateTwoColumnLayout(images[0],images[1]);
    }
    else if (images.length > 0 && text.length>0) {
       html =this.generateTwoColumnLayout(images[0],
        `<ul>${text.join('')}</ul>`);
    }
    else if (images.length > 0) {
      html += `${images[0]}`;
    }
    else if (text.length>4){
      html =this.generateTwoColumnLayout(
        `<ul>${text.slice(0,4).join('')}</ul>`,
        `<ul>${text.slice(4).join('')}</ul>`);
    }
    else if (text.length>0){
      html += `<ul>${text.join('')}</ul>`;
    };

    return html;
  }
  generateSlide(slide) {
    var text = "<section>";
    text += `<h2>${slide.title}</h2>`;
    text += this.generateSlideTextLevel(slide.children);
    text += "</section>";
    return text;
  }

  generateHtmlPresentationFromSlideSorter(slides: any[]): any {
    var text = "<div class='reveal'><div class='slides'>";
    //console.log(slides);
    slides.forEach(slide => {
      text += this.generateSlide(slide);
    });
    text += "</div></div>"
    return text;
  }


}
