import { Component, ElementRef, OnInit,AfterViewInit, Input, ViewChild } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LodashHelper } from '../../utility/lodash-helper';
import { NavigationService } from '../../services/navigation.service';
import { AuthenticateService } from '../../services/authenticate.service';
import * as _ from "lodash";
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit  {

  @Input() isKeyView = false;
  public selectedBookAbbrev: string;
  public selectedChapterAbbrev: string;
  public selectedPageAbbrev: string;

  public isSlideshow: boolean;
  public isAnnotation: boolean;
  public isWorkshop: boolean;
  public relativePagePath: string;

  public page: any;
  public akasJoined: string;
  self = this;

 
  constructor(public dataService: DataService,
    private navigation: NavigationService,
    private route: ActivatedRoute,
    private authenticateService: AuthenticateService,
    private router:Router,
    private globals:GlobalService
  ) { }


  ngOnInit() {

    this.route.params.forEach((params: Params) => {
      this.selectedBookAbbrev = params['bookAbbrev'];
      this.selectedChapterAbbrev = params['chapterAbbrev'];
      this.selectedPageAbbrev = params['pageAbbrev'];
      this.relativePagePath= this.dataService.generateRelativePagePath(this.selectedBookAbbrev,this.selectedChapterAbbrev,this.selectedPageAbbrev);
      this.dataService.getPageByUrlAndUpdateBookAndChapter(this.selectedBookAbbrev, this.selectedChapterAbbrev, this.selectedPageAbbrev)
        .subscribe(
        result => {
          //The current fpnotebook json format uses an href with '#/library...' which works with angular 1
          //Convert these href to use [routerLink]='/library...' which works with angular 2

          for (let pageBlock of result.PageBlockData) {
            //pageBlock.Content= pageBlock.Content.replace(/href=["']#(\/.*?)["']/gi,"[routerLink]='[\"$1\"]'");
            pageBlock.Content = pageBlock.Content.replace(/href=["']#(\/.*?)["']/gi, "href='$1'");
          }

          this.page = result;

          if (this.isKeyView) this.navigation.setBreadcrumbsToPage();
          // this.isSlideshow = this.navigation.breadcrumbs.find(x=>x.title=='Slideshow')!=null;


          var url = this.router.url;
          //annotation breadcrumbs may load after page, so using url instead
          this.isAnnotation = url.toLowerCase().endsWith("annotation");
          this.isWorkshop = url.toLowerCase().endsWith("workshop");
          this.isSlideshow= url.toLowerCase().includes("slideshow");
          //this.isAnnotation = this.navigation.breadcrumbs.find(x => x.title == 'Annotation') != null;
          //  this.isWorkshop = this.navigation.breadcrumbs.find(x=>x.title=='Workshop')!=null;

          var akaStringArray = _.map(result.Akas, 'Title');
          this.akasJoined = akaStringArray.join(', ');
        },
        error => { console.log(error); }

        );

    });


  }


}
