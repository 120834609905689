import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ActivatedRoute, Params } from '@angular/router';
import { LodashHelper } from '../../utility/lodash-helper';
import { Location } from '@angular/common';
import { NavigationService } from '../../services/navigation.service';
import { GlobalService } from '../../services/global.service';

import * as _ from "lodash";

@Component({
    selector: 'app-slideshow',
    templateUrl: './slideshow.component.html',
    styleUrls: ['./slideshow.component.scss']
})
export class SlideshowComponent implements OnInit {
    @Input() isKeyView = false;
    public selectedBookAbbrev: string;
    public selectedChapterAbbrev: string;
    public selectedPageAbbrev: string;
    public page: any;

    public imageN = 0;
    public imageLinks = [];
    public active = 1;
    public pagePath = "";


    constructor(private dataService: DataService,
        private route: ActivatedRoute,
        private location: Location,
        private navigation: NavigationService,
        private globalService:GlobalService) { }

    ngOnInit() {
        let isProduction = this.globalService.isProduction;

        this.route.params.forEach((params: Params) => {
            this.selectedBookAbbrev = params['bookAbbrev'];
            this.selectedChapterAbbrev = params['chapterAbbrev'];
            this.selectedPageAbbrev = params['pageAbbrev'];
            this.imageN = Number(params['imageId']) - 1;  //imageN is 1 based 
            //console.log(this.location.path());
            this.pagePath = `\/library/${this.selectedBookAbbrev}/${this.selectedChapterAbbrev}/${this.selectedPageAbbrev}/slideshow/`;

            this.dataService.getPageByUrlAndUpdateBookAndChapter(this.selectedBookAbbrev, this.selectedChapterAbbrev, this.selectedPageAbbrev)
                .subscribe(
                result => {

                    this.page = result;
                    this.navigation.currentImageId = this.imageN;
                    if (this.isKeyView) this.navigation.setBreadcrumbsToPageSlideshow();


                    this.imageLinks = result.ImageLinks;

                    _(this.imageLinks).forEach(function (value, index) {
                        value.id = index;
                        if (isProduction) {
                            value.url = value.url.replace(/^http:/i,'https:').replace(/www./,'');
                        }
                    })



                    //set the current imageLink to active
                    if (_.isNumber(this.imageN)) {
                        var len = this.imageLinks.length;

                        if (this.imageN >= 0 && this.imageN < len) {
                            this.imageLinks[this.imageN].active = true;
                            this.active = this.imageN;
                        }

                    }



                    $('#slideshow').on('slid.bs.carousel', (e) => {
                        var currentIndex = $('.carousel-inner div.active').index() + 1;
                        this.navigation.currentImageId = currentIndex;

                        this.location.replaceState(this.pagePath + currentIndex);

                    });


                },
                error => { console.log(error); }

                );

        });


    }


}
