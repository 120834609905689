export class Review {
   public id: number;
   public user : any;
   public stamp: Date;
    constructor(
        public annotationId:number,
        public comment: string,
        public status: number
     ) { }

}