
import { throwError as observableThrowError, of, Observable, from, concat , zip} from 'rxjs';
import { Injectable } from '@angular/core';
//import { Http, Response } from '@angular/http';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DataHelper } from '../utility/data-helper';
import { GlobalService } from './global.service';
import { NavigationService } from './navigation.service';
import { ILibraryTreeItem } from '../utility/interfaces';
import { LodashHelper } from '../utility/lodash-helper';
import * as _ from "lodash";
import {  catchError, switchMap, tap, debounceTime, distinctUntilChanged, map, filter, delay, retryWhen, scan, mapTo, toArray, flatMap, take } from 'rxjs/operators';
import { PageLink } from '../models/page-link.interface';




@Injectable()
export class DataService {

    public libraryTree: ILibraryTreeItem = { name: "", path: "", lineage: "", children: [] };
    public libraryTreeLoaded = false;


    public akas = [];
    public akasLoaded = false;


    public pageLinksIn = null;
    public pageLinksOut = null;
    public pages = null;
    public concepts = null;
    public conceptList = null;
    public conceptPages = null;

    //public currentBook = null;
    //public currentChapter = null;

    constructor(private globals: GlobalService,
        private http: HttpClient,
        private navigation: NavigationService) { }

    generateRelativePagePath(bookAbbrev: string, chapterAbbrev: string, pageAbbrev: string): string {
        let path = `/library/${bookAbbrev}/${chapterAbbrev}/${pageAbbrev}`;
        return path
    }

    getPageByUrlWithoutModifyingNavigation(bookAbbrev, chapterAbbrev, pageAbbrev) {
        var path = DataHelper.generateFilePath(this.globals.pathPages, '.json', bookAbbrev, chapterAbbrev, pageAbbrev);
        return this.http.get(path)
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            );

    };

    getPageByUrlAndUpdateBookAndChapter(bookAbbrev, chapterAbbrev, pageAbbrev) {
        var path = DataHelper.generateFilePath(this.globals.pathPages, '.json', bookAbbrev, chapterAbbrev, pageAbbrev);

        var rxBookChap = this.getChapterInTreeByAbbrev(bookAbbrev, chapterAbbrev);
        var rxPage = this.http.get(path)
            .pipe(
                map(this.extractData),
                tap((result) => {
                    this.navigation.currentPage = result;
                }),
                catchError(this.handleError));

        return concat(rxBookChap, rxPage)
            .pipe(
                filter((o: any) => o.Title)
            );//runs both Observables but only returns the page (which has Title property)
    }


    loadFullPages(selectedPages) {
        var pages = {};
        var completed = false;
        var pagesToGet = Object.entries(selectedPages)
            .map(s => s[1])
        // .filter(s => s["allSelected"]);//we need all to get imageLinks

        return from(pagesToGet)
            .pipe(
                flatMap(p => {
                    var info = p["parsedLineage"];
                    return this.getPageByUrlWithoutModifyingNavigation(info.book.path, info.chapter.path, info.page.path)
                })
                //      ,reduce((acc,p)=> acc[p["lineage"]]=p,{})
            )
    }



    // getPageByUrl(bookAbbrev, chapterAbbrev, pageAbbrev) {
    //     var path = DataHelper.generateFilePath(this.globals.pathPages, '.json', bookAbbrev, chapterAbbrev, pageAbbrev);


    //         return this.http.get(path)
    //             .map(this.extractData)
    //             .do((result) => {
    //                 console.log(result);
    //                 this.navigation.currentPage = result;
    //             })
    //             .catch(this.handleError);


    // }

    getPageInTreeByUrlWithoutModifyingNavigation(url) {

        var ignore = /(#|library|workshop|slideshow|\d*)/gi;

        var abbrev = url.split('/');//.filter(a=>!ignore.test(a)); 

        var bookAbbrev = abbrev[0];
        var chapterAbbrev = abbrev[1];
        var pageAbbrev = abbrev[2];

        return this.getLibraryTree()
            .pipe(
                map((result: any) => {
                    var filtered = _.filter(result.children, { 'path': bookAbbrev });
                    return (filtered && filtered.length > 0) ? filtered[0] : null;
                }),
                map((result: any) => {
                    var filtered = _.filter(result.children, { 'path': chapterAbbrev });
                    return (filtered && filtered.length > 0) ? filtered[0] : null;
                }),
                map((result: any) => {
                    //consolidate sections
                    if (!result.children) return null;
                    return result.children.reduce((acc, section) => {
                        return acc.concat(section.children);
                    }, []);

                }),

                map(result => {
                    var filtered = _.filter(result, { 'path': pageAbbrev });
                    return (filtered && filtered.length > 0) ? filtered[0] : null;
                }),
                tap(result => {
                    if (result) {
                        return of(result);
                    }
                    else { return observableThrowError("Page was not found by abbreviation.") }
                }),
                catchError(this.handleError)

            );



    }


    getPageInTreeByLineageWithoutModifyingNavigation(pageLineage) {

        var parts = pageLineage.split('.');

        var bookLineage = parts[0] + '.' + parts[1];
        var chapterLineage = parts[0] + '.' + parts[1] + '.' + parts[2];
        var sectionLineage = parts[0] + '.' + parts[1] + '.' + parts[2] + '.' + parts[3];

        return this.getLibraryTree()
            .pipe(

                map(result => {
                    var filtered = _.filter(result.children, { 'lineage': bookLineage });
                    return (filtered && filtered.length > 0) ? filtered[0] : null;
                }),
                map((result: any) => {
                    var filtered = _.filter(result.children, { 'lineage': chapterLineage });
                    return (filtered && filtered.length > 0) ? filtered[0] : null;
                }),
                map((result: any) => {
                    var filtered = _.filter(result.children, { 'lineage': sectionLineage });
                    return (filtered && filtered.length > 0) ? filtered[0] : null;
                }),
                map((result: any) => {
                    var filtered = _.filter(result.children, { 'lineage': pageLineage });
                    return (filtered && filtered.length > 0) ? filtered[0] : null;
                }),
                tap(result => {
                    if (result) {
                        return of(result);
                    }
                    else { return observableThrowError("Page was not found by abbreviation.") }
                }),
                catchError(this.handleError)

            );

    }


    getChapterInTreeByAbbrev(bookAbbrev, chapterAbbrev) {

        var bookMatcher = LodashHelper.buildCaseInsensitiveMatcher('path', bookAbbrev);
        var chapterMatcher = LodashHelper.buildCaseInsensitiveMatcher('path', chapterAbbrev);

        return this.getBookInTreeBy(bookMatcher)
            .pipe(
                map((result: any) => {
                    var filtered = _.filter(result.children, chapterMatcher); //was result.data.children
                    return (filtered && filtered.length > 0) ? filtered[0] : null;
                }),
                tap(result => {
                    if (result) {
                        this.navigation.currentChapter = result;
                        return of(result);
                    }
                    else { return observableThrowError("Chapter was not found by abbreviation.") }
                }),
                catchError(this.handleError)

            );

    }



    getChapterInTreeByLineage(lineage) {


        var parts = lineage.split('.'); // split(lineage + '', '.');
        //var parts = _.words(lineage,'.');
        var bookLineage = parts[0] + '.' + parts[1];
        var chapterLineage = parts[0] + '.' + parts[1] + '.' + parts[2];
        var criteria = { 'lineage': bookLineage };


        return this.getBookInTreeBy(criteria)
            .pipe(

                map((result: any) => {
                    var filtered = _.filter(result.children, { 'lineage': chapterLineage }); //was result.data.children
                    return (filtered && filtered.length > 0) ? filtered[0] : null;
                }),
                tap(result => {
                    if (result) {
                        this.navigation.currentChapter = result[0];
                        return of(result[0]);
                    }
                    else { return observableThrowError("Chapter was not found by lineage.") }
                }),
                catchError(this.handleError)

            )

    }


    getBookInTreeBy(criteria) {

        return this.getLibraryTree()
            .pipe(
                map(result => {
                    var filtered = _.filter(result.children, criteria); //was result.data.children
                    return (filtered && filtered.length > 0) ? filtered[0] : null;
                }),
                tap(result => {
                    if (result) {
                        this.navigation.currentBook = result;
                        return result;
                    }
                    else { return observableThrowError("Book was not found.") }

                }),
                catchError(this.handleError)

            );



    }



    getLibraryTree() {
        if (this.libraryTreeLoaded) {
            return of(this.libraryTree);
        }
        else {

            var path = this.globals.pathLibraryIndex + 'parentChildrenBookChapterPage.json';
            return this.http.get(path)
                .pipe(
                    map((res: any) => {
                        return res;
                        //let body = res.json();
                        //return body || {};
                    }),
                    tap(res => {
                        this.libraryTree = res;
                        this.libraryTreeLoaded = true;
                        return res;
                    }),
                    catchError(this.handleError)
                );


        }

    }

    getAkasForSearch(term: string, nValues:number = 20):Observable<PageLink[]> {
        var start_pattern = new RegExp("^" + term, "i");
        var any_pattern = new RegExp(term, "i");
        return this.getAkasWithId()
            .pipe(
            map((res:Observable<PageLink[]>) => {
                var filtered = _.filter(res, (aka: PageLink) => { return aka.value.match(any_pattern); });
                return filtered;
            }),
            take(nValues)
            );
    }

    //Replaced with getAkasWithId
    // getAkas() {
    //     if (this.akasLoaded) {
    //         return of(this.akas);
    //     }
    //     else {

    //         var path = this.globals.pathLibraryIndex + 'typeaheadFpnExtended.json';
    //         return this.http.get(path)
    //         .pipe (
    //             map((res: any) => {
    //                 // let body = res.json();
    //                 // return body || {};
    //                 return res;
    //             }),
    //             tap(res => {
    //                 this.akas = res;
    //                 this.akasLoaded = true;
    //                 return res;
    //             }),
    //             catchError(this.handleError)

    //         );
    //     }
    // }

    getAkasWithId():Observable<PageLink[]>{
        if (this.akasLoaded) {
            return of(this.akas);
        }
        else {

            var path = this.globals.pathLibraryIndex + 'akaIndexNonTropo.json';
            return this.http.get(path)
            .pipe (
                map((res: any) => {
                     let resMod = res.map(a=>{ return {'value':a.a , 'href':a.u, 'pid':a.i}})
                     return resMod
                }),
                tap(res => {
                    this.akas = res;
                    this.akasLoaded = true;
                    return res;
                }),
                catchError(this.handleError)

            );
        }
  
    }

getPageIdsInCommonForConceptIds(conceptIds:string[]){
    return this.getConceptPages()
        .pipe(
            switchMap(cPages=> {
                let cp = conceptIds.map(cId => cPages[cId]);
                let intersect = _.intersection(...cp);
                return of( intersect);
            }),
           // tap(cPages=>console.log(cPages))
        )
}

getPagesForIds(pageIds:number[]):Observable<any>{
    const rgxFileName = /https:\/\/fpnotebook.com(.*)\.htm/i;
    return zip(
        this.getPages(),
        (pages:any[])=>
        {

            return pageIds.map((pageId)=>{
                
                let page = pages[pageId];
                if (!page) return;
                let match =  rgxFileName.exec(page.filename);
                let filename = (match && match.length>1) ? match[1] : page.filename;
                return {'value':page.title , 'href':filename, 'pid':page.id}
            });
        })


}
getLinksInForPageIds(pageIds:string[]){
    const rgxFileName = /https:\/\/fpnotebook.com(.*)\.htm/i;
    //returning data in format of indexed arrays, based on the pageIdLink these pages are linking to
    //example: {pageId:[{value:'av block',href:'myurl.com', pid:2}]}
    
    //the zip takes in 2 streams, each which returns 1 value (these are each json files that need to be loaded)
    // The last parameter in zip is the function that uses the json files as lookups for the pageIds passed  
    return zip(
            this.getPageLinksOut(),
            this.getPages(),
            (links,pages:any[])=>
            {
          let result = pageIds
          .reduce((groups,pageId)=>{
              //there are duplicates in the links (unfortunately)
              let uniquePageIds : any[] = _.uniq(links[pageId]);
              //map each of the pageIds that include this link to an object that holds the page Id, url, title
             let pagesInGroup = uniquePageIds
                .map(linkedPageId=> pages[linkedPageId])
                .filter(page=> page)
                .map((page)=>{
                    if (!page) return;
                    let match =  rgxFileName.exec(page.filename);
                    let filename = (match && match.length>1) ? match[1] : page.filename;
                    return {'value':page.title , 'href':filename, 'pid':page.id}
                });
                //build a lookup object with the key being the pageIdLink and the value the array of pages that contain that link
             groups[pageId]=pagesInGroup;
             return groups;
          },{});

          return result;
        }
        );


            // .map(pageId=> links[pageId]
            //     .map(linkedPageId=> pages[linkedPageId])
            //          .map((page)=>{
            //             if (!page) return;
            //             let match =  rgxFileName.exec(page.filename);
            //             let filename = (match && match.length>1) ? match[1] : page.filename;
            //             return {'value':page.title , 'href':filename, 'pid':page.id}
            //         }) ));
        

}
getPageLinksOut(){
    if (this.pageLinksOut) {
        return of(this.pageLinksOut);
    } else {
        var path = this.globals.pathLibraryIndex + 'pageLinksOut.json';
        return this.http.get(path)
        .pipe (
            // map((res: any) => {
            //     // let body = res.json();
            //     // return body || {};
            //     return res;
            // }),
            tap(res => {
                this.pageLinksOut = res;
                 return res;
            }),
            catchError(this.handleError)

        );

    }
}

getPageLinksIn(){
    if (this.pageLinksIn) {
        return of(this.pageLinksIn);
    } else {
        var path = this.globals.pathLibraryIndex + 'pageLinksIn.json';
        return this.http.get(path)
        .pipe (
            // map((res: any) => {
            //     // let body = res.json();
            //     // return body || {};
            //     return res;
            // }),
            tap(res => {
                this.pageLinksIn = res;
                 return res;
            }),
            catchError(this.handleError)

        );

    }
}

getPages(){
    if (this.pages) {
        return of(this.pages);
    } else {
        var path = this.globals.pathLibraryIndex + 'pages.json';
        return this.http.get(path)
        .pipe (
            map((res: any) => {
                // let body = res.json();
                // return body || {};
               let pages =  res.reduce((map,obj)=>{
                    map[obj.id]=obj;
                    return map;
                });
                return pages;
            }),
            tap(res => {
               // console.log(res);
                this.pages = res;
                 return res;
            }),
            catchError(this.handleError)

        );

    }
}

getConcepts(){
    if (this.concepts) {
        return of(this.concepts);
    } else {
        var path = this.globals.pathLibraryIndex + 'concepts.json';
        return this.http.get(path)
        .pipe (
            tap(res => {
               // console.log(res);
                this.concepts = res;
                this.conceptList = _.values(res);
                 return res;
            }),
            catchError(this.handleError)

        );

    }
}

getConceptPages(){
    if (this.conceptPages) {
        return of(this.conceptPages);
    } else {
        var path = this.globals.pathLibraryIndex + 'conceptPages.json';
        return this.http.get(path)
        .pipe (
            // map((res: any) => {
            //     // let body = res.json();
            //     // return body || {};
            //     return res;
            // }),
            tap(res => {
                this.conceptPages = res;
                 return res;
            }),
            catchError(this.handleError)

        );

    }
}






    private extractData(res: Response) {
        //let body = res.json();
        //return body || {};
        return res;
    }

    private handleError(error: any) {
        // In a real world app, we might use a remote logging infrastructure
        // We'd also dig deeper into the error to get a better message
        let errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        console.error(errMsg); // log to console instead
        return observableThrowError(errMsg);
    }



}
